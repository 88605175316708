import React, { useState, useEffect } from "react";
import { Images } from "../Images";
import { LuPlane } from "react-icons/lu";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { RiHome6Fill } from "react-icons/ri";
import { BsTicketPerforated, BsSuitcaseLg } from "react-icons/bs";
import { BiPhotoAlbum } from "react-icons/bi";
import { IoChatboxOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import ResetPassword from "./login/ResetPassword";
import Login from "./login/Login";
import SignUpPopup from "./login/SignUpPopup";
import OtpPopup from "./login/OtpPopup";
import SubmitPopup from "./popups/SubmitPopup";
import { getTourCategories } from "../api/apiMethods";
import { TfiWallet } from "react-icons/tfi";
import { GrHomeRounded } from "react-icons/gr";

function SideBar({ showLogin, setShowLogin }) {
  const navigate = useNavigate();
  const [openNested, setOpenNested] = useState(null);
  const [showResetPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [OtpModal, setOtpModal] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [tourCategory, setTourCategory] = useState();

  useEffect(() => {
    getTourCategories({})
      .then((response) => {
        if (response?.status === true) {
          setTourCategory(response?.data?.category);
        } else {
          console.error("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  }, []);

  useEffect(() => {
    const savedActiveItem = localStorage.getItem("activeItem");
    if (savedActiveItem) {
      setActiveItem(savedActiveItem);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setShowLogin(true);
    navigate("/login");
  };

  const toggleNested = (item) => {
    setOpenNested(openNested === item ? null : item);
    setActiveItem(item);
    localStorage.setItem("activeItem", item);
  };

  const handleItemClick = (item, route) => {
    setActiveItem(item);
    localStorage.setItem("activeItem", item);
    setOpenNested(null);
    navigate(route);
  };

  const sidebarItems = [
    {
      icon: <GrHomeRounded />,
      text: "Dashboard",
      onClick: () => handleItemClick("Dashboard", "/"),
    },
    {
      icon: <LuPlane className="large-font" />,
      text: "Tours",
      onClick: () => toggleNested("Tours"),
      nestedItems: tourCategory
        ?.filter((item) => item.is_blocked === 0)
        .map((item) => ({
          text: item?.category_name,
          onClick: () => {
            handleItemClick("Tours", `/tour/${item?.slug}`);
          },
        })),
    },
    {
      icon: <BsTicketPerforated className="large-font" />,
      text: "Tickets",
      onClick: () => handleItemClick("Tickets", "/tickets"),
    },
    {
      icon: <BsSuitcaseLg />,
      text: "My Trips",
      onClick: () => handleItemClick("My Trips", "/my-tours"),
    },
    {
      icon: <TfiWallet />,
      text: "Wallet",
      onClick: () => handleItemClick("Wallet", "/wallet"),
    },
    {
      icon: <BiPhotoAlbum className="large-font" />,
      text: "Memories",
      onClick: () => handleItemClick("Memories", "/memories"),
    },
    {
      icon: <IoChatboxOutline className="large-font" />,
      text: "Chat",
      onClick: () => handleItemClick("Chat", "/chat"),
    },
    {
      icon: <IoSettingsOutline className="large-font" />,
      text: "Settings",
      onClick: () => toggleNested("Settings"),
      nestedItems: [
        {
          text: "Reset Password",
          onClick: () => {
            setShowPasswordPopup(true);
            setOpenNested(null);
            setActiveItem("Settings");
            localStorage.setItem("activeItem", "Settings");
          },
        },
        {
          text: "Privacy Policy",
          onClick: () => handleItemClick("Settings", "/privacy-policy"),
        },
      ],
    },
  ];

  return (
    <div className="w-100 sidebar-div">
      <div>
        <div className="p-2">
          <img
            alt=""
            src={Images.t2tlogo}
            className="w-100"
            onClick={() => handleItemClick("Dashboard", "/")}
          />
        </div>
        <div className="side-bar-scroll fw-600">
          {sidebarItems.map((item, index) => (
            <div key={index}>
              <div
                className={`flex-between align-items-center side-bar-div mt-1 p-2 br-10 medium-font largescreen-spaces cursor-pointer ${
                  activeItem === item.text ? "pink-bg clr-white w-100" : ""
                }`}
                onClick={
                  item.nestedItems
                    ? item.onClick
                    : () => {
                        item.onClick();
                      }
                }
              >
                <div>
                  <span className="large-font ps-2">{item.icon}</span>
                  <span className="sidebar-text medium-font">{item.text}</span>
                </div>
                {item.nestedItems &&
                  (openNested === item.text ? (
                    <FaAngleUp className="large-font me-2" />
                  ) : (
                    <FaAngleDown className="large-font me-2" />
                  ))}
              </div>
              {item.nestedItems && openNested === item.text && (
                <div className="back-ground small-font  rounded-bottom">
                  {item.nestedItems.map((nestedItem, nestedIndex) => (
                    <div
                      key={nestedIndex}
                      className="ps-3 pb-2 grey-text fw-500 cursor-pointer pink-hover medium-font"
                      onClick={nestedItem.onClick}
                    >
                      {nestedItem.text}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="login-card flex-center flex-column mt-2 p-2">
        <div
          className="w-80 text-center white-bg black-text medium-font fw-600 py-1 rounded-pill cursor-pointer"
          onClick={handleLogout}
        >
          Logout
        </div>
        <div className="text-center clr-white medium-font fw-600 mt-1">
          Choose Your <br /> Dream Paradise
        </div>
      </div>
      <ResetPassword
        showResetPasswordPopup={showResetPasswordPopup}
        setShowPasswordPopup={setShowPasswordPopup}
      />
      <Login
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        handleOtpModal={() => setOtpModal(true)}
        handleSignUpBtn={() => setShowSignUp(true)}
      />
      <SignUpPopup
        showSignUp={showSignUp}
        setShowSignUp={setShowSignUp}
        handleLoginBtn={() => setShowLogin(true)}
        handleOtpModal={() => setOtpModal(true)}
      />
      <OtpPopup
        OtpModal={OtpModal}
        setOtpModal={setOtpModal}
        handleSuccessPopup={() => setSuccessPopup(true)}
      />
      <SubmitPopup
        successPopup={successPopup}
        setSuccessPopup={setSuccessPopup}
      />
    </div>
  );
}

export default SideBar;
