import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { signUpUser } from "../../api/apiMethods";
import "./styles.css";

function SignUpPopup({
  showSignUp,
  setShowSignUp,
  handleLoginBtn,
  handleSuccessPopup,
  handleOtpModal,
}) {
  const [signUpData, setSignUpData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const handleSignupClose = () => setShowSignUp(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const formattedPhoneNumber = value.replace(/\D/g, "").slice(0, 10);
      setSignUpData({ ...signUpData, [name]: formattedPhoneNumber });
    } else {
      setSignUpData({ ...signUpData, [name]: value });
    }
  };

  const handleSignUp = () => {
    if(signUpData?.password !== signUpData?.confirm_password){
      setError("password and confirm password don't match");
      return;
    }
    signUpUser({
      user_name: signUpData.user_name,
      country_code: "+91",
      phone: signUpData.phone,
      password: signUpData.password,
      confirm_password: signUpData.confirm_password,
    })
      .then((response) => {
        if (response?.status === true) {
          setSignUpData({});
          setTimeout(() => {
            setShowSignUp(false);
            handleLoginBtn();
          }, 2000);
        } else {
          setError("Something Went Wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  return (
    <Modal
      show={showSignUp}
      onHide={handleSignupClose}
      className="signup-popup"
      centered
    >
      <Modal.Body>
        <div className="white-bg">
          <div className="w-100 d-flex justify-content-end">
            <MdOutlineClose
              className="large-font grey-text cursor-pointer"
              onClick={handleSignupClose}
            />
          </div>
          <h5 className="password-clr w-100 text-center fw-600">Sign Up</h5>

          <div className="d-flex flex-column medium-font fw-500">
            <label className="password-clr">User Name</label>
            <div className="input-bg br-10 mt-1 small-font">
              <input
                type="text"
                name="user_name"
                placeholder="Enter Username"
                className="grey-text w-100 p-2 bg-none small-font"
                value={signUpData.user_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-2">
            <label className="password-clr">Phone No</label>
            <div className="input-bg br-10 mt-1 small-font">
              <input
                type="text"
                name="phone"
                placeholder="Enter Phone Number"
                className="grey-text w-100 p-2 bg-none small-font"
                value={signUpData.phone}
                onChange={handleChange}
                maxLength={10}
              />
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-2">
            <label className="password-clr">Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2 small-font">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                className="grey-text w-100 p-2 bg-none small-font"
                value={signUpData.password}
                onChange={handleChange}
              />
              {showPassword ? (
                <AiFillEye
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-2">
            <label className="password-clr">Confirm Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2 small-font">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm Password"
                className="grey-text w-100 p-2 bg-none small-font"
                value={signUpData.confirm_password}
                onChange={handleChange}
              />
              {showConfirmPassword ? (
                <AiFillEye
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
          </div>

          <div
            className="medium-font pink-bg p-2 clr-white fw-600 text-center mt-3 rounded-pill cursor-pointer"
            onClick={handleSignUp}
          >
            Sign Up
          </div>

          {error && (
            <div className="red-text small-font text-center mt-2">{error}</div>
          )}

          <div className="text-center mt-2">
            <span className="grey-text fw-400 medium-font">Already have an account?</span>
            <span
              className="password-clr fw-600 ps-1 cursor-pointer medium-font"
              onClick={handleLoginBtn}
            >
              Login
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SignUpPopup;
