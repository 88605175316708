export const Images = {
  logo: "/assets/t2tlogo.png",
  BgBannerOne: "/assets/bgbannerone.png",
  BgBannerTwo: "/assets/bgbannertwo.png",
  BgBannerThree: "/assets/bgbannerthree.png",
  poster_01: "../assets/poster_01.png",
  poster_02: "../assets/poster_02.png",
  poster_03: "../assets/poster_03.png",
  card_img_01: "/assets/card_img_01.png",
  card_img_02: "/assets/card_img_02.png",
  card_img_03: "/assets/card_img_01.png",
  football_poster: "/assets/football_poster.png",
  sign_in: "/assets/sign_in.png",
  profile_img: "/assets/profile_img.png",
  casino_img: "/assets/casio_tour.png",
  trip_img_01: "/assets/trip_img_01.png",
  trip_img_02: "/assets/trip_img_02.png",
  trip_img_03: "/assets/trip_img_03.png",
  upload_img_01: "/assets/upload_img_01.png",
  upload_img_02: "/assets/upload_img_02.png",
  upload_img_03: "/assets/upload_img_03.png",
  upload_img_04: "/assets/upload_img_04.png",
  upload_img_05: "/assets/upload_img_05.png",
  upload_img_06: "/assets/upload_img_06.png",
  Casino: "/assets/casino_banner.png",
  Lake: "../assets/lake.png",
  IPL: "../assets/ipl.png",
  chatpic1: "/assets/chatpic1.png",
  chatpic2: "/assets/chatpic2.png",
  chatpic3: "/assets/chatpic3.png",
  chatpic4: "/assets/chatpic4.png",
  chatpic5: "/assets/chatpic5.png",
  chatpic6: "/assets/chatpic6.png",
  chatpic7: "/assets/chatpic7.png",
  chatpic8: "/assets/chatpic8.png",
  onlinepic1: "/assets/onlinepic1.png",
  onlinepic2: "/assets/onlinepic2.png",
  onlinepic3: "/assets/onlinepic3.png",
  addfrnd1: "/assets/addfrnd1.png",
  addfrnd2: "/assets/addfrnd2.png",
  addfrnd3: "/assets/addfrnd3.png",
  addfrnd4: "/assets/addfrnd4.png",
  sendreq1: "/assets/sendreq1.png",
  sendreq2: "/assets/sendreq2.png",
  sendreq3: "/assets/sendreq3.png",
  balibg: "/assets/balibg.png",
  threeboys: "/assets/3boys.png",
  t2tlogo: "/assets/t2tlogo.png",
  profile_img: "/assets/side-profile-pic.png",
  IndividualTour: "/assets/individual-tour.png",
  girl3: "./assets/girl3.jpg",
  girl2: "./assets/girl2.jpg",
  editchat: "./assets/editchat.png",
  sidebar1: "/assets/sidebar1.png",
  sidebar2: "/assets/sidebar2.png",
  sidebar3: "/assets/sidebar3.png",
  sidebar4: "/assets/sidebar4.png",
  tourimg1: "/assets/tourimg1.png",
  tourimg2: "/assets/tourimg2.png",
  tourimg3: "/assets/tourimg3.png",
  tourimg4: "/assets/tourimg4.png",
  tourimg5: "/assets/tourimg5.png",
  tourimg6: "/assets/tourimg6.png",
  FootballImage: "/assets/football-image.png",
};
