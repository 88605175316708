import React from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import chatting from "../../api/chatApis/chatting";
import { MdBlock } from "react-icons/md";

function BlockPopup({ blockPopoup, setBlockPopup, chattingWithUser }) {
  const handleBlockClose = () => {
    chattingWithUser.block ? handleUnBlockTheUser() : handleBlockTheUser();
    setBlockPopup(false);
  };

  const handleBlockTheUser = () => {
    chatting.blockTheUser(chattingWithUser.userId);
  };

  const handleUnBlockTheUser = () => {
    chatting.unBlockTheUser(chattingWithUser.userId);
  };

  return (
    <Modal
      show={blockPopoup}
      onHide={() => setBlockPopup(false)}
      className="otp-popup"
      centered
    >
      <div className="p-1">
        <div className="w-100 d-flex justify-content-end">
          <RxCross2
            className="large-font"
            onClick={() => setBlockPopup(false)}
          />
        </div>
        <div className="p-2">
          <div className="px-4 my-2">
            <h5 className="password-clr w-100 text-center fw-600 pointer">
              {chattingWithUser.block
                ? "Unblock To Send Message"
                : "Are You Sure Want To Block ?"}
            </h5>
            <span className="block-icon-size flex-center">
              <MdBlock />
            </span>
            <div className="d-flex flex-row align-items-center justify-content-around my-2">
              <div
                className="grey-bg py-2 br-20 grey-text fw-700 text-center border-grey w-40 pointer"
                onClick={() => setBlockPopup(false)}
              >
                Cancel
              </div>
              <div
                className="pink-bg py-2 br-20 clr-white fw-700 text-center  w-40 pointer"
                onClick={() => handleBlockClose()}
              >
                {chattingWithUser.block ? "UnBlock" : "Block"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BlockPopup;
