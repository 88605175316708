import { Modal } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { useState } from "react";
import CancelExpences from "./CancelExpences";

function CancelPopup({refreshTours, bookingIdToCancel, cancelPopup, setCancelPopup }) {
  const [showCancelExpense, setShowCancelExpense] = useState(false);
  const handleCancelExpenseButton = () => {
    setShowCancelExpense(true);
    setCancelPopup(false);
    
  };
  return (
    <>
      <Modal show={cancelPopup} centered size="sm">
        <Modal.Body>
          <div className="white-bg px-2 pb-2">
            <div className="w-100 d-flex justify-content-end">
              <MdOutlineClose
                className="font-24 black-text cursor-pointer"
                onClick={() => setCancelPopup(false)}
              />
            </div>
            <h5 className="password-clr w-100 text-center fw-600 mt-3 large-font">
              Are you sure you want cancel the trip
            </h5>
            <div className="d-flex medium-font mt-3 fw-600 mx-3">
              <div
                className="pink-bg rounded-pill py-2 clr-white w-40 text-center mx-4 pointer small-font"
                onClick={handleCancelExpenseButton}
              >
                YES
              </div>
              <div
                className="white-bg rounded-pill py-2 grey-text w-40 text-center border pointer small-font"
                onClick={() => setCancelPopup(false)}
              >
                NO
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CancelExpences
        showExpenceModal={showCancelExpense}
        setShowExpenceModal={setShowCancelExpense}
        bookingIdToCancel={bookingIdToCancel}
        refreshTours={refreshTours}
      />
    </>
  );
}

export default CancelPopup;
