import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoMdDownload } from "react-icons/io";
import { AiFillEye } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

function Documents({
  showDocumentsPopup,
  setShowDocumentsPopup,
  images,
  ticketDetails,
}) {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");

  const handleDocumentClose = () => {
    setShowDocumentsPopup(false);
  };

  const handleImageClose = () => {
    setShowImagePopup(false);
    setSelectedImage(null);
    setSelectedImageName("");
  };

  const handleViewImage = (url, name) => {
    setSelectedImage(url);
    setSelectedImageName(name);
    setShowImagePopup(true);
  };

  if (!ticketDetails) return null;

  const { guidance_details, travel_details, hotel_details } = ticketDetails;

  const getFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };

  return (
    <>
      <Modal
        show={showDocumentsPopup}
        onHide={handleDocumentClose}
        className="signup-popup pointer"
        size="md"
        centered
      >
        <Modal.Body>
          <div className="white-bg p-3">
            <div className="w-100 d-flex justify-content-end">
              <RxCross2 className="large-font" onClick={handleDocumentClose} />
            </div>
            <h6 className="clr-green w-100 text-center fw-600">Documents</h6>
            {/* 
            {images?.invoice && (
              <div>
                <h3>Invoice</h3>
                <img
                  src={images.invoice}
                  alt="Invoice"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            )} */}
            {/* Travel Booking Section */}
            <h6 className="clr-pink my-2">Travel Booking</h6>
            <div className="d-flex w-100 flex-column">
              {travel_details.map((travel) => (
                <div
                  className="d-flex align-items-center justify-content-between border-grey m-1 br-10 py-1 px-2"
                  key={travel.travel_id}
                >
                  <div>{getFileName(travel.travel_path)}</div>
                  <div className="w-20 d-flex justify-content-around align-items-center">
                    <AiFillEye
                      className="large-font fw-600 password-clr grey-bg"
                      onClick={() =>
                        handleViewImage(
                          travel.travel_path,
                          getFileName(travel.travel_path)
                        )
                      }
                    />
                    <a
                      href={travel.travel_path}
                      download={getFileName(travel.travel_path)}
                      rel="noopener noreferrer"
                    >
                      <IoMdDownload className="large-font fw-600 password-clr" />
                    </a>
                  </div>
                </div>
              ))}
            </div>

            {/* Hotel Booking Section */}
            <h6 className="clr-pink my-2">Hotel Booking</h6>
            <div className="d-flex w-100 flex-column">
              {hotel_details.map((hotel) => (
                <div
                  className="d-flex align-items-center justify-content-between border-grey m-1 br-10 py-1 px-2"
                  key={hotel.hotel_id}
                >
                  <div>{getFileName(hotel.hotel_path)}</div>
                  <div className="w-20 d-flex justify-content-around align-items-center">
                    <AiFillEye
                      className="large-font fw-600 password-clr grey-bg"
                      onClick={() =>
                        handleViewImage(
                          hotel.hotel_path,
                          getFileName(hotel.hotel_path)
                        )
                      }
                    />
                    <a
                      href={hotel.hotel_path}
                      download={getFileName(hotel.hotel_path)}
                      rel="noopener noreferrer"
                    >
                      <IoMdDownload className="large-font fw-600 password-clr" />
                    </a>
                  </div>
                </div>
              ))}
            </div>

            {/* Tour Guidance Section */}
            <h6 className="clr-pink my-2">Tour Guidance</h6>
            <div className="d-flex w-100 flex-column">
              {guidance_details.map((guidance) => (
                <div
                  className="d-flex align-items-center justify-content-between border-grey m-1 br-10 py-1 px-2"
                  key={guidance.guidance_id}
                >
                  <div>{getFileName(guidance.guidance_path)}</div>
                  <div className="w-20 d-flex justify-content-around align-items-center">
                    <AiFillEye
                      className="large-font fw-600 password-clr grey-bg"
                      onClick={() =>
                        handleViewImage(
                          guidance.guidance_path,
                          getFileName(guidance.guidance_path)
                        )
                      }
                    />
                    <a
                      href={guidance.guidance_path}
                      download={getFileName(guidance.guidance_path)}
                      rel="noopener noreferrer"
                    >
                      <IoMdDownload className="large-font fw-600 password-clr" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Image Popup Modal with Document Name */}
      <Modal show={showImagePopup} onHide={handleImageClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <img
            src={selectedImage}
            alt={selectedImageName}
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Documents;
