import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

function UserDetails({
  packageCount,
  totalMembers,
  selectedPackageNames,
  setActiveStep,
  totalPrice,
  selectedTourData,
  resetData,
  addNewPackage,
}) {
  const selectedPackageName = useSelector(
    (state) => state?.selectedPackageName
  );

  const totalGroupMembers = useSelector((state) => state.totalGroupMembers);
  console.log(totalGroupMembers, "totalGroupMembers");

  const totalPackageCost = useSelector((state) => state.totalPackageCost);
  const totalGroupCost = useSelector((state) => state.totalGroupCost);
  const selectedOption = useSelector((state) => state.selectedOption);
  console.log(totalGroupCost, "totalGroupCost");
  console.log("selectedOption", selectedOption);

  return (
    <div className="mt-2">
      <h1 className="password-clr font-20 my-2 fw-700 w-100 text-center">
        Added Users Details
      </h1>
      <Row className="booknow-table-header py-1 clr-white small-font fw-600 d-flex align-items-center">
        <Col xs={1} className="text-center">
          Sno
        </Col>
        <Col xs={3} className="text-center">
          Selected Pkg
        </Col>
        <Col xs={2} className="text-center">
          Pkg Count
        </Col>
        <Col xs={3} className="text-center">
          Member
        </Col>
        <Col xs={3} className="text-center">
          Min Amount
        </Col>
      </Row>

      <Row className="border-bottom-grey password-clr small-font fw-600 d-flex align-items-center py-1">
        <Col xs={1} className="text-center">
          {1}
        </Col>
        <Col xs={3} className="text-center">
          {selectedTourData?.slug === "casino-tour" ? (
            <>
              {selectedPackageNames.length > 0 ? (
                selectedPackageNames.map((name, index) => (
                  <p key={index}>{name}</p>
                ))
              ) : (
                <p>No packages selected</p>
              )}
            </>
          ) : (
            <>{selectedPackageName} Package</>
          )}
        </Col>
        <Col xs={2} className="text-center">
          {packageCount}
        </Col>
        <Col xs={3} className="text-center">
          {selectedTourData?.slug === "casino-tour" ? (
            <>{totalMembers}</>
          ) : (
            <> {totalGroupMembers} </>
          )}
        </Col>
        <Col xs={3} className="text-center">
          {selectedTourData?.slug === "casino-tour" ? (
            <> {totalPrice.toFixed(2)}</>
          ) : (
            <>
              {selectedOption === "group" ? (
                <>{totalGroupCost.toFixed(2)}</>
              ) : (
                <> {totalPackageCost.toFixed(2)} </>
              )}
            </>
          )}
        </Col>
      </Row>

      <Row className="input-bg py-2 password-clr small-font fw-600">
        <Col xs={4}>Total Amount</Col>
        <Col xs={4} className="fw-700">
          500000.00
        </Col>
        <Col xs={4} className="fw-700">
          {selectedTourData?.slug === "casino-tour" ? (
            <> {totalPrice.toFixed(2)}</>
          ) : (
            <>
              {selectedOption === "group" ? (
                <>{totalGroupCost.toFixed(2)}</>
              ) : (
                <> {totalPackageCost.toFixed(2)} </>
              )}
            </>
          )}
        </Col>
      </Row>
      <div className="d-flex flex-row w-100">
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 w-50 mx-1"
          onClick={() => {
            setActiveStep(0);
            resetData();
          }}
        >
          Buy More
        </div>
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 w-50 mx-1"
          onClick={() => {
            setActiveStep(2);
          }}
        >
          Proceed to Pay
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
