import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../Images";
import { FaEdit, FaFlag, FaHeart, FaTrashAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import { MdOutlineEmojiEmotions, MdOutlineZoomInMap } from "react-icons/md";
import { LuSendHorizonal } from "react-icons/lu";
import { useNavigate, useParams } from "react-router";
import {
  sendComments,
  editComment,
  deleteComment,
  getAllMemories,
  getMemoryById,
  commnetDelReq,
  replyComment,
} from "../../api/apiMethods";
import { HiDotsVertical } from "react-icons/hi";
import DeletePopup from "../popups/DeletePopup";
import { Button, Modal } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import { LuReply } from "react-icons/lu";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import moment from "moment";
import { IoClose } from "react-icons/io5";

const PhotosVideos = () => {
  const { id } = useParams();
  console.log(id, "id--");
  const navigate = useNavigate();

  const login_userid = localStorage.getItem("user_id");
  const loginUserId = Number(login_userid);
  console.log(loginUserId, "loginUserId");

  const [likeClicked, setLikeClicked] = useState(true);
  const [commentClicked, setCommentClicked] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleLikeClick = () => {
    setLikeClicked(!likeClicked);
    setCommentClicked(false);
  };

  const handleCommentClick = () => {
    setCommentClicked(!commentClicked);
    setLikeClicked(false);
  };
  const [like, setlike] = useState(0);
  const handlelike = () => {
    setlike(!like);
  };

  const [report, setreport] = useState();
  const [show, setShow] = useState(false);
  const [others, setothers] = useState();
  const handleClose = () => setShow(false);
  const [commentIdForReport, setCommentIdForReport] = useState(null);

  const handleShow = (commnetDelReqId) => {
    setShow(true);
    setreport(false);
    setCommentIdForReport(commnetDelReqId);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const [customReason, setCustomReason] = useState("");
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value !== "others") {
      setothers(false);
    }
  };

  const [getMemorydata, setGetMemorydata] = useState([]);
  console.log(getMemorydata, "get memory data");
  const getAllMemoriesById = () => {
    getMemoryById(id)
      .then((response) => {
        if (response.status === true) {
          setGetMemorydata(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    getAllMemoriesById();
  }, [id]);

  const [getMemories, setGetMemories] = useState([]);
  const fetchAllMemories = () => {
    getAllMemories()
      .then((response) => {
        if (response.status === true) {
          console.log(response, "res");
          setGetMemories(response?.data?.record);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMemories();
  }, []);

  const handleCommentReportSubmit = () => {
    const deleteReason = {
      delete_reason:
        selectedOption === "others" ? customReason : selectedOption,
    };
    console.log(deleteReason, "deleteReason");
    console.log(commentIdForReport, "com del req id");
    commnetDelReq(commentIdForReport, deleteReason)
      .then((response) => {
        if (response.status === true) {
          console.log("Comment reported:", response);
        } else {
          console.log("Error in reporting comment");
        }
      })
      .catch((error) => console.log(error.message));
    handleClose();
  };

  const [openedCommentId, setOpenedCommentId] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);

  const handleOptions = (commentId) => {
    setOpenedCommentId((prev) => (prev === commentId ? null : commentId));
  };

  const handleEdit = (commentId, commentText, item) => {
    console.log(item, "===>ITEM");
    setOpenedCommentId(null);
    setEditingCommentId(commentId);
    setComment(commentText);
    console.log("edit");
  };

  const inputRef = useRef();
  useEffect(() => {
    if (editingCommentId) {
      inputRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [editingCommentId]);

  const formatDateTime = (time) => {
    const dataObj = new Date(time);
    const formatDate = dataObj.toLocaleDateString("en-GB");
    const formatTime = dataObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formatDate}, ${formatTime}`;
  };

  const [comment, setComment] = useState("");
  console.log(editingCommentId, "editingCommentId");

  const createComment = () => {
    if (editingCommentId) {
      editComment(editingCommentId, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "ress");
            setEditingCommentId(null);
            setComment("");
            getAllMemoriesById();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      sendComments(id, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log("respo", response);
            setComment("");
            getAllMemoriesById();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    }
  };

  const [commentid, setCommentid] = useState(null);
  const deleteCommentById = () => {
    if (commentid) {
      deleteComment(commentid)
        .then((response) => {
          if (response.status === true) {
            console.log(response, "res delete comment");
            getAllMemoriesById();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    }
    setShowDeletePopup(false);
  };

  const handleDeletePopup = (deleteCommentId) => {
    setOpenedCommentId(null);
    setShowDeletePopup(true);
    setCommentid(deleteCommentId);
    console.log("delete");
  };

  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");
  const handleReplyClick = (commentId) => {
    setReplyingCommentId(replyingCommentId === commentId ? null : commentId);
  };

  const [showReplies, setShowReplies] = useState(false);
  const openReplies = (commentId) => {
    setShowReplies(showReplies === commentId ? null : commentId);
    console.log("show replies");
  };

  const handleSubmitReply = (parentcommentId) => {
    const replyData = {
      parent_comment_id: parentcommentId,
      comment: replyText,
    };
    console.log(replyData, "replyData");
    replyComment(id, replyData);
    console
      .log(id, "id reply")
      .then((response) => {
        if (response.status === true) {
          console.log(response, "relpy to comments");
          setReplyText("");
          handleClosereplySection();
          getAllMemoriesById();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  const handleClosereplySection = () => {
    setReplyingCommentId(null);
    setReplyText("");
  };

  const morePosts = getMemories.filter(
    (memory) => memory?.memory_id !== Number(id) && memory?.type !== "video"
  );
  console.log(morePosts, "morePosts");

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenedCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div className="mt-2">
        <div className="w-100 flex-center">
          <img
            src={getMemorydata?.profile_image || Images.balibg}
            alt="img"
            className="balibg"
          />
          <div className="zoom-out clr-white large-font">
            <MdOutlineZoomInMap />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-around">
          <div className="col-8  m-2 mt-2 box-shadow-1 br-10">
            <div
              className="d-flex  w-100 justify-content-between  mt-2 px-2 border-bottom-greyy"
              onClick={handlelike}
            >
              <div
                onClick={handleLikeClick}
                className={
                  likeClicked
                    ? "clicked cursor-pointer fw-600 large-font "
                    : "cursor-pointer large-font"
                }
              >
                <FaHeart className="clr-red mx-2" />
                <span className="clr-black">
                  {getMemorydata?.total_likes_count}
                </span>
              </div>
              <div
                onClick={handleCommentClick}
                className={
                  commentClicked
                    ? "clicked cursor-pointer fw-600 large-font"
                    : "cursor-pointer large-font"
                }
              >
                <FaCommentDots className="clr-comment  mx-2" />
                <span className="clr-black">
                  {getMemorydata?.comments_count}
                </span>
              </div>
            </div>

            {likeClicked === true && (
              <div className="chat-item">
                {getMemorydata?.likes?.map((chat, index) => (
                  <div key={index} className="d-flex ">
                    <div className="position-relative">
                      <img
                        src={Images?.chatpic1 || chat?.like_user_profile_image}
                        alt={chat?.like_user_name}
                        className="like-images mt-2 mx-2"
                      />

                      <FaHeart className="x-large-font clr-red me-1 pos-like-icons" />
                    </div>
                    <div className="mx-2  mt-2">
                      <div className="chat-header">
                        <p className="chat-name medium-font p-0 m-0 fw-600 clr-black">
                          {chat?.like_user_name}
                        </p>
                        <span className="small-font clr-black">
                          {formatDateTime(chat?.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="">
              {commentClicked === true && (
                <div className="scrollabale-comments">
                  {getMemorydata?.comments?.map((item, index) => (
                    <div className="mt-2 d-flex position-relative">
                      <div>
                        <img
                          src={
                            Images?.chatpic2 || item?.comment_user_profile_image
                          }
                          alt=""
                          className="like-images mt-2 mx-2"
                        />
                      </div>

                      <div className="d-flex flex-column w-fill ">
                        <div className="chat-text-bg py-2 br-10 me-2 mb-1">
                          <div className="d-flex flex-between">
                            <div className="d-flex flex-start align-items-center">
                              <div className="flex-column">
                                <div className="small-font px-1">
                                  {item?.comment_user_name}
                                </div>

                                <div className="w-100 py-1 flex-start br-10 px-2 small-font ">
                                  {item?.comment}
                                  {item?.edit === 1 && (
                                    <span className="small-font clr-grey2 ms-2">
                                      (edited)
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {item?.comment_user_id !== loginUserId &&
                              getMemorydata?.user_id === loginUserId && (
                                <div
                                  className="clr-black py-1 small-font mx-1 cursor-pointer"
                                  onClick={() => handleShow(item?.comment_id)}
                                >
                                  <FaFlag className="me-1" />
                                  Report
                                </div>
                              )}
                            {item?.comment_user_id === loginUserId && (
                              <div>
                                <span className="cursor-pointer">
                                  <HiDotsVertical
                                    className="cursor"
                                    onClick={() =>
                                      handleOptions(item?.comment_id)
                                    }
                                  />
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="d-flex flex-end ">
                            <div className="pos-delete-option br-10 px-2 position-absolute ">
                              {openedCommentId === item?.comment_id && (
                                <div
                                  className="d-flex flex-column"
                                  ref={dropdownRef}
                                >
                                  <div className="clr-black py-1 border-bottom-grey small-font cursor-pointer">
                                    <FaTrashAlt className="me-1" />

                                    <span
                                      onClick={() =>
                                        handleDeletePopup(item?.comment_id)
                                      }
                                    >
                                      Delete
                                    </span>
                                  </div>
                                  <div
                                    className="clr-black py-1 border-bottom-grey small-font cursor-pointer"
                                    onClick={() =>
                                      handleEdit(
                                        item?.comment_id,
                                        item?.comment,
                                        item
                                      )
                                    }
                                  >
                                    <FaEdit className="me-1" />
                                    Edit
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-between ">
                          <div className="pe-2 small-font mx-1">
                            {formatDateTime(item?.created_at)}
                          </div>
                          <div>
                            {item?.comment_user_id !== loginUserId && (
                              <span
                                className="d-flex flex-end align-items-center small-font mx-1 cursor-pointer"
                                onClick={() =>
                                  handleReplyClick(item?.comment_id)
                                }
                              >
                                <LuReply className="clr-black mx-1" />
                                Reply
                              </span>
                            )}
                            {replyingCommentId === item?.comment_id && (
                              <div className="d-flex px-3 py-3 flex-column">
                                <input
                                  type="text"
                                  className="border-bottom-black input-none small-font"
                                  placeholder="Write a reply..."
                                  value={replyText}
                                  onChange={(e) => setReplyText(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleSubmitReply(item?.comment_id);
                                    }
                                  }}
                                />
                                <div className="d-flex flex-end align-items-center mt-2 small-font">
                                  <div
                                    className="input-bg px-2 mx-2 py-1 br-20 cursor-pointer"
                                    onClick={() =>
                                      handleSubmitReply(item?.comment_id)
                                    }
                                  >
                                    Reply
                                  </div>
                                  <div
                                    className="px-2 cursor-pointer"
                                    onClick={handleClosereplySection}
                                  >
                                    cancel
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-2 mb-2">
                          <span
                            className={`clr-blue cursor-pointer px-1 py-1 br-10 mt-2 mb-2 ms-3 small-font ${
                              item?.replies?.length > 0 ? "input-reply-bg" : ""
                            }`}
                            onClick={() => openReplies(item?.comment_id)}
                          >
                            {item?.replies?.length > 0 && (
                              <span>
                                {showReplies ? (
                                  <span>
                                    <IoIosArrowUp className="small-font" />
                                    {item?.replies?.length === 1
                                      ? `${item.replies.length} reply`
                                      : `${item.replies.length} replies`}
                                  </span>
                                ) : (
                                  <span>
                                    <IoIosArrowDown className="small-font" />
                                    {item?.replies?.length === 1
                                      ? `${item.replies.length} reply`
                                      : `${item.replies.length} replies`}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>

                          {showReplies === item?.comment_id &&
                            item?.replies?.map((reply, index) => (
                              <div className="d-flex input-bg p-1 mt-2 br-5">
                                <img
                                  className="h-5h"
                                  src={
                                    Images.chatpic1 ||
                                    reply?.comment_user_profile_image
                                  }
                                  alt="profile"
                                />
                                <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center">
                                    <div className="mx-2 small-font">
                                      {reply?.comment_user_name}
                                    </div>
                                    <div className="mx-1 small-font">
                                      {moment(reply?.created_at).fromNow()}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="ms-2 small-font">
                                      {reply?.comment}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!likeClicked && (
                <>
                  <div className="border-black py-1 br-10 d-flex align-items-center input-bg flex-center mx-2 mb-3 mt-2">
                    <div className="w-80">
                      <input
                        type="text"
                        placeholder="write a comment "
                        className="input-none w-100 py-2"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            createComment();
                          }
                        }}
                      />
                    </div>
                    <div className="white-bg br-20 px-2 py-1 large-font mx-2 cursor-pointer">
                      <IoClose
                        className="clr-black"
                        onClick={() => {
                          setComment("");
                          setReplyText("");
                        }}
                      />
                    </div>
                    <div
                      className="large-font clr-pink mx-2 white-bg br-20 px-2 py-1 cursor-pointer"
                      onClick={createComment}
                    >
                      <LuSendHorizonal />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-4 w-40 box-shadow-1 br-10 mt-2">
            <div className="fw-600 p-2 large-font">More Post</div>

            <div className="overflow-y more-image-box">
              {morePosts.map((item) => (
                <div
                  className="image-container pointer"
                  onClick={() => navigate("/memories")}
                  title={"Click to view this photo in Memories"}
                >
                  <img
                    src={Images.threeboys || item?.path}
                    alt=""
                    className="more-images m-2"
                  />
                  <div className="overlay">
                    <div className="text">
                      <span>
                        <FaHeart className="clr-like mx-1 font-20" />
                        {item?.total_likes_count}
                      </span>
                      <span>
                        <FaCommentDots className="mx-1 font-20" />
                        {item?.comments_count}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={deleteCommentById}
        description="Are you sure to delete this comment?"
      />

      <div>
        <Modal show={show} size="sm" onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title className="medium-font">Select option</Modal.Title>
            <IoClose className="font-20 pointer" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className="small-font">
              <div>
                <input
                  type="radio"
                  id="option1"
                  name="options"
                  className="small-font mx-1"
                  value="Offensive Language"
                  checked={selectedOption === "Offensive Language"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option1">Offensive Language</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="option2"
                  name="options"
                  className="small-font mx-1"
                  value="Harassment or Bullying"
                  checked={selectedOption === "Harassment or Bullying"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option2">Harassment or Bullying</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="option3"
                  className="small-font mx-1"
                  name="options"
                  value="Spam or Misleading"
                  checked={selectedOption === "Spam or Misleading"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option3">Spam or Misleading</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="option2"
                  name="options"
                  value="others"
                  className="small-font mx-1"
                  checked={selectedOption === "others"}
                  onClick={handleOptionChange}
                />
                <label>Others</label>
                {selectedOption === "others" && (
                  <div>
                    <textarea
                      className="w-100 mt-1 br-5 px-1"
                      placeholder="Please Explain Your Report"
                      value={customReason}
                      onChange={(e) => setCustomReason(e.target.value)}
                    ></textarea>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="pink-bg px-2 py-1 br-5 medium-font clr-white pointer"
              onClick={() => handleCommentReportSubmit()}
            >
              Submit
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PhotosVideos;
