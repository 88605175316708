import React from "react";

function Table(props) {
  const { data, columns, title } = props;
  return (
    <div className="table-bg">
      {title && <div className="table-title table-row py-2">{title}</div>}
      <div className="w-100 table-container">
        <table className="w-100">
          <thead>
            <tr className="table-row border-top">
              {columns?.map((column, index) => (
                <th className="table-head text-center fw-600" key={index}>
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length &&
              data?.map((row, rowIndex) => (
                <tr key={rowIndex} className="table-row">
                  {columns?.map((column, colIndex) => (
                    <td
                      className="py-2 table-text text-center fw-600"
                      key={colIndex}
                    >
                      {row[column.field]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
