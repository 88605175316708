import React from "react";
import BforeLoginSideBar from "./BforeLoginSideBar";
import BeforeLoginMemories from "./BeforeLoginMemories";

function BforeLoginHomePage() {
  return (
    <div className="row">
      <div className="col-md-9">
        <BeforeLoginMemories/>
        {/* <Memories /> */}
      </div>
      <div className="col-md-3">
        <BforeLoginSideBar />
      </div>
    </div>
  );
}

export default BforeLoginHomePage;
