import React from "react";
import { BsChatLeftText } from "react-icons/bs";

const Notice = () => {
  return (
    <div className="chat-bg chat-height d-flex flex-center ">
      {/* <div ><BsChatLeftText size={100} className="message-icon"/></div> */}
    </div>
  );
};

export default Notice;
