import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { updateUserWithdrawDetailsByID } from "../../api/apiMethods";
import SubmitPopup from "../popups/SubmitPopup";

function ViewWithdrawlDetailsPopup({
  viewWithdrawDetails,
  setViewWIthdrawDetails,
  withdrawalDetails,
  viewWithdrawMode,
  setViewWithdrawMode,
  selectWithdrawId,
}) {
  console.log(selectWithdrawId, "selectWithdrawId");
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imagePath) => {
    setZoomedImage(imagePath);
  };

  const closeZoomModal = () => {
    setZoomedImage(null);
  };

  return (
    <>
      <Modal size="md" show={viewWithdrawDetails} centered>
        <Modal.Body>
          <div className="px-2">
            <div className="flex-between">
              <div className="text-black large-font fw-bold">
                {viewWithdrawMode
                  ? "View Withdraw Details"
                  : "Edit Withdraw Details"}
              </div>
              <RxCross2
                className="pointer font-2rem"
                onClick={() => setViewWIthdrawDetails(false)}
              />
            </div>

            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Role</label>
                <input
                  type="text"
                  value={withdrawalDetails?.role_name || ""}
                  placeholder="Customer"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
              <div className="col-6">
                <label className="mb-1">Name</label>
                <input
                  type="text"
                  value={withdrawalDetails?.name || ""}
                  placeholder="Srinivas"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
            </div>

            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Balance"
                  value={withdrawalDetails?.balance_amount || ""}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Withdraw Balance"
                  value={withdrawalDetails?.withdrawAmount || ""}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
            </div>
            {viewWithdrawMode ? null : (
              <div className="row mt-2 medium-font fw-600">
                <div className="col-6">
                  <label className="mb-1">Enter Amount</label>
                  <input
                    type="number"
                    placeholder="Amount"
                    value={withdrawalDetails?.withdrawAmount || ""}
                    className="input-box1 br-10 p-2 w-fill fw-500"
                    min={1000}
                    readOnly
                  />
                </div>
                <div className="col-6">
                  <label className="mb-1">Payment Mode</label>
                  <input
                    type="text"
                    placeholder="Payment Mode"
                    value={withdrawalDetails?.payment_mode || ""}
                    className="input-box1 br-10 p-2 w-fill fw-500"
                    readOnly
                  />
                </div>{" "}
              </div>
            )}

            <div className="flex-column w-100 mt-2 medium-font fw-600">
              <label className="mb-1">UPI/Bank Details</label>
              <textarea
                className="input-box1 br-10 p-2 w-fill fw-500"
                value={withdrawalDetails?.bank_details || "N/A"}
                placeholder="Enter your UPI or Bank details here"
                readOnly
              />
            </div>
            <div className="flex-column w-100 mt-2 medium-font fw-600">
              <label className="mb-1">Payment Receipt</label>
              <img
                className="w-100 h-10vh cursor-pointer"
                src={withdrawalDetails?.path}
                onClick={() => handleImageClick(withdrawalDetails?.path)}
                alt="Receipt"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Zoom Image Modal */}
      {zoomedImage && (
        <Modal size="lg" show={true} centered onHide={closeZoomModal}>
          <Modal.Body className="p-0">
            <img
              src={zoomedImage}
              alt="Zoomed Receipt"
              className="w-100"
              style={{ maxHeight: "90vh", objectFit: "contain" }}
            />
          </Modal.Body>
          <button
            onClick={closeZoomModal}
            className="btn btn-secondary position-absolute top-0 end-0 m-3"
          >
            Close
          </button>
        </Modal>
      )}
    </>
  );
}

export default ViewWithdrawlDetailsPopup;
