import React from "react";

const ChatBubble = () => {
  const chatBubbleStyle = {
    backgroundColor: "#E6F8F1",
    padding: "16px 28px",
    borderRadius: "20px",
    borderBottomLeftRadius: "2px",
    display: "inline-block",
  };

  const typingStyle = {
    display: "flex",
    alignItems: "center",
    height: "17px",
  };

  const dotStyle = {
    animation: "mercuryTypingAnimation 1.8s infinite ease-in-out",
    backgroundColor: "#6CAD96",
    borderRadius: "50%",
    height: "7px",
    marginRight: "4px",
    width: "7px",
    display: "inline-block",
  };

  const keyframes = `
    @keyframes mercuryTypingAnimation {
      0% {
        transform: translateY(0px);
        background-color: #6CAD96;
      }
      28% {
        transform: translateY(-7px);
        background-color: #9ECAB9;
      }
      44% {
        transform: translateY(0px);
        background-color: #B5D9CB;
      }
    }
  `;

  return (
    <div>
      <style>{keyframes}</style>
      <div style={chatBubbleStyle}>
        <div style={typingStyle}>
          <div style={{ ...dotStyle, animationDelay: "200ms" }}></div>
          <div style={{ ...dotStyle, animationDelay: "300ms" }}></div>
          <div style={{ ...dotStyle, animationDelay: "400ms", marginRight: 0 }}></div>
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;
