import {  useEffect, useState } from "react";
import { Images } from "../Images";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setTourCategoryData } from "./../redux/action";
import {
  FaHeart,
  FaRegHeart,
  FaArrowLeftLong,
  FaArrowRightLong,
} from "react-icons/fa6";
import {
  getBannerById,
  getBestDestination,
  getTourCategories,
  getPromotionalBanner,
} from "../api/apiMethods";
import moment from "moment";
import { LuSearch, LuSettings2 } from "react-icons/lu";

function Content({ chatMinimize }) {
  const userDetails = useSelector((state) => state.userDetailsData);
  const role_id = localStorage?.getItem("role_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bannerImage, setBannerImage] = useState();
  const [tourCategory, setTourCategory] = useState();
  const [bestDestinationData, setBestDestinationData] = useState([]);
  const [promotionBannerData, setPromotionBannerData] = useState([]);

  console.log(tourCategory,"===>tourCategory");

  const [searchQuery, setSearchQuery] = useState("");

  const searchData = bestDestinationData.filter((item) =>
    item?.tour_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const typeOfTours =
    tourCategory
      ?.filter((item) => item?.is_blocked === 0)
      ?.map((item) => {
        return {
          img: item?.upload_path || Images?.poster_02,
          tourCategory: item?.category_name,
          tourPath: item?.slug,
        };
      }) || [];

  const maxVisibleTours = chatMinimize ? 3 : 4;
  const totalTours = typeOfTours.length;
  const toursToDisplay =
    totalTours > 0
      ? typeOfTours.slice(currentIndex, currentIndex + maxVisibleTours)
      : [];

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? totalTours - maxVisibleTours : prevIndex - 1
      );
    } else if (direction === "right") {
      setCurrentIndex((prevIndex) =>
        prevIndex === totalTours - maxVisibleTours ? 0 : prevIndex + 1
      );
    }
  };

  const [likes, setLikes] = useState(bestDestinationData?.map(() => false));
  const handleLikeChange = (index) => {
    const newLikes = [...likes];
    newLikes[index] = !newLikes[index];
    setLikes(newLikes);
  };
  console.log(toursToDisplay, "tour to display");

  const getBannerImage = () => {
    getBannerById(role_id)
      .then((response) => {
        if (response?.status === true) {
          setBannerImage(response?.data?.banner_path);
          console.log(response?.data?.banner_path)
        } else {
          console.error("Something went wrong");
        }
      })
      .catch((error) => console.error(error?.message));
  };

  useEffect(() => {
    getBannerImage();
  }, [role_id]);

  useEffect(() => {
    getTourCategories({})
      .then((response) => {
        if (response?.status === true) {
          setTourCategory(response?.data?.category);
          dispatch(setTourCategoryData(response?.data?.category));
        } else {
          console.error("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));

    getBestDestination()
      .then((response) => {
        if (response?.status) {
          setBestDestinationData(response.data || []);
        }
      })
      .catch((error) => console.error(error?.message));

    getPromotionalBanner()
      .then((response) => {
        if (response?.status === true) {
          setPromotionBannerData(response?.data);
        } else {
          console.error("Failed to fetch tours");
        }
      })
      .catch((error) => console.error(error.message));
  }, []);

  return (
    <div className="w-100 p-2">
      <div
        style={{
          backgroundImage: `url(${ Images?.BgBannerOne || bannerImage })`,
        }}
        className="dashboard-class db-height py-2 px-3"
      >
        <div className={`rounded-top w-100 d-flex align-items-center py-2`}>
          <h6 className={`w-15 white-space fw-bold mx-2  clr-white`}>
            Hello User
          </h6>
          <div className="w-fill d-flex align-items-center input-proper p-1">
            <LuSearch className="ms-2" />
            <input
              className="search-input p-1 small-font"
              placeholder="Search Destinatioin"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="px-3 py-2 pink-clr rounded-pill mx-2 flex-center w-10">
            <LuSettings2 className="d-flex large-font clr-white" />
          </div>
          <div className="medium-font  p-2 pink-clr rounded-pill clr-white flex-center me-2 fw-600 w-20">
            Search
          </div>
        </div>
        <div className="flex-between w-100 mb-1 align-items-center">
          <h6 className="font-weight-bold clr-white">
            Coming Most Popular Tours
          </h6>
          <div className="d-flex w-10">
            <div
              className={`left-arrow d-flex mx-1 justify-content-center align-items-center cursor-pointer  ${
                active === "left" ? "" : "white-bg password-clr"
              }`}
              onClick={() => {
                handleArrowClick("left");
                setActive("left");
              }}
            >
              <FaArrowLeftLong className="medium-font" />
            </div>
            <div
              className={`left-arrow d-flex mx-1 justify-content-center align-items-center cursor-pointer ${
                active === "right" ? "" : "white-bg password-clr"
              }`}
              onClick={() => {
                handleArrowClick("right");
                setActive("right");
              }}
            >
              <FaArrowRightLong className="medium-font" />
            </div>
          </div>
        </div>
        <div className="row pointer">
          {toursToDisplay.map((item, index) => (
            <div
              key={index}
              className={chatMinimize ? "col-4" : "col-3"}
              onClick={() => navigate(`/tour/${item.tourPath}`)}
            >
              <div className="card-container d-flex flex-column">
                <img
                  src={item.img}
                  className="w-100 tour-image br-10"
                  alt={item.tourCategory}
                />
                <div className="fw-600 medium-font mt-1">
                  {item.tourCategory}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-100 d-flex">
        <div className="col-8">
          <div className="box-shadow-1 bg-1 py-3 ps-3 pe-1">
            <div className="flex-between align-items-center pe-2">
              <span>
                <h6 className="password-clr fw-bold">Best Destination</h6>
                <div className="small-font grey-text fw-500 my-2">
                  {bestDestinationData?.length} Destination found
                </div>
              </span>
              <span className="password-clr medium-font fw-500">
                Select Your Trip
              </span>
            </div>
            <div className="destination-scroll pe-2">
              {searchData?.length > 0 ? (
                <>
                  {searchData?.map((item, index) => (
                    <div className="flex-between mt-2" key={index}>
                      <div className="d-flex align-items-center">
                        <video
                          width="120"
                          height="80"
                          src={item?.video_path}
                          className="rounded-3"
                          controls
                        />
                        <div className="flex-column d-flex ms-3">
                          <div className="sub-text font-weight-bold">
                            {item?.tour_title}
                          </div>
                          <div className="small-font grey-text fw-500 mt-2 d-flex align-items-center">
                            <FaRegCalendarAlt className="me-1 clr-black" />
                            {moment(item?.schedule_from).format("DD-MM-YYYY")}
                          </div>
                          <div className="small-font grey-text fw-500 mt-2 d-flex align-items-center">
                            <IoLocationOutline className="me-1 clr-black" />{" "}
                            {item?.tour_location}
                          </div>
                        </div>
                      </div>
                      <div className="flex-center flex-column like-position">
                        <input
                          type="checkbox"
                          id={`like-${index}`}
                          onChange={() => handleLikeChange(index)}
                          checked={likes[index]}
                          hidden
                        />
                        <label htmlFor={`like-${index}`} className="pointer">
                          {likes[index] ? (
                            <FaHeart className="like-clr pointer like-icon" />
                          ) : (
                            <FaRegHeart className="like-clr pointer like-icon" />
                          )}
                        </label>
                        <div className="sub-text fw-600">I am Interested</div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="medium-font">No Destinations Available</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-4">
          {promotionBannerData.length > 0 ? (
            promotionBannerData.map((item, index) => (
              <div
                key={index}
                className="expore-more-div"
                style={{
                  backgroundImage: `url(${
                    item?.path || Images?.FootballImage
                  })`,
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center w-100 position-text">
                  <div className="medium-font clr-white fw-600 w-75 text-center mb-1">
                    {item?.text}
                  </div>
                  <div className="small-font clr-white mt-2">
                    1000 destination found
                  </div>
                  <div className="d-flex justify-content-center flex-column align-items-center w-100 mt-4">
                    <div className="pink-bg py-2 clr-white br-20 w-50 my-1 text-center medium-font fw-bold cursor-pointer">
                      Book Now
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{ backgroundImage: `url(${Images?.FootballImage})` }}
              className="expore-more-div"
            >
              <div className=" px-4 py-3 medium-font clr-white">
                No Tours To Display
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Content;
