import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import QRCodeModal from "./QRCodeModal";
import { cancelUserTrip } from "../../api/apiMethods";
import SubmitPopup from "./SubmitPopup";

function CancelExpences({
  showExpenceModal,
  setShowExpenceModal,
  setConfirmModal,
  bookingIdToCancel,
  refreshTours,
}) {
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);

  const [upiDetails, setUpiDetails] = useState({
    upiNumber: "1234567890@upi",
    name: "John Doe",
  });

  const [neftDetails, setNeftDetails] = useState({
    ifsc: "XYZB0001234",
    bankAccount: "9876543210",
    name: "Jane Doe",
    phoneNumber: "9876543210",
  });

  const [textAreaContent, setTextAreaContent] = useState("");

  useEffect(() => {
    if (selectedPaymentGateway === "QR Code") {
      setTextAreaContent("");
    } else if (
      ["Phone Pe", "Google Pay", "Paytm"].includes(selectedPaymentGateway)
    ) {
      setTextAreaContent(
        `UPI Number: ${upiDetails.upiNumber}\nName: ${upiDetails.name}`
      );
    } else if (selectedPaymentGateway === "NEFT") {
      setTextAreaContent(
        `IFSC Code: ${neftDetails.ifsc}\nBank Account: ${neftDetails.bankAccount}\nName: ${neftDetails.name}\nPhone Number: ${neftDetails.phoneNumber}`
      );
    } else {
      setTextAreaContent("");
    }
  }, [selectedPaymentGateway, upiDetails, neftDetails]);

  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
  };

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);

  const handleShowQrCode = () => {
    setShowQrCode(true);
  };

  const handleCloseQrCode = () => {
    setShowQrCode(false);
  };

  const handleQrCodeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQrCodeImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const CancelTheUpcomingTour = () => {
    const payment_gateway = selectedPaymentGateway;
    let banking_details = "";
    if (payment_gateway === "QR Code") {
      banking_details = qrCodeImage;
    } else {
      banking_details = textAreaContent;
    }

    const payload = {
      payment_gateway,
      banking_details,
    };
    console.log("Payload:", payload);

    cancelUserTrip(bookingIdToCancel, payload)
      .then((res) => {
        if (res?.status === true) {
          console.log("Tour Is Cancelled");
          setShowSubmitPopup(true);
          setShowExpenceModal(false);
          refreshTours();
        } else {
          console.log("Something wrong happened");
        }
      })
      .catch((error) => console.log(error.message));
  };
  return (
    <div>
      <Modal show={showExpenceModal} centered>
        <Modal.Body className="px-3">
          <div className="d-flex justify-content-end pointer">
            <RxCross2
              className="icon-font"
              onClick={() => setShowExpenceModal(false)}
            />
          </div>
          <h5 className="fw-600 text-black d-flex justify-content-center mb-2">
            TOTAL EXPENCES
          </h5>

          <h6 className="fw-600 text-black d-flex justify-content-center my-2 medium-font">
            Add Your Original Payment Method (IND/INR)
          </h6>
          <div className="w-100">
            <label className="medium-font fw-500 mb-1 text-black">
              Payment Gateway
            </label>
            <select
              className="medium-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
              value={selectedPaymentGateway}
              onChange={handlePaymentGatewayChange}
            >
              <option value="">Select Payment Gateway</option>
              <option value="Phone Pe">Phone Pe</option>
              <option value="Google Pay">Google Pay</option>
              <option value="Paytm">Paytm</option>
              <option value="NEFT">NEFT</option>
              <option value="QR Code">QR Code</option>
            </select>
          </div>

          {selectedPaymentGateway === "QR Code" && (
            <div className="w-100 mt-2">
              <label className="medium-font fw-500 mb-1 text-black">
                Upload QR Code
              </label>
              <input
                type="file"
                className="medium-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
                onChange={handleQrCodeUpload}
              />
            </div>
          )}

          {selectedPaymentGateway !== "QR Code" && (
            <div className="w-100 mt-2">
              <label className="medium-font fw-500 mb-1 text-black">
                UPI/Bank Details
              </label>
              <textarea
                className="medium-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
                value={textAreaContent}
                readOnly
              />
            </div>
          )}

          {qrCodeImage && (
            <div className="flex-center mt-2">
              <img
                onClick={handleShowQrCode}
                src={qrCodeImage}
                alt="QR Code"
                className="qr-code-img"
              />
            </div>
          )}

          <div className="flex-between w-100 mt-3 medium-font fw-600">
            <div
              className="pink-bg clr-white w-48 p-2 rounded-pill text-center"
              onClick={() => CancelTheUpcomingTour()}
            >
              Proceed
            </div>
            <div
              className="password-clr border-grey white-bg text-center p-2 w-48 rounded-pill"
              onClick={() => setShowExpenceModal(false)}
            >
              Cancel
            </div>
          </div>
        </Modal.Body>
        <QRCodeModal
          showQrCode={showQrCode}
          qrCodeImage={qrCodeImage}
          handleCloseQrCode={handleCloseQrCode}
        />
      </Modal>
      <SubmitPopup
        successPopup={showSubmitPopup}
        setSuccessPopup={setShowSubmitPopup}
        title="Requested For Cancellation"
        description="Your cancellation request has been submitted Successfully"
      />
    </div>
  );
}

export default CancelExpences;
