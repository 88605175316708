import axios from "axios";
import { chattingBaseUrl } from "../baseUrl";

const baseUrl = chattingBaseUrl;
const jwt_token = localStorage.getItem("jwt_token");

const personalPhotos = {
  requestForPersonalPhotos: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/memories/request-access/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error requesting personal photos access:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  acceptPersonalPhotosRequest: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/memories/accept-request/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error accepting personal photos request:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  rejectPersonalPhotosRequest: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/memories/reject-request/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error rejecting personal photos request:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  getThePersonalPhotos: async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/api/memories/access/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error retrieving personal photos:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  getPersonalPhotosRequest: async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/memories/pending-requests`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error retrieving personal photo requests:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};

export default personalPhotos;
