import React, { useEffect, useState } from "react";
import AddAmountPopup from "./AddAmountPopup";
import Table from "../../components/Table";
import { HiOutlineEye } from "react-icons/hi2";
import { BiMoneyWithdraw } from "react-icons/bi";
import {
  deleteUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID,
  getUserDeposit,
  getUserDepositDetailsByID,
  getUserWithdrawDetailsByID,
  getWalletStatus,
  getWalletTable,
  updateUserWithdrawDetailsByID,
} from "../../api/apiMethods";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import ViewWithdrawlDetailsPopup from "./ViewWithdrawlDetailsPopup";
import DeletePopup from "../popups/DeletePopup";
import ViewDepositViewPopup from "./ViewDepositViewPopup";
import EditDepositDetails from "./EditDepositDetails";
import EditWithdrawDetailsPopup from "./EditWithdrawDetailsPopup";
import { useDispatch } from "react-redux";
import { setWithdrawData } from "../../redux/action";
import Withdraw from "./Withdraw";

function Wallet() {
  const dispatch = useDispatch();
  const [activeBtn, setActiveBtn] = useState(0);
  const [withdraw, setWithdraw] = useState(false);
  const handleActiveBtn = (index) => {
    setActiveBtn(index);
  };
  const [viewWithdrawMode, setViewWithdrawMode] = useState(true);
  const [viewDepositMode, setViewDepositMode] = useState(true);
  const [editDepositDetail, setEditDepositDetail] = useState(false);

  const handleWithdrawPopupOpen = (id) => {
    getUserWithdrawData(id);
    setWithdraw(true);
  };

  const [userWalletStatsData, setUserWalletsData] = useState();
  console.log(userWalletStatsData, "userWalletStatsData");

  const userWalletStatus = () => {
    getWalletStatus()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setUserWalletsData(response.data);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };
  const [userWalletWithdrawTable, setUserWalletWithdrawTable] = useState();
  const [userWalletDepositTable, setuserWalletDepositTable] = useState();
  console.log(userWalletDepositTable, "userWalletDepositTable");
  const userWalletTable = () => {
    getWalletTable()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setUserWalletWithdrawTable(response.data.withdraw);
          setuserWalletDepositTable(response.data.deposit);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    userWalletStatus();
    userWalletTable();
  }, []);
  const [userDepositData, setUserDepositData] = useState(false);
  console.log(userDepositData, "userDepositData");
  const userDeposits = () => {
    getUserDeposit()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "get deposit data");
          setUserDepositData(response.data.record);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    userDeposits();
  }, []);
  const buttons = ["Deposit", "Withdraw"];
  const withdrawData = [
    {
      amount: userWalletStatsData?.totalDeposit,
      name: "Total Deposit",
    },
    {
      amount: userWalletStatsData?.totalWithdrawal,
      name: "Total Withdrawal",
    },
    {
      amount: userWalletStatsData?.balance,
      name: "Balance",
      icon: <BiMoneyWithdraw />,
      withdraw: "Withdraw",
    },
  ];

  const TICKETS_COLUMNS = [
    {
      header: <div className="">Date & Time</div>,
      field: "date_time",
    },
    { header: "Tnx", field: "tnx" },
    { header: "Tnx ID", field: "tnx_id" },
    { header: "Amount", field: "amount" },
    { header: "Status", field: "status" },
    { header: "View", field: "view" },
  ];
  const [addAmountPopup, setShowAddAmountPopup] = useState(false);
  const handleAddAmountPopup = () => {
    setShowAddAmountPopup(true);
  };

  const [withdrawalDetails, setWithdrawalDetails] = useState(null);
  const [depositDetails, setDepositDetails] = useState(null);
  console.log(depositDetails, "depositDetails");

  console.log(withdrawalDetails, "withdrawalDetails");

  const [viewWithdrawDetails, setViewWIthdrawDetails] = useState(false);
  const [viewDepositDetails, setViewDepositDetails] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [selectWithdrawId, setSelectedWithdrawId] = useState();

  const [selectDepositId, setSelectedDepositId] = useState();

  const [editWithdrawDetailPopup, setEditWithdrawDetailPopup] = useState(false);

  const handleEditWithdrawPopupOpen = (id) => {
    getUserWithdrawData(id);
    setEditWithdrawDetailPopup(true);
    setSelectedWithdrawId(id);
  };
  console.log(selectDepositId, "selectDepositId");

  const handleEditDepositDetailPopup = (id) => {
    getUserDepositData(id);
    setEditDepositDetail(true);
    setSelectedDepositId(id);
  };

  const handleViewClick = (id) => {
    getUserWithdrawData(id);
    setSelectedWithdrawId(id);
    setViewWithdrawMode(true);
    setViewWIthdrawDetails(true);
  };

  const handleShowDeletePopup = (type, id) => {
    setShowDeletePopup(true);
    if (type === "withdraw") {
      setSelectedWithdrawId(id);
      setSelectedDepositId(null);
    } else if (type === "deposit") {
      setSelectedDepositId(id);
      setSelectedWithdrawId(null);
    }
  };

  const handleDepositViewClick = (id) => {
    getUserDepositData(id);
    setSelectedDepositId(id);
    setViewDepositDetails(true);
  };

  const getUserWithdrawData = (id) => {
    getUserWithdrawDetailsByID(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setWithdrawalDetails(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserWithdrawData();
  }, []);

  const getUserDepositData = (id) => {
    getUserDepositDetailsByID(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setDepositDetails(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserDepositData();
  }, []);
  const deleteUserWithdrawData = () => {
    deleteUserWithdrawDetailsByID(selectWithdrawId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserWithdrawData();
  }, []);
  const deleteUserdepositData = () => {
    deleteUserDepositDetailsByID(selectDepositId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserdepositData();
  }, []);
  const WITHDRAWL_DATA =
    userWalletWithdrawTable &&
    userWalletWithdrawTable?.map((item) => ({
      date_time: (
        <div className="text-start">
          {moment(item.time_date).format("DD-MM-YYYY")}
          <br />
          {moment(item.time_date).format("hh:mm A")}
        </div>
      ),
      tnx: item.type,
      tnx_id: item.tnx_id,
      amount: item.amount,
      status: (
        <div className="flex-center w-100">
          <div
            className={`fit-width p-1 br-10 ${
              item.status === "approved"
                ? "approved-btn "
                : item.status === "pending"
                ? "pending-bg pending-clr"
                : item.status === "rejected"
                ? "reject-bg reject-clr"
                : ""
            }`}
          >
            {item.status}
          </div>
        </div>
      ),
      view: (
        <div className="w-100 flex-center">
          <div className="d-flex w-75 justify-content-around align-items-center">
            <div className="icon-container cursor-pointer" title="View">
              <HiOutlineEye
                className="large-font"
                onClick={() => {
                  handleViewClick(item.withdraw_id);
                }}
              />
            </div>
            <div className="icon-container cursor-pointer" title="Edit">
              <CiEdit
                className="large-font"
                onClick={() => {
                  handleEditWithdrawPopupOpen(item.withdraw_id);
                }}
              />
            </div>
            <div className="icon-container cursor-pointer" title="Delete">
              <RiDeleteBin6Line
                className="large-font"
                onClick={() =>
                  handleShowDeletePopup("withdraw", item.withdraw_id)
                }
              />
            </div>
          </div>
        </div>
      ),
    }));
  const DEPOSIT_DATA =
    userWalletDepositTable &&
    userWalletDepositTable?.map((item) => ({
      date_time: (
        <div className="text-start">
          {moment(item.time_date).format("DD-MM-YYYY")}
          <br />
          {moment(item.time_date).format("hh:mm A")}
        </div>
      ),
      tnx: item.type,
      tnx_id: item.tnx_id,
      amount: item.deposit_amount,
      status: (
        <div className="flex-center w-100">
          <div
            className={`fit-width p-1 br-10 ${
              item.status === "approved"
                ? "approved-btn "
                : item.status === "pending"
                ? "pending-bg pending-clr"
                : item.status === "rejected"
                ? "reject-bg reject-clr"
                : ""
            }`}
          >
            {item.status}
          </div>
        </div>
      ),
      view: (
        <div className="w-100 flex-center">
          <div className="d-flex w-75 justify-content-around align-items-center">
            <div className="icon-container cursor-pointer" title="View">
              <HiOutlineEye
                className="large-font"
                onClick={() => {
                  handleDepositViewClick(item.deposit_id);
                }}
              />
            </div>
            <div className="icon-container cursor-pointer" title="Edit">
              <CiEdit
                className="large-font"
                onClick={() => {
                  handleEditDepositDetailPopup(item.deposit_id);
                }}
              />
            </div>
            <div className="icon-container cursor-pointer" title="Delete">
              <RiDeleteBin6Line
                className="large-font"
                onClick={() =>
                  handleShowDeletePopup("deposit", item.deposit_id)
                }
              />
            </div>
          </div>
        </div>
      ),
    }));
  const handleConfirmDelete = () => {
    if (selectWithdrawId !== null) {
      deleteUserWithdrawData(selectWithdrawId);
    } else if (selectDepositId !== null) {
      deleteUserdepositData(selectDepositId);
    }
    setShowDeletePopup(false);
  };

  const formatAmount = (amount) => {
    if (amount !== undefined && amount !== null) {
      return amount.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "0.00";
  };
  useEffect(() => {
    dispatch(setWithdrawData(withdrawData));
  }, [dispatch, withdrawData]);
  return (
    <div className="mt-2">
      <div className="flex-between">
        <div className="d-flex medium-font fw-600 w-100">
          {buttons.map((btn, index) => (
            <div
              className={`w-20 text-center py-2 mx-1 rounded
        ${
          activeBtn === index
            ? "bg-pink text-white "
            : "bg-white text-black pointer"
        }`}
              onClick={() => handleActiveBtn(index)}
            >
              {btn}
            </div>
          ))}
        </div>
      </div>
      <div className="row my-3">
        {withdrawData.map((item, index) => (
          <div className="col-4 px-2">
            <div className="p-3 rounded-4 bg-white flex-between" key={index}>
              <div>
                <div className="large-font fw-bold clr-green">
                  {formatAmount(item.amount)}
                  {/* {item.amount} */}
                </div>
                <div className="small-font fw-500 text-hash">{item.name}</div>
              </div>
              {index === 2 && (
                <div className="flex-column flex-center">
                  {activeBtn === 0 ? (
                    <div
                      className="px-2 py-2 rounded-pill small-font pink-bg clr-white cursor-pointer"
                      onClick={handleAddAmountPopup}
                    >
                      Add Amount
                    </div>
                  ) : (
                    <>
                      <div
                        className="px-2 py-2 rounded-pill small-font pink-bg clr-white cursor-pointer"
                        onClick={handleWithdrawPopupOpen}
                      >
                        Withdraw
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {activeBtn === 1 && (
        <Table
          data={WITHDRAWL_DATA}
          columns={TICKETS_COLUMNS}
          itemsPerPage={10}
        />
      )}
      {activeBtn === 0 && (
        <Table
          data={DEPOSIT_DATA}
          columns={TICKETS_COLUMNS}
          itemsPerPage={10}
        />
      )}
      <AddAmountPopup
        addAmountPopup={addAmountPopup}
        setShowAddAmountPopup={setShowAddAmountPopup}
        userWalletWithdrawTable={userWalletWithdrawTable}
      />
      <Withdraw
        withdraw={withdraw}
        setWithdraw={setWithdraw}
        userWalletStatsData={userWalletStatsData}
      />
      <ViewWithdrawlDetailsPopup
        selectWithdrawId={selectWithdrawId}
        viewWithdrawDetails={viewWithdrawDetails}
        setViewWIthdrawDetails={setViewWIthdrawDetails}
        withdrawalDetails={withdrawalDetails}
        viewWithdrawMode={viewWithdrawMode}
        setViewWithdrawMode={setViewWithdrawMode}
      />
      <ViewDepositViewPopup
        selectDepositId={selectDepositId}
        viewDepositDetails={viewDepositDetails}
        setViewDepositDetails={setViewDepositDetails}
        viewDepositMode={viewDepositMode}
        setViewDepositMode={setViewDepositMode}
        depositDetails={depositDetails}
      />
      <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={handleConfirmDelete}
        description="Are You Want To Delete data from Here?"
      />
      <EditDepositDetails
        editDepositDetail={editDepositDetail}
        setEditDepositDetail={setEditDepositDetail}
        depositDetails={depositDetails}
        userWalletStatsData={userWalletStatsData}
        selectDepositId={selectDepositId}
      />
      <EditWithdrawDetailsPopup
        editWithdrawDetailPopup={editWithdrawDetailPopup}
        setEditWithdrawDetailPopup={setEditWithdrawDetailPopup}
        withdrawalDetails={withdrawalDetails}
        userWalletStatsData={userWalletStatsData}
        selectWithdrawId={selectWithdrawId}
      />
    </div>
  );
}

export default Wallet;
