const endpoints = {
  loginUser: { method: "post", url: "/login-user" },
  signUpUser: { method: "post", url: "/sign-up-user" },
  getTourCategories: { method: "get", url: "/category" },
  resetUserPassword: { method: "put", url: "/reset-password" },
  updateUserProfile: { method: "put", url: "/user-edit-profile" },
  getUserDetails: { method: "get", url: "/get-user-details" },
  getBannerById: {
    method: "get",
    url: (id) => `/banner/${id}`,
  },
  postForgotPassword: { method: "post", url: "/forgot-password" },
  postVerifyOTP: { method: "post", url: "/verify-otp" },
  updatePassword: { method: "put", url: "/update-password" },
  getPublishedTours: { method: "get", url: "/get-published-tours" },
  getPublishedTourById: {
    method: "get",
    url: (id) => `/get-published-tours/${id}`,
  },
  getBankDetailsByTourId: {
    method: "get",
    url: (tour_id) => `/banking-by-tourId/${tour_id}`,
  },
  cancelUserTrip: {
    method: "post",
    url: (tripId) => `/user/booking/cancel/${tripId}`,
  },
  bookingUser: { method: "post", url: "/user/bookinguser" },
  uploadImage: { method: "post", url: "/upload" },
  getBestDestination: { method: "get", url: "/get-best-destination" },

  getToursBydates: { method: "post", url: "/memories-completed-tour_date" },
  getAllMemories: { method: "get", url: "/user/memoriesuser" },
  createMemories: { method: "post", url: "/user/memoriesuser" },
  sendComments: { method: "post", url: (id) => `/agent/agent-comment/${id}` },
  editComment: { method: "put", url: (id) => `/agent-edit-comment/${id}` },
  deleteComment: {
    method: "delete",
    url: (id) => `/agent/agent-delete-comment/${id}`,
  },
  postLike: { method: "post", url: "/agent/like" },
  dislike: { method: "delete", url: (id) => `/agent/dislike/${id}` },
  getMemoryById: { method: "get", url: (id) => `/user/memoriesuser/${id}` },
  getMyMemories: { method: "get", url: "/user/get-my-memories" },
  memoryPrivacyRequest: { method: "put", url: (id) => `/user/privacy/${id}` },
  commnetDelReq: {
    method: "post",
    url: (id) => `/user/comment/delete/request/${id}`,
  },
  replyComment: { method: "post", url: (id) => `/agent/agent-comment/${id}` },
  deleteMemoryById: {
    method: "delete",
    url: (id) => `/user/memoriesuser/${id}`,
  },
  getLoginVideos: { method: "get", url: "/get-videos" },
  getPublicPhotos: { method: "get", url: "/get-public-photos" },
  getPromotionalBanner: { method: "get", url: "/get-promotions" },
  getTicketByUserId: {
    method: "get",
    url: (id) => `/get-ticket-by-userId/${id}`,
  },
  updateImageById: {
    method: "put",
    url: (id) => `/update-upload/${id}`,
  },
  getUpcomingTrips: { method: "get", url: "/user/trips?upcoming=true" },
  getCompletedTrips: { method: "get", url: "/user/trips?expired=true" },
  getUserDeposit: { method: "get", url: "/user/deposit" },
  createUserDeposit: { method: "post", url: "/user/deposit" },
  createUserWithdraw: { method: "post", url: "/agent/withdraw" },

  getUserWithdrawDetailsByID: {
    method: "get",
    url: (id) => `/agent/withdraw/${id} `,
  },
  getUserDepositDetailsByID: {
    method: "get",
    url: (id) => `/user/deposit/${id}`,
  },
  updateUserWithdrawDetailsByID: {
    method: "put",
    url: (id) => `/agent/withdraw/${id}`,
  },
  deleteUserWithdrawDetailsByID: {
    method: "delete",
    url: (id) => `/agent/withdraw/${id}`,
  },
  updateUserDepositDetailsByID: {
    method: "put",
    url: (id) => `/user/deposit/${id}`,
  },
  deleteUserDepositDetailsByID: {
    method: "delete",
    url: (id) => `/user/deposit/${id}`,
  },
  getWalletStatus: { method: "get", url: "/user/tickets-stats" },
  getWalletTable: { method: "get", url: "/user/tickets-table" },

  getBeforeLoginSidbarIamges: { method: "get", url: "/managementUploads" },
  getBeforeLoginTours: { method: "get", url: "/get-videos-login-sidebar" },
  getAllDepositBankings: { method: "get", url: "/get-deposit-bankings" },
};

export default endpoints;
