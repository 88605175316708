import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { updatePassword } from "../../api/apiMethods";
import Login from "./Login";

function ForgotPasswordPopup({
  showForgotPassword,
  setShowForgotPassword,
  userId,
  handleLoginBtn,
}) {
  const [forgotPassword, setForgotPassword] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setForgotPassword({
      ...forgotPassword,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdatePassword = () => {
    if(forgotPassword?.new_password !== forgotPassword?.confirm_password){
      setError("new password and confirm password don't match");
      return;
    }
    updatePassword({
      userId,
      new_password: forgotPassword?.new_password,
      confirm_password: forgotPassword?.confirm_password,
    })
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setForgotPassword({});
          setTimeout(() => {
            handleLoginBtn();
          }, 3000);
        } else {
          setError("Something Went Wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };
  return (
    <div>
    <Modal show={showForgotPassword} centered>
      <Modal.Body>
        <div className="white-bg">
          <div className="w-100 d-flex justify-content-end">
            <RxCross2
              className="large-font grey-text cursor-pointer"
              onClick={() => setShowForgotPassword(false)}
            />
          </div>
          <h5 className="password-clr w-100 text-center fw-600">
            Forgot Password
          </h5>

          <div className="d-flex flex-column">
            <div className="black-text font-16 fw-600 my-1">
              New Password <span className="star">*</span>
            </div>
            <div className="input-bg br-10 my-1 d-flex align-items-center">
              <input
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                placeholder="Enter New Password"
                className="grey-text w-100 p-2 bg-none"
                value={forgotPassword?.new_password}
                onChange={handleChange}
              />
              {showNewPassword ? (
                <AiFillEye
                  className="font-30 clr-grey cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="font-30 clr-grey cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="black-text font-16 fw-600 my-1">
              Confirm Password <span className="star">*</span>
            </div>
            <div className="input-bg br-10 my-1 d-flex align-items-center">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm New Password"
                className="grey-text w-100 p-2 bg-none"
                value={forgotPassword?.confirm_password}
                onChange={handleChange}
              />
              {showConfirmPassword ? (
                <AiFillEye
                  className="font-30 clr-grey cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiFillEyeInvisible 
                  className="font-30 clr-grey cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
          </div>
          <div
            className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 pointer"
            onClick={handleUpdatePassword}
          >
            Update Password
          </div>
          {error && (
            <div className="red-text small-font text-center mt-2">{error}</div>
          )}
          {/* <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">Old Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2">
              <input
                type={showOldPassword ? "text" : "password"}
                name="old_password"
                placeholder="Enter Old Password"
                className="grey-text w-100 p-2 bg-none"
                value={resetPasswordData.old_password}
                onChange={handleChange}
              />
              {showOldPassword ? (
                <AiFillEyeInvisible
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                />
              ) : (
                <AiFillEye
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">New Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2">
              <input
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                placeholder="Enter New Password"
                className="grey-text w-100 p-2 bg-none"
                value={resetPasswordData?.new_password}
                onChange={handleChange}
              />
              {showNewPassword ? (
                <AiFillEyeInvisible
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              ) : (
                <AiFillEye
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">Confirm Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm New Password"
                className="grey-text w-100 p-2 bg-none"
                value={resetPasswordData?.confirm_password}
                onChange={handleChange}
              />
              {showConfirmPassword ? (
                <AiFillEyeInvisible
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiFillEye
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
          </div>

          <div
            className="medium-font pink-bg p-2 clr-white fw-600 text-center mt-3 rounded-pill"
            onClick={handleResetPassword}
          >
            Reset Password
          </div>

          {error && (
            <div className="red-text small-font text-center mt-2">{error}</div>
          )} */}
        </div>
      </Modal.Body>
    </Modal>
    
    </div>
  );
}

export default ForgotPasswordPopup;
