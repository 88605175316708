import React, { useEffect, useState } from "react";
import { CgAppleWatch, CgCalendarDates } from "react-icons/cg";
import { Images } from "../Images";
import { getPublicPhotos, getLoginVideos } from "../api/apiMethods";
import moment from "moment";

function BeforeLoginMemories() {
  const [allVideos, setAllVideos] = useState(null);
  const [getMemories, setGetMemories] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState();

  const fetchAllMemories = () => {
    getPublicPhotos()
      .then(
        (response) => response.status && setGetMemories(response?.data?.record)
      )
      .catch((error) => console.log(error?.message));
  };
  console.log(allVideos, "allVideos");
  const fetchAllVideos = () => {
    getLoginVideos()
      .then((response) => {
        if (response.status) {
          setAllVideos(response.data);
          if (response.data.length > 0) {
            setSelectedVideo(response.data[0]?.video_details.path);
          }
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMemories();
    fetchAllVideos();
  }, []);

  const handleSelectedVideo = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div className="w-100">
      <h5 className="font-weight-bold my-2">Tour Videos</h5>
      <div className="row">
        <div className="col-8">
          <video
            className="video-container"
            src={selectedVideo}
            alt={`selectedVideo`}
            controls
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col p-1 pointer h-30vh overflow-scroll-vertical">
          {allVideos?.map((item, index) => (
            <div
              key={index}
              className={`bg-white clr-black d-flex radius-10 p-2 mb-3  ${
                selectedVideo === item?.video_details?.path
                  ? "pink-border2"
                  : ""
              }`}
              onClick={() => handleSelectedVideo(item?.video_details?.path)}
            >
              <img
                className="trip-images1 rounded"
                src={item?.poster_details?.path}
                alt={item?.video_name}
              />
              <div className="flex-column justify-content-center ml-3">
                <div className="medium-font sub-text font-weight-bold">
                  {item?.video_name || "Video Name"}
                </div>
                <div className="d-flex align-items-center mt-2">
                  <CgCalendarDates className="medium-font me-1" />
                  <span className="small-font fw-500">
                    {moment(item?.created_at).format("DD-MM-YYYY")}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h5 className="font-weight-bold my-2">Tour Photos</h5>
      <div className="w-100 d-flex flex-wrap mt-1">
        {getMemories?.map((img, index) => (
          <div className="col-4 pe-3 pb-3 ps-0" key={index}>
            <img
              className="w-100 cursor-pointer br-10 h-25vh"
              src={img?.path || Images?.card_img_02}
              alt="Memory_Name"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BeforeLoginMemories;
