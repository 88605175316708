import { useState, useEffect, useCallback, useRef } from "react";
import { HiOutlineBell } from "react-icons/hi";
import { CgCalendarDates } from "react-icons/cg";
import { IoSend } from "react-icons/io5";
import { FiChevronRight } from "react-icons/fi";
import { Images } from "../../Images/index";
import { useDispatch } from "react-redux";
import EditProfilePopup from "../popups/EditProfilePopup";
import chatting from "../../api/chatApis/chatting";
import moment from "moment";
import { FaCheckDouble } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { setUserDetailsData } from "../../redux/action";
import { getUpcomingTrips, getUserDetails } from "../../api/apiMethods";
import { PiSquaresFourFill } from "react-icons/pi";
import { uploadFilesToS3 } from "../../Images/images";
import { useLocation } from "react-router-dom";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../../utils/socketIo";

function ChatContainer({ handleChatMinimize }) {
  const dispatch = useDispatch();
  const jwt_token = localStorage?.getItem("jwt_token");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [profileActive, setProfileActive] = useState("Profile");
  const [userDetails, setUserDetails] = useState({});
  const [officeTeam, setOfficeTeam] = useState([]);
  const [message, setMessage] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [userMessages, setUserMessages] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const menuRef = useRef(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const chatEndRef = useRef(null);
  const UserId = localStorage.getItem("user_id");
  const [socketConnected, setSocketConnected] = useState(false);
  const location = useLocation();
  console.log(userDetails, "userDetails");
  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (selectedTitle.id) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", selectedTitle.id);

        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setMessage((prevMessages) => [...prevMessages, message]);
        };

        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user:1${selectedTitle.id}`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: ${selectedTitle.id}`);
      }
    };
  }, [selectedTitle, socketConnected]);

  useEffect(() => {
    disconnectSocket();
    setSocketConnected(false);

    if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
    }
  }, [location]);

  const startEditing = (msg) => {
    setEditingMessageId(msg.id);
    setEditedContent(msg.content);
    setOpenMenuIndex();
  };

  const cancelEditing = () => {
    setEditingMessageId(null);
    setEditedContent("");
  };

  const saveEdit = (recipientId, messageId) => {
    handleEditMessage(recipientId, messageId, editedContent);

    console.log(recipientId, messageId, "===>error");

    setEditingMessageId(null);
    setEditedContent("");
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);
  const toggleMenu = (id) => {
    setOpenMenuIndex(openMenuIndex === id ? null : id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleGetUserDetails = useCallback(() => {
    getUserDetails({ jwt_token })
      .then((response) => {
        if (response?.status === true) {
          setUserDetails(response?.data);
          dispatch(setUserDetailsData(response?.data));
        } else {
          console?.log("Something went wrong");
        }
      })
      .catch((error) => console?.log(error?.message));
  }, [jwt_token]);

  useEffect(() => {
    handleGetUserDetails();
  }, [handleGetUserDetails]);

  const handleProfileActive = (item) => {
    setProfileActive(item);
  };
  useEffect(() => {
    setCurrentMonth(new Date());
  }, []);

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = () =>
    new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
  const [selectedTrip, setSelectedTrip] = useState(0);
  console.log(selectedTrip, "selectedTrip");

  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };
  console.log(getDatesInRange, "getDatesInRange");
  const renderCalendar = () => {
    const startOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    );

    const datesToShow = [];
    const firstDayOfWeek = startOfMonth.getDay();

    for (let i = 0; i < firstDayOfWeek; i++) {
      datesToShow.push(null);
    }

    for (let day = 1; day <= endOfMonth.getDate(); day++) {
      datesToShow.push(
        new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
      );
    }

    const highlightedDates = selectedTrip
      ? getDatesInRange(selectedTrip.from, selectedTrip.to).map((date) =>
          moment(date).format("YYYY-MM-DD")
        )
      : [];
    console.log(highlightedDates, "highlightedDates");
    return datesToShow.map((date, index) => {
      const isHighlighted =
        date && highlightedDates.includes(moment(date).format("YYYY-MM-DD"));

      return (
        <div
          key={index}
          className={`calendar-day x-small-font ${
            isHighlighted ? "bg-pink clr-white rounded-circle small-font" : ""
          }`}
        >
          {date ? date.getDate() : ""}
        </div>
      );
    });
  };

  const NOTIFICATIONS = [
    {
      title: "Goa Trip",
      type: "Image/Video",
      startDate: "16/02/2024",
      endDate: "17/02/2024",
      status: "Approved",
      uploadTime: "10:30 pm",
      uploadDate: "28/05/2024",
    },
    {
      title: "Goa Trip",
      type: "Image/Video",
      startDate: "01/05/2024",
      endDate: "10/05/2024",
      status: "Uploaded",
      uploadTime: "12:00 pm",
      uploadDate: "15/05/2024",
    },
    {
      title: "Goa Trip",
      type: "Image/Video",
      startDate: "20/06/2024",
      endDate: "25/06/2024",
      status: "Rejected",
      uploadTime: "9:00 am",
      uploadDate: "01/07/2024",
    },
    {
      title: "Goa Trip",
      type: "Image/Video",
      startDate: "20/06/2024",
      endDate: "25/06/2024",
      status: "srinivas Like your video",
      uploadTime: "9:00 am",
      uploadDate: "01/07/2024",
    },
  ];

  const [showEditProfile, setShowEditProfile] = useState(false);
  const handleEditProfileButton = () => {
    setShowEditProfile(true);
  };

  const defaultUser = () => {
    setSelectedTitle({
      userName: "media",
      id: 35,
    });
  };

  const handleSelectTeamPerson = (userName, id) => {
    setSelectedTitle({
      userName: userName,
      id: id,
    });
  };

  const handleChatWithTeam = async () => {
    try {
      const response = await chatting.chatWithTeam();

      setOfficeTeam(response.data);
    } catch (error) {
      console.error("Error while chatting with team:", error);
    }
  };

  useEffect(() => {
    handleChatWithTeam();
    defaultUser();
  }, []);

  const handleChange = (e) => setUserMessages(e.target.value);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
      setUserMessages("");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const handleSendMessage = async () => {
    try {
      const messageData = {
        senderId: Number(selectedTitle.id),
        recipientId: Number(UserId),
        content: userMessages,
        messageType: uploadPosterURL?.length > 0 ? "image" : "text",
        mediaUrl: uploadPosterURL[0],
      };
      emitEvent("chat:sendMessage", messageData);
      setMessage((prevMessages) => [
        ...prevMessages,
        {
          content:
            messageData.messageType === "image"
              ? messageData.mediaUrl
              : messageData.content,
          senderId: Number(selectedTitle.id),
          date: moment(new Date()).format("DD-MM-YYYY hh:mm A"),
        },
      ]);

      setUserMessages("");
      setUploadedFile(null);
      setUploadedFileName("");
      setUploadProgress("");
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const handleChatMessages = async () => {
    if (!selectedTitle?.id) return;
    try {
      const response = await chatting?.getChatMessages(selectedTitle?.id);
      const filteredMessages = response.data.filter(
        (message) => !message.deletedBy
      );
      setMessage(filteredMessages);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  const handleEditMessage = async (recipientId, messageId, editedContent) => {
    console.log(recipientId, messageId, editedContent, "===>edit data here");

    try {
      const response = await chatting.editMessage(
        recipientId,
        messageId,
        editedContent
      );
      handleChatMessages();

      console.log("Message edited successfully", response);
    } catch (error) {
      console.error("Failed to edit message", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await chatting.deleteMessage(messageId);
      handleChatMessages();

      console.log("Message deleted successfully", response);
    } catch (error) {
      console.error("Failed to delete message", error);
    }
  };

  const handleDeleteMessageForBothusers = async (recipientId, messageId) => {
    try {
      const response = await chatting.deleteMessageForBothUsers(
        recipientId,
        messageId
      );
      handleChatMessages();

      console.log("Message deleted for both users successfully", response);
    } catch (error) {
      console.error("Failed to delete message for both users", error);
    }
  };

  useEffect(() => {
    if (selectedTitle?.id) {
      handleChatMessages();
    }
  }, [selectedTitle?.id]);

  const [upcomingTours, setUpcomingTours] = useState([]);
  console.log(upcomingTours, "upcomingTours");

  const handleUpcomingTrips = () => {
    getUpcomingTrips({})
      .then((response) => {
        if (response?.status === true) {
          console.log(response, "completed Tripa");
          setUpcomingTours(response.data.record);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => console?.log(error));
  };
  useEffect(() => {
    handleUpcomingTrips();
  }, []);

  return (
    <div className="flex-column flex-between">
      <div className="flex-between small-font fw-600 postion-relative">
        <div className="w-100 d-flex small-font py-1 mt-2">
          <div
            className={`col-5 flex-center br-20 cursor-pointer ${
              profileActive === "Profile"
                ? "bg-pink text-white cursor-pointer"
                : "black-text bg-white cursor-pointer"
            }`}
            onClick={() => handleProfileActive("Profile")}
          >
            Profile
          </div>
          <div
            className={`col flex-center br-20 mx-2 ${
              profileActive === "Chat"
                ? "bg-pink text-white "
                : "black-text bg-white pointer"
            } `}
            onClick={() => handleProfileActive("Chat")}
          >
            Chat
          </div>
          <div
            className={`col-2 flex-center p-2 br-20 ${
              profileActive === "notification"
                ? "bg-pink text-white"
                : "clr-pink bg-white pointer"
            }`}
            onClick={() => handleProfileActive("notification")}
          >
            <HiOutlineBell className="large-font" />
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-end chat-left">
        <div
          className="flex-center p-2 br-20 black-text bg-white pointer mt-2"
          onClick={handleChatMinimize}
        >
          <FiChevronRight className="large-font" />
        </div>
      </div>

      {profileActive === "Profile" && (
        <div>
          <div className="d-flex sub-text small-font fw-600">
            <div className="pos-relative">
              <img
                className="pro-pic rounded-circle"
                src={userDetails?.profile_image || Images.profile_img}
                alt=""
              ></img>
            </div>
            <div className="flex-column justify-content-center ms-1 clr-white small-font ">
              <div>{userDetails?.name || "User Name"}</div>
              <div onClick={handleEditProfileButton} className="cursor-pointer">
                Edit Profile
              </div>
            </div>
          </div>
          <div className="sub-text clr-white mt-1 medium-font fw-600">
            Coming Tours
          </div>
          <div className="coming-tours-div">
            {upcomingTours.map((tour) => (
              <div
                key={tour.booking_id}
                className={`schedule-tour-card d-flex mt-1 small-font w-100 tours-card p-2 rounded-4 ${
                  selectedTrip &&
                  moment(selectedTrip.from).isSame(tour.schedule_from) &&
                  moment(selectedTrip.to).isSame(tour.schedule_upto)
                    ? "border-yellow cursor-pointer"
                    : ""
                }`}
                onClick={() =>
                  setSelectedTrip({
                    from: new Date(tour.schedule_from),
                    to: new Date(tour.schedule_upto),
                  })
                }
              >
                <img
                  src={Images.casino_img}
                  className="tour-image-container"
                  alt={tour.tour_title}
                />
                <div className="ms-2 d-flex flex-column justify-content-center fw-600">
                  <div className="clr-white small-font pb-1">
                    {tour.tour_title}
                  </div>
                  <div className="small-font my-1 clr-white d-flex align-items-center">
                    <CgCalendarDates className="me-2" />
                    {moment(tour.schedule_from).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="schedule-tour-card clr-white small-font my-1 br-10 p-2">
            Hi, If you are like any tour Please click on{" "}
            <span className="clr-pink cursor-pointer">I am interested</span>{" "}
            button then, I accept you, you see terms and book your trip
          </div>
          <div className="calendar mt-2 p-2 small-font">
            <div className="calendar-header">
              <div
                onClick={() =>
                  setCurrentMonth(
                    new Date(
                      currentMonth.getFullYear(),
                      currentMonth.getMonth() - 1
                    )
                  )
                }
                className="small-font cursor-pointer"
              >
                Prev
              </div>
              <div className="medium-font mx-1 white-space cursor-pointer">
                {currentMonth.toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </div>
              <div
                onClick={() =>
                  setCurrentMonth(
                    new Date(
                      currentMonth.getFullYear(),
                      currentMonth.getMonth() + 1
                    )
                  )
                }
                className="small-font cursor-pointer"
              >
                Next
              </div>
            </div>
            <div className="calendar-grid">
              <div className="day-label x-small-font">Sun</div>
              <div className="day-label x-small-font">Mon</div>
              <div className="day-label x-small-font">Tue</div>
              <div className="day-label x-small-font">Wed</div>
              <div className="day-label x-small-font">Thu</div>
              <div className="day-label x-small-font">Fri</div>
              <div className="day-label x-small-font">Sat</div>
              {renderCalendar()}
            </div>
          </div>
        </div>
      )}
      {profileActive === "Chat" && (
        <>
          <div className="d-flex border-bottom-grey justify-content-around w-80">
            {officeTeam.map((online, index) => (
              <div className="p-2 col-3 pos-relative" key={index}>
                <div className="d-flex flex-column justify-content-center align-items-center w-100 cursor-pointer">
                  <img
                    src={online.image || Images.onlinepic1}
                    className={`online-chat-profile rounded-pill ${
                      selectedTitle.userName === online.userName
                        ? "border-green"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelectTeamPerson(online.userName, online.id)
                    }
                    alt="Profile"
                  />
                  <div
                    className={`font-10 fw-bold  ${
                      selectedTitle.userName === online.userName
                        ? "clr-white"
                        : "grey-text"
                    }`}
                  >
                    {online.userName}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex-column flex-between mt-2">
            <h6 className="clr-white font-14 fw-600 mb-0">
              Chat With
              <span className="clr-pink px-1">{selectedTitle.userName}</span>
            </h6>
            <div className="profile-chat clr-white pt-2 pb-4">
              {message.length &&
                message.map((msg, index) => (
                  <div key={index}>
                    <div
                      className={
                        Number(msg?.senderId) !== Number(UserId)
                          ? "w-100 d-flex justify-content-end"
                          : "w-100 d-flex justify-content-start"
                      }
                    >
                      <div className="d-flex flex-column fit-content position-relative message-container">
                        {/* Chat message container */}
                        <div
                          className={
                            Number(msg?.senderId) !== Number(UserId)
                              ? "small-font w-100 black-bg p-2 outgoing-msg"
                              : "small-font w-100 black-bg p-2 outgoing-msg"
                          }
                        >
                          <div>
                            {/* If editing this message, show an input field instead of the text */}
                            {editingMessageId === msg.id ? (
                              <input
                                type="text"
                                value={editedContent}
                                onChange={(e) =>
                                  setEditedContent(e.target.value)
                                }
                                className="edit-message-input"
                              />
                            ) : (
                              <div className="message-content small-font">
                                {msg?.messageType === "image" ? (
                                  <img
                                    src={msg?.mediaUrl || msg?.content}
                                    alt="Message Image"
                                    className="chatImagesize-dashboard pointer"
                                  />
                                ) : typeof msg?.content === "string" &&
                                  msg?.content.includes("http") ? (
                                  <img
                                    src={msg?.content}
                                    alt="Chat Image"
                                    className="chatImagesize-dashboard pointer"
                                  />
                                ) : (
                                  <div>{msg?.content}</div>
                                )}

                                <span className="font-10">
                                  {msg.edited === true ? "edited" : ""}
                                </span>
                              </div>
                            )}

                            {/* 3-dot icon for options */}
                            <span
                              className="three-dots-outer pointer"
                              onClick={() => toggleMenu(msg.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <FaChevronDown className="small-font down-icon-chat" />
                            </span>
                          </div>
                        </div>
                        <div className="d-flex w-100  flex-between">
                          <span className="timestamp mx-2 small-font">
                            {msg.timestamp
                              ? new Date(msg.timestamp).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })
                              : new Date().toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                          </span>

                          {Number(msg?.senderId) !== Number(UserId) && (
                            <span>
                              <FaCheckDouble className="font-10" />
                            </span>
                          )}
                        </div>

                        {/* Dropdown menu (only visible when openMenuIndex is the current msg.id) */}
                        {openMenuIndex === msg.id && (
                          <div
                            className="position-absolute"
                            ref={menuRef}
                            style={{
                              top: "20px",
                              fontSize: ".6rem",
                              right: "10px",
                              background: "#fff",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                              zIndex: 1000,
                            }}
                          >
                            <ul className="popup-menu-homepage fw-700 small-font">
                              {/* Conditionally render Edit if the sender is the current user */}
                              {Number(msg?.senderId) !== Number(UserId) &&
                                msg?.messageType !== "image" && (
                                  <li
                                    className="menu-item"
                                    onClick={() => startEditing(msg)}
                                  >
                                    Edit
                                  </li>
                                )}
                              <li
                                className="menu-item"
                                onClick={() => handleDeleteMessage(msg.id)}
                              >
                                Delete
                              </li>

                              <li
                                className="menu-item"
                                onClick={() =>
                                  handleDeleteMessageForBothusers(
                                    msg.recipientId,
                                    msg.id
                                  )
                                }
                              >
                                Delete All
                              </li>
                            </ul>
                          </div>
                        )}

                        {editingMessageId === msg.id && (
                          <div className="edit-options d-flex justify-content-end mt-2">
                            <div
                              className="bg-yellow font-10 pointer"
                              onClick={() => saveEdit(msg.recipientId, msg.id)}
                            >
                              Save
                            </div>
                            <div
                              className="bg-yellow font-10 mx-2 pointer"
                              onClick={cancelEditing}
                            >
                              Cancel
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div ref={chatEndRef}></div>
                  </div>
                ))}
            </div>
            <div className="flex-between align-items-center  w-100 white-bg rounded-pill p-2">
              <input
                type="text"
                placeholder={`${
                  selectedFile &&
                  uploadProgress &&
                  uploadProgress[selectedFile.name] === 100
                    ? "Image uploaded"
                    : "Type your message"
                }`}
                className="grey-text all-none w-75 py-2 small-font fw-600"
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                value={userMessages}
              />
              <label htmlFor="file-upload">
                <PiSquaresFourFill className="large-font clr-pink mx-1 pointer" />
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                id="file-upload"
                onChange={handleFileChange}
              />
              <IoSend className="send-icon" onClick={handleSendMessage} />
            </div>
          </div>
        </>
      )}
      {profileActive === "notification" && (
        <div className="flex-column flex-between mt-2">
          <h6 className="clr-white fw-600 mb-0">Notifications</h6>
          <div className="profile-chat clr-white mt-2">
            {NOTIFICATIONS?.map((item, index) => (
              <div key={index}>
                <div className="black-bg p-2 br-20 mt-1">
                  <div className="flex-between medium-font fw-600">
                    <span>{item?.title}</span>
                    <span>{item?.type}</span>
                  </div>
                  <div className="flex-between small-font fw-500 mt-1 clr-grey2">
                    <span>{item?.startDate}</span>
                    <span>{item?.endDate}</span>
                  </div>
                  <div
                    className={`d-flex ${
                      ["Approved", "Uploaded", "Rejected"].includes(item.status)
                        ? "justify-content-end"
                        : ""
                    } small-font fw-600 mt-1`}
                  >
                    <li
                      className={
                        item.status === "Approved"
                          ? "clr-green"
                          : item.status === "Uploaded"
                          ? "upload-clr"
                          : item.status === "Rejected"
                          ? "reject-clr"
                          : "clr-white"
                      }
                    >
                      {item?.status}
                    </li>
                  </div>
                </div>
                <div className="flex-between small-font fw-500 mt-1 clr-white">
                  <span>{item?.uploadTime}</span>
                  <span>{item?.uploadDate}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <EditProfilePopup
        showEditProfile={showEditProfile}
        setShowEditProfile={setShowEditProfile}
        userDetails={userDetails}
      />
    </div>
  );
}

export default ChatContainer;
