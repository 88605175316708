import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { resetUserPassword } from "../../api/apiMethods";

function ResetPassword({ showResetPasswordPopup, setShowPasswordPopup }) {
  const [resetPasswordData, setResetPasswordData] = useState({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => {
    setResetPasswordData({
      ...resetPasswordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResetPassword = () => {
    if(resetPasswordData?.old_password === resetPasswordData?.new_password){
      setError("New password can't be same as the old password");
      return;
    }
    if(resetPasswordData?.new_password !== resetPasswordData?.confirm_password){
      setError("new passwrod and confirm password don't match");
      return;
    }
    resetUserPassword({
      old_password: resetPasswordData?.old_password,
      new_password: resetPasswordData?.new_password,
      confirm_password: resetPasswordData?.confirm_password,
    })
      .then((response) => {
        if (response?.status === true) {
          setResetPasswordData({});
          setTimeout(() => {
            setShowPasswordPopup(false);
          }, 2000);
        } else {
          setError("Something Went Wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  return (
    <Modal show={showResetPasswordPopup} centered>
      <Modal.Body>
        <div className="white-bg">
          <div className="w-100 d-flex justify-content-end">
            <RxCross2
              className="large-font clr-black cursor-pointer"
              onClick={() => setShowPasswordPopup(false)}
            />
          </div>
          <h5 className="password-clr w-100 text-center fw-600">
            Reset Password
          </h5>

          <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">Old Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2">
              <input
                type={showOldPassword ? "text" : "password"}
                name="old_password"
                placeholder="Enter Old Password"
                className="grey-text w-100 p-2 bg-none small-font"
                value={resetPasswordData.old_password}
                onChange={handleChange}
              />
              {showOldPassword ? (
                <AiFillEye 
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">New Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2">
              <input
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                placeholder="Enter New Password"
                className="grey-text w-100 p-2 bg-none small-font"
                value={resetPasswordData?.new_password}
                onChange={handleChange}
              />
              {showNewPassword ? (
                <AiFillEye 
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              ) : (
                <AiFillEyeInvisible 
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">Confirm Password</label>
            <div className="input-bg br-10 mt-1 d-flex align-items-center pe-2">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm New Password"
                className="grey-text w-100 p-2 bg-none small-font"
                value={resetPasswordData?.confirm_password}
                onChange={handleChange}
              />
              {showConfirmPassword ? (
                <AiFillEye
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiFillEyeInvisible 
                  className="font-30 password-clr cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
          </div>

          <div
            className="medium-font pink-bg p-2 clr-white fw-600 text-center mt-3 rounded-pill cursor-pointer"
            onClick={handleResetPassword}
          >
            Reset Password
          </div>

          {error && (
            <div className="red-text small-font text-center mt-2">{error}</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ResetPassword;
