import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Images } from "../../Images";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";

const images = [
  {
    id: 1,
    src: Images.threeboys,
    alt: "Green sports car",
    caption: "Supercar & Car Images",
  },
  { id: 2, src: Images.girl3, alt: "Red Mercedes", caption: "Red Car Photos" },
  {
    id: 3,
    src: Images.balibg,
    alt: "Purple Lamborghini",
    caption: "Car HD photos",
  },
  {
    id: 4,
    src: Images.profile_img,
    alt: "Red sports car",
    caption: "Sports Car Images",
  },
  {
    id: 5,
    src: Images.girl3,
    alt: "Blue BMW",
    caption: "Sports Car Stock Photos",
  },
  {
    id: 6,
    src: Images.girl2,
    alt: "Red Hyundai",
    caption: "Hyundai Car Price, Images, Reviews",
  },
  {
    id: 7,
    src: Images.threeboys,
    alt: "Green sports car",
    caption: "Supercar & Car Images",
  },
  { id: 8, src: Images.girl3, alt: "Red Mercedes", caption: "Red Car Photos" },
  {
    id: 9,
    src: Images.balibg,
    alt: "Purple Lamborghini",
    caption: "Car HD photos",
  },
  {
    id: 10,
    src: Images.profile_img,
    alt: "Red sports car",
    caption: "Sports Car Images",
  },
  {
    id: 11,
    src: Images.girl3,
    alt: "Blue BMW",
    caption: "Sports Car Stock Photos",
  },
  {
    id: 12,
    src: Images.girl2,
    alt: "Red Hyundai",
    caption: "Hyundai Car Price, Images, Reviews",
  },
];

const PrivateUserImages = ({ openPrivatePhotos, setOpenPrivatePhotos }) => {
  const privatePhotos = useSelector((state) => state.privatePhotos);
 
  const [selectedImage, setSelectedImage] = useState(null);
  const [viewMode, setViewMode] = useState("grid");

  useEffect(() => {
    if (openPrivatePhotos) {
      setViewMode("grid");
      setSelectedImage(null);
    }
  }, [openPrivatePhotos]);

  const handleClose = () => {
    setOpenPrivatePhotos(false);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setViewMode("selected");
  };

  return (
    <Modal show={openPrivatePhotos} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <span className="fw-600 large-font">Your Photos</span>
      </Modal.Header>
      <Modal.Body>
        {viewMode === "grid" ? (
          <div className="container-fluid">
            <div className="row">
              {privatePhotos.map((image) => (
                <div key={image.id} className="col-6 col-md-4 mb-3">
                  <div
                    className="image-item"
                    onClick={() => handleImageClick(image)}
                  >
                    <img
                      src={image?.path}
                      alt={image.alt}
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="row">
            {/* Thumbnails on the left */}
            <div className="col-3">
              <div className="thumbnail-list d-flex flex-column">
                {privatePhotos.map((image) => (
                  <div
                    key={image.id}
                    className={`thumbnail-item mb-2 ${
                      selectedImage.id === image.id
                        ? "border border-primary"
                        : ""
                    }`}
                    onClick={() => setSelectedImage(image)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={image?.path}
                      alt={image.alt}
                      className="img-fluid rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* Selected image on the right */}
            <div className="col-9">
              <div className="position-relative">
                <button
                  className="close-button btn btn-outline-secondary position-absolute"
                  style={{ top: "10px", right: "10px" }}
                  onClick={() => setViewMode("grid")}
                >
                  <RxCross2 />
                </button>
                <div className="full-private-space">
                  <img
                    src={selectedImage.src}
                    alt={selectedImage.alt}
                    className="full-private-image "
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PrivateUserImages;
