import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdCalendarMonth } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useParams } from "react-router";
import { Carousel } from "react-bootstrap";
import { Images } from "../../Images";
import { getPublishedTours, getTourCategories } from "../../api/apiMethods";

function CasinoTour() {
  const { tourCategory } = useParams();
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [publishedToursData, setPublishedTourData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const tourCategoryHeading = tourCategory
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const getAllPublishedTours = () => {
    getPublishedTours({})
      .then((response) => {
        if (response?.status === true) {
          const filteredTours = response?.data?.data?.filter(
            (tour) => tour.slug.toLowerCase() === tourCategory.toLowerCase()
          );
          setPublishedTourData(filteredTours || []);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    getTourCategories({})
      .then((response) => {
        if (response?.status === true) {
          const data = response?.data?.category?.find(
            (item) => item?.slug === tourCategory
          );
          setSelectedCategory(data);
        } else {
          console.error("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  }, [tourCategory]);

  console.log(selectedCategory, tourCategory, "===>selectedCategory");

  useEffect(() => {
    getAllPublishedTours();
  }, [tourCategory]);

  const IndividualTours = publishedToursData.map((tour) => ({
    image: tour?.uploads?.path,
    title: tour.tour_title,
    date: new Date(tour.schedule_from).toLocaleDateString("en-GB"),
    location: tour.tour_location,
    path: `/tour/${tourCategory}/${tour.tour_id}`,
  }));

  const descriptions = publishedToursData.reduce((acc, tour) => {
    acc[tour.tour_title] = tour.quotations || "No description available";
    return acc;
  }, {});

  useEffect(() => {
    setAnimationClass("fade-in-from-top");
    const timer = setTimeout(() => setAnimationClass(""), 1000);
    return () => clearTimeout(timer);
  }, [activeSlide]);

  const handlePrevClick = () => {
    setActiveSlide((prevSlide) =>
      prevSlide > 0 ? prevSlide - 1 : IndividualTours.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveSlide((prevSlide) =>
      prevSlide < IndividualTours.length - 1 ? prevSlide + 1 : 0
    );
  };

  const currentDescription =
    descriptions[IndividualTours[activeSlide]?.title] ||
    "No description available";

  return (
    <div className="w-100 p-2 mt-2">
      <h5 className="password-clr my-2 fw-700">{tourCategoryHeading}</h5>
      <Carousel interval={3000} fade={true} className="w-100">
      {selectedCategory?.banners?.map((item,index) => (
          <Carousel.Item key={index}>
            <img src={item?.path ||   Images.Casino} alt="Casino" className="w-100" />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="white-bg my-2 p-2 br-10">
        <div className="d-flex align-items-end">
          <h5 className="password-clr fw-600">
            Upcoming {tourCategoryHeading}
          </h5>
          <div className="ms-3 small-font grey-text fw-500">
            {publishedToursData.length} destinations found
          </div>
        </div>
        {IndividualTours.length > 0 ? (
          <div className="d-flex w-100 align-items-center position-relative mt-2">
            <FaChevronLeft
              onClick={handlePrevClick}
              className="icon-theme cursor-pointer"
            />
            <div className="d-flex w-100 horizontal-scroll">
              {IndividualTours.slice(activeSlide, activeSlide + 2).map(
                (item, index) => (
                  <div
                    key={index}
                    className="col-6"
                    onClick={() => navigate(item.path)}
                  >
                    <div
                      className={`w-100 d-flex section-bg br-22 ${
                        index === 0 ? "green-border" : ""
                      }`}
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-30 image-height br-22"
                      />
                      <div className="w-70 ps-3 flex-column justify-content-center">
                        <h6 className="fw-bold">{item.title}</h6>
                        <div className="d-flex small-font text-hash fw-600 my-2">
                          <div className="d-flex me-2">
                            <MdCalendarMonth className="medium-font me-1" />
                            <div>{item.date}</div>
                          </div>
                          <div className="d-flex">
                            <GrLocation className="medium-font me-1" />
                            <div>{item.location}</div>
                          </div>
                        </div>
                        <div className="pink-clr clr-white px-3 py-1 rounded-pill small-font fw-600 w-fit pointer">
                          Book Now
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <FaChevronRight
              onClick={handleNextClick}
              className="icon-theme cursor-pointer"
            />
          </div>
        ) : (
          <div className="text-center mt-4">
            <h6>No Tours Available</h6>
          </div>
        )}
        {IndividualTours.length > 0 && (
          <div className={`d-flex flex-column my-2 p-1 ${animationClass}`}>
            <h6 className="fw-700 password-clr mb-1">
              <u>{IndividualTours[activeSlide]?.title} Tour Details</u>
            </h6>
            <div className="small-font fw-500 grey-text mt-1">
              {currentDescription}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CasinoTour;
