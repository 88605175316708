import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./styles.css";
import SideBar from "./SideBar";
import Content from "./Content";
import ChatContainer from "./chat/ChatContainer";
import SearchBar from "./SearchBar";
import MyTours from "./mytours/MyTours";
import CasinoTour from "./booknowpopup/CasinoTour";
import PrivacyPalicy from "./PrivacyPalicy";
import ChatContainerTwo from "./chat/ChatContainerTwo";
import TourMessage from "./booknowpopup/TourMessage";
import BforeLoginHomePage from "../bforelogin/BforeLoginHomePage";
import Wallet from "./wallet/Wallet";
import Memories from "./memories/Memories";
import PhotosVideos from "./memories/PhotosVideos";
import Chat from "./chat/Chat";
import Tickets from "./Tickets/Tickets";

function ProtectedRoute({ children }) {
  const isAuthorized = localStorage.getItem("isAuthorized") === "true";

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function HomePage() {
  const location = useLocation();
  const [chatMinimize, setChatMinimize] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  const isChatPage = location.pathname === "/chat";
  const isDasboardPage = location.pathname === "/";
  const isLoginPage = location.pathname === "/login";

  const handleChatMinimize = () => {
    setChatMinimize(!chatMinimize);
  };

  const routingList = [
    { path: "/", component: <Content chatMinimize={chatMinimize} /> },
    { path: "/tour/:tourCategory", component: <CasinoTour /> },
    { path: "/my-tours", component: <MyTours /> },
    { path: "/privacy-policy", component: <PrivacyPalicy /> },
    { path: "/casino", component: <CasinoTour /> },
    { path: "/memories", component: <Memories /> },
    { path: "/photosvideos/:id", component: <PhotosVideos /> },
    { path: "/chat", component: <Chat /> },
    { path: "/tickets", component: <Tickets /> },
    { path: "/wallet", component: <Wallet /> },
    { path: "/tour/:tourCategory/:tourId", component: <TourMessage /> },
  ];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://m.top2tours.com";
    }
  }, [isMobile]);
  return (
    <>
      {isMobile ? (
        <p>Redirecting to mobile view...</p>
      ) : (
        <div className="w-100 d-flex homepage p-2 h-100vh">
          {!isLoginPage && (
            <div className="col-2">
              <SideBar showLogin={showLogin} setShowLogin={setShowLogin} />
            </div>
          )}
          <div
            className={
              isLoginPage
                ? "col-12 content rounded"
                : isChatPage
                ? "col-10 content rounded"
                : chatMinimize
                ? "col-8 content rounded"
                : "col content rounded"
            }
          >
            <div className="scroll-container"> 
              <Routes>
                <Route path="/login" element={<BforeLoginHomePage />} />

                {routingList.map((obj, i) => (
                  <Route
                    key={i}
                    path={obj.path}
                    element={<ProtectedRoute>{obj.component}</ProtectedRoute>}
                  />
                ))}

                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </div>
          </div>
          {!isLoginPage &&
            !isChatPage &&
            (chatMinimize ? (
              <div className="col-2 chat-container">
                <ChatContainer handleChatMinimize={handleChatMinimize} />
              </div>
            ) : (
              <div className="chat-container">
                <ChatContainerTwo handleChatMinimize={handleChatMinimize} />
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default HomePage;
