import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles.css";
import { Row, Col } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { postForgotPassword, postVerifyOTP } from "../../api/apiMethods";
import { FaRedoAlt } from "react-icons/fa";
import ForgotPasswordPopup from "./ForgotPasswordPopup";

function OtpPopup({ OtpModal, setOtpModal, handleLoginBtn }) {
  const handleOtpClose = () => {
    setOtpModal(false);
  };
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [country_code, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState(null);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [validatedPhone, setValidatedPhone] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendActive, setIsResendActive] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [err, setErr] = useState("");

  useEffect(() => {
    if (isOtpSent && resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    if (resendTimer === 0) {
      setIsResendActive(true);
    }
  }, [isOtpSent, resendTimer]);

  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 1) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
      if (value && index < otpValues.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handleForgetPassword = () => {
    postForgotPassword({ country_code, phone })
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setUserId(response.data.userId);
          setValidatedPhone(`${country_code}-${phone}`);
          setIsOtpSent(true);
          setResendTimer(60);
          setIsResendActive(false);
        } else {
          if (response.message === "Phone Not Found.") {
            console.log("Please enter a valid phone number.");
            setValidatedPhone("");
            setIsOtpSent(false);
          } else {
            console.log("Something went wrong.");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setValidatedPhone("");
        setIsOtpSent(false);
        setErr(err?.message);
      })
      .finally(() => setIsResending(false));
  };

  const handlePostVerifyOtp = () => {
    const otp = otpValues.join("");
    if (userId) {
      postVerifyOTP({ userId, otp })
        .then((response) => {
          if (response.status === true) {
            console.log(response.data);
            setTimeout(() => {
              handleForgotPassword();
              setOtpModal(false);
            }, 2000);
          } else {
            console.log("Something went wrong.");
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log("User ID is not available.");
    }
  };

  const handleResendCode = () => {
    if (isResendActive) {
      setIsResending(true);
      handleForgetPassword();
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const validPhone = value.replace(/\D/g, "").slice(0, 10);
    setPhone(validPhone);
  };
  return (
    <>
      <Modal
        show={OtpModal}
        onHide={handleOtpClose}
        className="otp-popup"
        centered
      >
        <div className="p-1">
          <div className="w-100 d-flex justify-content-end pointer">
            <RxCross2 className="font-20" onClick={() => handleOtpClose()} />
          </div>
          <div className="px-4">
            <div className="password-clr w-100 text-center fw-600 large-font">
              OTP Verification
            </div>
            <div className="d-flex flex-column">
              <label className="fw-600 medium-font my-1">Phone Number</label>
              <div className="d-flex  w-100 br-10 flex-between">
                <input
                  type="text"
                  placeholder="+00"
                  value={country_code}
                  className="py-1 input-bg br-10 w-20 px-1 border-none mx-1 small-font"
                  onChange={(e) => setCountryCode(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  className="py-1 input-bg br-10 w-80 px-2 border-none mx-1 small-font"
                  onChange={handlePhone}
                />
                <div
                  className="w-25 py-2 br-20 pink-bg clr-white text-center fw-600 pointer ms-1 small-font"
                  onClick={handleForgetPassword}
                >
                  Send
                </div>
              </div>
              <span className="clr-red flex-center my-2 small-font">{err}</span>
            </div>
            {isOtpSent ? (
              <>
                <div className="small-font fw-500 w-100 mt-1 text-center grey-text">
                  We have sent a 6 digit code on your registered <br />
                  <div className="fw-600 clr-green">{validatedPhone}</div>
                </div>
                <Row className="my-2">
                  {otpValues.map((value, index) => (
                    <Col size="2" key={index}>
                      <div>
                        <input
                          id={`otp-input-${index}`}
                          className="input-bg br-10 my-1 font-18 py-1 w-100 text-center"
                          value={value}
                          onChange={(e) => handleInputChange(e, index)}
                          maxLength="1"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
                <div className="text-center d-flex mb-1 pointer">
                  <span className="grey-text">Didn’t receive any code?</span>
                  <span
                    className={`fw-700 d-flex align-items-center ${
                      isResendActive ? "active clr-green" : "inactive grey-text"
                    }`}
                    onClick={handleResendCode}
                    style={{
                      cursor: isResendActive ? "pointer" : "not-allowed",
                    }}
                  >
                    {isResendActive ? (
                      <>
                        Resend Code
                        <FaRedoAlt
                          className={`ms-2 ${
                            isResending ? "resending-icon" : ""
                          }`}
                          style={{
                            animation: isResending
                              ? "spin 1s linear infinite"
                              : "none",
                          }}
                        />
                      </>
                    ) : (
                      `Resend in ${Math.floor(resendTimer / 60)}:${(
                        resendTimer % 60
                      )
                        .toString()
                        .padStart(2, "0")}`
                    )}
                  </span>
                </div>
                <div
                  className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 pointer"
                  onClick={() => handlePostVerifyOtp()}
                >
                  Verify
                </div>
              </>
            ) : (
              <div className="font-14 fw-500 w-100 mt-1 text-center grey-text">
                Please enter a valid phone number.
              </div>
            )}
          </div>
        </div>
      </Modal>
      <ForgotPasswordPopup
        userId={userId}
        handleLoginBtn={handleLoginBtn}
        showForgotPassword={showForgotPassword}
        setShowForgotPassword={setShowForgotPassword}
      />
    </>
  );
}

export default OtpPopup;
