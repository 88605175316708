import { HiOutlineBell } from "react-icons/hi";
import { FiChevronLeft } from "react-icons/fi";

function ChatContainerTwo({ handleChatMinimize }) {
  return (
    <div className="p-1">
      <div className="flex-center p-2 br-20 clr-pink bg-white pointer">
        <HiOutlineBell className="large-font" />
      </div>
      <div
        className="flex-center p-2 br-20 black-text bg-white pointer mt-2"
        onClick={handleChatMinimize}
      >
        <FiChevronLeft className="large-font" />
      </div>
    </div>
  );
}

export default ChatContainerTwo;
