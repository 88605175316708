import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { loginUser } from "../../api/apiMethods";
import { useNavigate } from "react-router";
import "./styles.css";

function Login({ showLogin, setShowLogin, handleSignUpBtn, handleOtpModal }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ user_name: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    loginUser(formData)
      .then((response) => {
        if (response?.status) {
          localStorage.setItem("jwt_token", response.data.token);
          localStorage.setItem("user_name", response.data.user?.user_name);
          localStorage.setItem("isAuthorized", "true");
          localStorage.setItem("user_id", response?.data.user?.id);
          localStorage.setItem("role_id", response?.data.user.role_id._id);
          navigate("/");
          setError("");
        } else {
          setError("Something Went Wrong");
        }
      })
      .catch((error) => {
        setError(error?.message);
      });
    setTimeout(() => setError(""), 2000);
  };

  useEffect(() => {
    const authorized = localStorage.getItem("isAuthorized") === "true";
    setShowLogin(!authorized);
  }, []);

  return (
    <Modal
      show={showLogin}
      onHide={() => {
        setShowLogin(false);
        setError("");
      }}
      centered
    >
      <Modal.Body>
        <div className="white-bg">
          <div className="w-100 d-flex justify-content-end">
            <MdOutlineClose
              className="font-24 black-text cursor-pointer"
              onClick={() => setShowLogin(false)}
            />
          </div>
          <h5 className="password-clr w-100 text-center fw-600 large-font">
            Login
          </h5>
          <div className="d-flex flex-column medium-font fw-500 medium-font">
            <label className="password-clr">
              User Name <span className="clr-red">*</span>
            </label>
            <div className="input-bg br-5 mt-1 small-font">
              <input
                type="text"
                name="user_name"
                placeholder="Enter User Name"
                className="grey-text w-100 p-2 bg-none small-font"
                onChange={handleChange}
                value={formData.user_name}
              />
            </div>
          </div>
          <div className="d-flex flex-column medium-font fw-500 mt-3">
            <label className="password-clr">
              Password <span className="clr-red">*</span>
            </label>
            <div className="input-bg br-5 mt-1 d-flex align-items-center pe-2 small-font">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                className="grey-text w-100 p-2 bg-none"
                value={formData.password}
                onChange={handleChange}
              />
              {showPassword ? (
                <AiFillEye
                  className="font-24 password-clr cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <AiFillEyeInvisible
                  className="font-24 password-clr cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>
          <div
            className="password-clr fw-500 text-center mt-2 cursor-pointer medium-font"
            onClick={handleOtpModal}
          >
            Forgot Your Password?
          </div>
          <div
            className="medium-font pink-bg p-2 clr-white fw-600 text-center mt-2 rounded-pill cursor-pointer"
            onClick={handleLogin}
          >
            Login
          </div>
          {error && (
            <div className="red-text small-font text-center mt-2">{error}</div>
          )}
          <div className="text-center mt-2 medium-font">
            <span className="grey-text fw-400 medium-font">
              Don't have an account?
            </span>
            <span
              className="password-clr fw-600 ps-1 cursor-pointer medium-font"
              onClick={handleSignUpBtn}
            >
              Sign Up
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Login;
