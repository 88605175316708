import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Documents from "../popups/Documents";
import CancelPopup from "../popups/CancelPopup";
import {
  getUpcomingTrips,
  getCompletedTrips,
  getAllTrips,
} from "../../api/apiMethods";

function MyTours() {
  const token = localStorage.getItem("jwt_token");
  const [activeHead, setActiveHead] = useState("Upcoming Trips");
  const [showDocumentsPopup, setShowDocumentsPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [bookingIdToCancel, setBookingIdToCancel] = useState(null);
  const handleDocuments = () => {
    // const images = {
    //   travel: tour?.path?.[0] || "",
    //   hotel: tour?.path?.[1] || "",
    //   tour: tour?.path?.[2] || "",
    // };
    // setSelectedImage(images);
    setShowDocumentsPopup(true);
  };
  console.log(selectedImage, "657890");

  const handleCancelPopup = (bookingId) => {
    setBookingIdToCancel(bookingId);
    setCancelPopup(true);
  };
  console.log("bookingIdToCancel", bookingIdToCancel);

  const [upcomingTours, setUpcomingTours] = useState([]);
  const [completedTours, setCompletedTours] = useState([]);
  console.log(upcomingTours, "upcomingTours");
  console.log(completedTours, "completedTours");

  const getTours = () => {
    getAllTrips({ token })
      .then((res) => {
        if (res?.status === true) {
          console.log(res);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleUpcomingTrips = () => {
    getUpcomingTrips({})
      .then((response) => {
        if (response?.status === true) {
          console.log(response, "completed Tripa");
          setUpcomingTours(response.data.record);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => console?.log(error));
  };
  const handleCompletedTrips = () => {
    getCompletedTrips({})
      .then((response) => {
        if (response?.status === true) {
          console.log(response, "Upcoming Trips");
          setCompletedTours(response.data.record);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => console?.log(error));
  };

  console.log(completedTours, "====completedTours");

  useEffect(() => {
    getTours();
    handleUpcomingTrips();
    handleCompletedTrips();
  }, []);

  const refreshTours = () => {
    handleUpcomingTrips();
    handleCompletedTrips();
  };
  const formatDateToYyyyMmDd = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const data =
    activeHead === "Upcoming Trips"
      ? upcomingTours?.length &&
        upcomingTours?.map((tour, index) => ({
          s_no: index + 1,
          tour_title: tour.tour_title,
          start_date: formatDateToYyyyMmDd(tour.schedule_from),
          status: (
            <div
              className={`br-20 py-1 ${
                tour.booking_status === "approved"
                  ? "approved-btn"
                  : tour.booking_status === "pending"
                  ? "pending-bg pending-clr"
                  : "reject-bg reject-clr"
              }`}
            >
              {tour.booking_status === "approved"
                ? "Approved"
                : tour.booking_status === "pending"
                ? "Pending"
                : "Rejected"}
            </div>
          ),
          paid_amount: (
            <div className="clr-approve br-20 py-1">{tour.total_price}</div>
          ),
          document: (
            <img
              className="clr-pink pointer h-10vh w-100 br-10"
              src={tour.invoice_details}
              alt=""
            ></img>
          ),
          cancel:
            tour.booking_status !== "approved" ? (
              <div
                className="pointer"
                onClick={() => handleCancelPopup(tour.booking_id)}
              >
                Cancel
              </div>
            ) : null,
          // cancel: (
          //   <div
          //     className="pointer"
          //     onClick={() => handleCancelPopup(tour.booking_id)}
          //   >
          //     Cancel
          //   </div>
          // ),
          image:
            tour.path && tour.path.length > 0 ? (
              <img
                src={tour.path[0]}
                alt={tour.tour_title}
                style={{ width: "100px", height: "auto" }}
              />
            ) : (
              <div>No Image Available</div>
            ),
        }))
      : completedTours?.length &&
        completedTours?.map((tour, index) => ({
          s_no: index + 1,
          tour_title: tour.tour_title,
          start_date: formatDateToYyyyMmDd(tour.schedule_from),
          end_date: formatDateToYyyyMmDd(tour.schedule_upto),
          paid_amount: <div className="clr-green">{tour.total_price}</div>,
          document: (
            <div
              className="clr-pink pointer"
              onClick={() => handleDocuments(tour)}
            >
              View
            </div>
          ),
          cancel: (
            <div className="pointer" onClick={handleCancelPopup}>
              Cancel
            </div>
          ),
          image:
            tour.path && tour.path.length > 0 ? (
              <img
                src={tour.path[0]}
                alt={tour.tour_title}
                style={{ width: "100px", height: "auto" }}
              />
            ) : (
              <div>No Image Available</div>
            ),
        }));

  // const columns = [
  //   { header: "Sno", field: "s_no" },
  //   { header: "Tour Title", field: "tour_title" },
  //   { header: "Start Date", field: "start_date" },
  //   ...(activeHead === "Completed Trips"
  //     ? [{ header: "End Date", field: "end_date" }]
  //     : [{ header: "Status", field: "status" }]),
  //   { header: "Paid Amount", field: "paid_amount" },
  //   { header: "Invoice", field: "document" },
  //   ...(activeHead === "Upcoming Trips"
  //     ? [{ header: "Cancel", field: "cancel" }]
  //     : []),
  // ];
  const columns = [
    { header: "Sno", field: "s_no" },
    { header: "Tour Title", field: "tour_title" },
    { header: "Start Date", field: "start_date" },
    ...(activeHead === "Completed Trips"
      ? [{ header: "End Date", field: "end_date" }]
      : [{ header: "Status", field: "status" }]),
    { header: "Paid Amount", field: "paid_amount" },
    { header: "Invoice", field: "document" },
    ...(activeHead === "Upcoming Trips"
      ? [
          {
            header: "Cancel",
            field: "cancel",
            showCondition: (tour) => tour.booking_status !== "approved", // Only include if not approved
          },
        ]
      : []),
  ];

  // Render logic for the "Cancel" column
  const renderRow = (row) => {
    return columns
      .filter((column) => !column.showCondition || column.showCondition(row)) // Filter out columns where showCondition is not met
      .map((column) => {
        return <td key={column.field}>{row[column.field]}</td>;
      });
  };
 
  const headings = ["Upcoming Trips", "Completed Trips"];
  return (
    <div className="w-100 p-2 mt-2">
      <div className="row w-50 pointer">
        {headings.map((item, i) => (
          <div
            key={i}
            className="col  d-flex justify-content-center small-font"
            onClick={() => setActiveHead(item)}
          >
            <div
              className={
                activeHead === item
                  ? "pink-clr clr-white py-2 w-100 br-20 text-center fw-600"
                  : "py-2 br-20 bg-white w-100 text-center fw-600"
              }
            >
              {item}
            </div>
          </div>
        ))}
      </div>
      <div className="white-bg br-10 px-2 mt-2">
        <Table data={data} columns={columns} title={activeHead} />
      </div>
      <Documents
        showDocumentsPopup={showDocumentsPopup}
        setShowDocumentsPopup={setShowDocumentsPopup}
        images={selectedImage}
      />
      <CancelPopup
        cancelPopup={cancelPopup}
        setCancelPopup={setCancelPopup}
        bookingIdToCancel={bookingIdToCancel}
        refreshTours={refreshTours}
      />
    </div>
  );
}

export default MyTours;
