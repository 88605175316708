import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Documents from "../popups/Documents";
import { getTicketByUserId } from "../../api/apiMethods";

function Tickets() {
  const [showDocumentsPopup, setShowDocumentsPopup] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);

  const handleDocuments = (ticket) => {
    setTicketDetails(ticket);
    setShowDocumentsPopup(true);
  };

  const [getTicketData, setTicketData] = useState();
  const userID = localStorage.getItem("user_id");
  const getUserTickets = () => {
    getTicketByUserId(userID)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setTicketData(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserTickets();
  }, []);
  console.log(getTicketData, "getTicketData");

  const formattedData = getTicketData?.data.map((ticket, index) => ({
    s_no: index + 1,
    tour_title: ticket.tour_title,
    start_date: new Date(ticket.start_date).toLocaleDateString(),
    status: (
      <div className="approve-bg clr-approve br-20 py-1">
        {ticket.tickets_status.charAt(0).toUpperCase() +
          ticket.tickets_status.slice(1)}
      </div>
    ),
    paid_amount: ticket.ticket_price.toLocaleString(),
    document: (
      <div className="clr-pink pointer" onClick={() => handleDocuments(ticket)}>
        View
      </div>
    ),
  }));

  const columns = [
    { header: "Sno", field: "s_no" },
    { header: "Tour Title", field: "tour_title" },
    { header: "Start Date", field: "start_date" },
    { header: "Status", field: "status" },
    { header: "Paid Amount", field: "paid_amount" },
    { header: "Tickets", field: "document" },
  ];

  return (
    <div className="w-100 p-2 mt-2">
      <div className="white-bg br-10 px-2">
        <div className="password-clr large-font p-2 fw-600">Tickets</div>
        <Table data={formattedData} columns={columns} itemsPerPage={10} />
      </div>

      <Documents
        showDocumentsPopup={showDocumentsPopup}
        setShowDocumentsPopup={setShowDocumentsPopup}
        ticketDetails={ticketDetails}
      />
    </div>
  );
}

export default Tickets;
