import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaEye, FaTimes } from "react-icons/fa";
import {
  getToursBydates,
  createMemories,
  uploadImage,
  getTourCategories,
} from "../../api/apiMethods";
import { uploadFilesToS3 } from "../../Images/images";

function UploadVideos({ openUploadVideos, setShowOpenVideos, isVideoUpload }) {
  const handleUploadClose = () => {
    setShowOpenVideos(false);
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filePaths, setFilePaths] = useState([]);
  const fileInputRef = useRef(null);
  console.log(uploadProgress, "Upload Progress Percentage");
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedFiles.length > 5) {
      alert("You can upload a maximum of 5 files.");
      return;
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    uploadFilesToS3(files, (progress) => {
      setUploadProgress(progress);
    })
      .then((urls) => {
        setFilePaths((prevPaths) => [...prevPaths, ...urls]);
        setIsUploadComplete(true);
      })
      .catch((error) => {
        console.error("Error Uploading Files", error);
      });
  };

  const removeFile = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedPaths = filePaths.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
    setFilePaths(updatedPaths);
    if (updatedFiles.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleCategory = (event) => {
    setSelectedCategoryId(event.target.value);
  };

  const [categories, setCategories] = useState([]);
  const getCategories = () => {
    getTourCategories({})
      .then((response) => {
        if (response.status === true) {
          console.log(response, "get categories");
          setCategories(response?.data?.category);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    getCategories();
  }, []);

  console.log(selectedFiles, "selectedFiles");
  console.log(filePaths, "filePaths");
  console.log(uploadProgress, "UP");

  const handlePreview = (file) => {
    setSelectedFile(file);
    setIsLoading(true);
    setShowModal(true);
  };

  const handleCancel = () => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== selectedFile)
    );
    setUploadProgress((prevProgress) => {
      const { [selectedFile.name]: _, ...rest } = prevProgress;
      return rest;
    });
    setSelectedFile(null);
    setShowModal(false);
  };

  const handleRemove = (file) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    setUploadProgress((prevProgress) => {
      const { [file.name]: _, ...rest } = prevProgress;
      return rest;
    });
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toursData, setTourData] = useState([]);

  const getTourbydates = () => {
    getToursBydates({ StartDate: startDate, EndDate: endDate })
      .then((response) => {
        if (response.status === true) {
          console.log(response, "date wise tours---");
          setTourData(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  const filterTours = selectedCategoryId
    ? toursData.filter(
        (tour) => Number(tour?.category_id) === Number(selectedCategoryId)
      )
    : toursData;

  useEffect(() => {
    if (startDate && endDate) {
      getTourbydates();
    }
  }, [startDate, endDate]);

  console.log(uploadedFiles, "678");

  const imgName = selectedFiles?.map((img) => img?.name);
  console.log(imgName, "image path");

  const ImgPath = {
    path: filePaths,
    type: isVideoUpload ? "video" : "image",
  };

  const [uploaddata, setUploaddata] = useState([]);
  console.log(uploaddata, "===>uploaddata");

  const uploadMemory = () => {
    console.log(ImgPath, "ImgPath before upload");
    uploadImage(ImgPath)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "hdhs data");
          setUploaddata(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  const imgIds = uploaddata?.map((item) => item?.id);
  console.log(imgIds, "img iddd");
  const [selectedTourId, setSelectedTourId] = useState(null);
  console.log(selectedTourId, "tour id");
  const handleTour = (e) => {
    setSelectedTourId(e.target.value);
  };

  const userId = localStorage.getItem("user_id");
  const payload = {
    upload_id: imgIds,
    memory_name: "user_memory",
    tour_id: selectedTourId,
    user_id: Number(userId),
  };
  console.log(payload, "payload");
  const postMemories = () => {
    createMemories(payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "res cretae mem");
          setStartDate("");
          setEndDate("");
          setTimeout(() => {
            clearPayload({});
          }, 1000);

          setTimeout(() => {
            setShowOpenVideos(false);
          }, 1500);
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  };

  const clearPayload = () => {
    payload.upload_id = [];
    payload.memory_name = "";
    payload.tour_id = null;
    payload.user_id = null;
  };
  const handleVideoLoaded = () => {
    setIsLoading(false);
  };
  return (
    <Modal
      show={openUploadVideos}
      onHide={handleUploadClose}
      className="upload-popup"
    >
      <Modal.Header closeButton className="font-12 clr-black">
        <Modal.Title className="password-clr text-center fw-bold pointer large-font">
          {isVideoUpload ? "Upload Videos" : "Upload Photos"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="white-bg">
          <div className="d-flex flex-column border-pink br-10 p-2 my-2">
            <div className="d-flex flex-row small-font justify-content-around mt-1">
              <div className="d-flex flex-column col">
                <label className="small-font fw-bold password-clr">
                  Start Date
                </label>
                <input
                  type="date"
                  className="w-100 input-bg p-2 br-20 border-none small-font"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></input>
              </div>
              <div className="d-flex flex-column col">
                <label className="small-font fw-bold password-clr">
                  End Date
                </label>
                <input
                  type="date"
                  className="w-100 input-bg p-2 br-20 border-none small-font"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-around">
              <div className="d-flex flex-column col">
                <label className="small-font fw-bold password-clr">
                  Tour Category
                </label>
                <select
                  className="w-100 input-bg p-2 br-20 border-none small-font"
                  onChange={handleCategory}
                >
                  <option>Select Category</option>
                  {categories?.map((category, index) => (
                    <option
                      className=""
                      key={index}
                      value={category?.category_id}
                    >
                      {category?.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex flex-column col">
                <label className="small-font fw-bold password-clr">
                  Tour Name
                </label>
                <select
                  className="w-100 input-bg p-2 br-20 border-none small-font"
                  onChange={handleTour}
                >
                  <option>Select</option>
                  {filterTours?.map((tour, index) => (
                    <option className="" key={index} value={tour?.tour_id}>
                      {tour?.tour_title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column my-1">
            <div className="d-flex small-font my-1  password-clr">
              Uploading
            </div>
            <div className="font-10 grey-text">
              Please wait while your file is uploaded. the upload progress will
              be displayed below. Quality is more important than time.
            </div>
          </div>
          <div className="d-flex flex-column my-1">
            <div className="d-flex small-font my-1 fw-600 clr-pink">
              {isVideoUpload ? "Video Uploading" : "Photo Uploading"}
            </div>
            <input
              type="file"
              className="p-2 mt-1 input-bg rounded medium-font border-none pointer"
              onChange={handleChange}
              multiple
              accept={isVideoUpload ? "video/*" : "image/*"}
              ref={fileInputRef}
            />
            {selectedFiles.length > 0 && (
              <ul>
                {selectedFiles.map((file, index) => (
                  <li key={index} className="mt-1">
                    <div className="flex-between">
                      <span className="medium-font fw-500 clr-grey">
                        {file.name}
                      </span>
                      <span className="small-font clr-green">
                        {uploadProgress[file.name] || 0}%
                      </span>
                      <FaEye onClick={() => handlePreview(file)} />
                      <button
                        onClick={() => removeFile(index)}
                        className="small-font fw-700 pink-dashed-border white-bg rounded-pill px-2 like-clr"
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                ))}

                <div
                  className="clr-white fw-600 text-center w-30 py-1 px-1 br-5 my-1 pink-bg cursor-pointer"
                  onClick={uploadMemory}
                >
                  {isUploadComplete ? "Upload" : "Loading....."}
                  {/* Upload */}
                </div>
              </ul>
            )}

            <Modal show={showModal} onHide={handleCancel} centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>{selectedFile && selectedFile.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {isLoading && (
                  <div className="text-center my-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
                {selectedFile && selectedFile.type.startsWith("video/") ? (
                  <video
                    controls
                    src={URL.createObjectURL(selectedFile)}
                    onLoadedData={handleVideoLoaded}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                    }}
                  />
                ) : (
                  selectedFile && (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Preview"
                      className="img-fluid"
                    />
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary">Upload</Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div
            className="pink-bg py-2 br-20 clr-white fw-600 text-center my-3 pointer medium-font"
            onClick={postMemories}
          >
            Submit
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UploadVideos;
