import React, { useState } from "react";
import {
  Modal,
  Form,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Images } from "../../Images";
import chatting from "../../api/chatApis/chatting";

const ForwardMessage = ({
  isOpen,
  onClose,
  myAcceptedFriends,
  forwardMessageId,
}) => {
  const [search, setSearch] = useState("");
  const [forwardMembers, setForwardMembers] = useState([]);

  const filteredChats = myAcceptedFriends?.filter((chat) =>
    chat?.userName?.toLowerCase().includes(search?.toLowerCase())
  );

  const dummyImage = Images.chatpic2;

  const forwardMembersList = (userId) => {
    setForwardMembers((prevForwardMembers) => {
      if (prevForwardMembers.includes(userId)) {
        return prevForwardMembers.filter((id) => id !== userId);
      } else {
        return [...prevForwardMembers, userId];
      }
    });
    console.log(forwardMembers.join(","), "===> forwardMembers");
  };

  const handleSendForwardMessage = async () => {
    const response = chatting.forwardMessages(
      forwardMessageId,
      forwardMembers.join(","),
      "data"
    );
    console.log(response.data, "==>forwardTheMessages");
    onClose(true);
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="sm" centered>
      <div className="m-2 d-flex flex-between">
        <h6>Forward Message</h6>
        <IoClose className="pointer" onClick={onClose} />
      </div>

      <Modal.Body>
        {/* Search input */}
        <div className="mb-3">
          <FormControl
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* Recent Chats */}

        <div className="chat-list-container">
          <ListGroup>
            {filteredChats?.map((chat, index) => (
              <ListGroup.Item key={index} className="d-flex align-items-center">
                <div className=" d-flex w-100 ">
                  <img
                    src={chat?.img || dummyImage}
                    alt="chat"
                    className="chat-profile "
                  />
                  <div className="d-flex flex-between w-100">
                    <h6 className="my-2 ms-2">{chat?.userName}</h6>
                    <Form.Check
                      type="checkbox"
                      className="my-2"
                      onClick={() => forwardMembersList(chat?.userId)}
                      checked={forwardMembers?.includes(chat.userId)}
                    />
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        <div className="w-100">
          <div className=" flex-end  ">
            <span
              className="Forward-btn flex-center pointer"
              onClick={handleSendForwardMessage}
            >
              Send
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForwardMessage;
