import React, { useEffect, useRef, useState } from "react";
import { IoClose, IoCloudUploadOutline } from "react-icons/io5";
import { Images } from "../../Images";
import { CgCalendarDates, CgTrash } from "react-icons/cg";
import { HiDotsVertical } from "react-icons/hi";
import { FaCommentDots } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import {
  getAllMemories,
  getMyMemories,
  postLike,
  memoryPrivacyRequest,
  dislike,
  deleteMemoryById,
} from "../../api/apiMethods";

import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { MdOutlineClose } from "react-icons/md";
import DeletePopup from "../popups/DeletePopup";
import UploadVideos from "./UploadVideos";

function Memories() {
  const [like, setlike] = useState();
  const isAuthorized = localStorage?.getItem("isAuthorized");
  const login_userid = localStorage.getItem("user_id");
  const loginUserId = Number(login_userid);
  const [report, setreport] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();
  const [photoStatus, setPhotoStatus] = useState("");
  const [show, setShow] = useState(false);
  const [showMemoryId, setShowMemoryId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (memoryId, status) => {
    setShow(true);
    setShowMemoryId(memoryId);
    setreport(false);
    setPhotoStatus(status);
  };
  const handlereport = (memoryId) => {
    setreport(!report);
    setShowMemoryId(memoryId);
  };

  const [openUploadVideos, setShowOpenVideos] = useState(false);
  const [isVideoUpload, setIsVideoUpload] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const handleUploadVideos = () => {
    setShowOpenVideos(true);
    setIsVideoUpload(true);
  };
  const handleShowPhotos = () => {
    setIsVideoUpload(false);
    setShowOpenVideos(true);
  };

  const memoriesButtons = ["All Photos", "Latest", "Popular", "My Photos"];

  const [activeMemoryButton, setActiveMemoryButton] = useState(0);
  const handleMemoryButton = (index) => {
    setActiveMemoryButton(index);

    if (index === 1) {
      fetchAllMemories({ userMemories: "latest" });
    } else if (index === 2) {
      fetchAllMemories({ userMemories: "popular" });
    } else if (index === 3) {
      fetchAllMyMemories();
    } else {
      fetchAllMemories();
    }
  };

  const [getMemories, setGetMemories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  console.log(videos, "videos");
  const fetchAllMemories = (userMemories = {}) => {
    getAllMemories({ userMemories })
      .then((response) => {
        if (response.status === true) {
          console.log(response, "res", userMemories);
          setGetMemories(response?.data?.record);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMemories();
  }, []);

  useEffect(() => {
    const imgData = [];
    const videosData = [];
    getMemories.forEach((memory) => {
      if (memory.type === "image") {
        imgData.push(memory);
      } else if (memory.type === "video") {
        videosData.push(memory);
      }
    });

    setPhotos(imgData);
    setVideos(videosData);
  }, [getMemories]);

  const postPrivacyReqMyPhotos = (memoryId, privacy) => {
    console.log(memoryId, privacy, "45678");
    memoryPrivacyRequest(memoryId, { privacy: privacy })
      .then((response) => {
        if (response.status === true) {
          console.log("privacy staus requset", response);
          handleClose();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  const handleSubmitPrivacy = () => {
    const privacy =
      photoStatus === "public" ? "privaterequest" : "publicrequest";
    postPrivacyReqMyPhotos(showMemoryId, privacy);
  };

  const [myMemories, setMyMemories] = useState([]);
  console.log(myMemories, "user my photos");
  const fetchAllMyMemories = () => {
    getMyMemories({})
      .then((response) => {
        if (response.status === true) {
          console.log(response, "my user images");
          setMyMemories(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMyMemories();
  }, []);

  const [likedStatus, setLikedStatus] = useState({});
  useEffect(() => {
    const initialLikeStatus = {};
    photos.forEach((photo) => {
      initialLikeStatus[photo.memory_id] = !!photo.like_id;
    });
    setLikedStatus(initialLikeStatus);
  }, [photos]);

  const handleLikes = (memoryId, likedId) => {
    if (likedStatus[memoryId]) {
      dislike(likedId)
        .then((response) => {
          if (response.status === true) {
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: false,
            }));
            setPhotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                      ...photo,
                      likes_count: photo.total_likes_count - 1,
                      like_id: null,
                    }
                  : photo
              )
            );
            fetchAllMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      postLike({ memories_id: memoryId })
        .then((response) => {
          if (response.status === true) {
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: true,
            }));
            setPhotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                      ...photo,
                      likes_count: photo.total_likes_count + 1,
                      like_id: response?.data?.like_id,
                    }
                  : photo
              )
            );
            fetchAllMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const [selectedCard, setSelectedCard] = useState(null);
  const [videoSrc, setVideoSrc] = useState("");

  const handleVideo = (videoPath) => {
    setVideoSrc(videoPath);
  };

  const [videoPlay, setVideoPlay] = useState(false);

  const videoPlayModal = () => {
    setVideoPlay(true);
  };

  const [memoryDelId, setMemoryDelId] = useState(null);
  const handleDeletePopup = (memoryId) => {
    setShowConfirmationPopup(true);
    setMemoryDelId(memoryId);
  };

  const handleDeleteMemory = () => {
    deleteMemoryById(memoryDelId)
      .then((response) => {
        if (response.status === true) {
          setShowConfirmationPopup(false);
          fetchAllMyMemories();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  console.log(memoryDelId, " DelMemoryId");

  return (
    <div className="w-100 mt-2">
      <div className="w-100 flex-between align-items-center">
        <h5 className="fw-600">Tour Videos</h5>
        {isAuthorized && (
          <div
            className="text-wrap medium-font fw-600 sub-text clr-pink d-flex align-items-center upload-button pointer px-3 py-1"
            onClick={handleUploadVideos}
          >
            Upload Video
          </div>
        )}
      </div>
      <div className="row mt-2">
        <div className="col-8">
          {
            <div className="video-player">
              {videoSrc ? (
                <video
                  className="video-container"
                  controls
                  src={videoSrc}
                  title="Selected Video"
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p className="no-video-text">
                  Select a tour to watch its video
                </p>
              )}
            </div>
          }
        </div>
        <div className="col pointer">
          <div className="videos-section">
            {videos.map((item, i) => (
              <div
                key={i}
                className={`p-2 bg-white clr-black d-flex radius-10 mb-2 mr-2 
                
                  ${selectedCard === i ? "border-pink" : ""}
                }`}
                onClick={() => {
                  setSelectedCard(i);
                  handleVideo(item?.path);
                }}
              >
                <img
                  alt="title"
                  src={Images.trip_img_01 || item?.path}
                  className="trip-images1"
                ></img>
                <div className="d-flex flex-column justify-content-center ml-2">
                  <div className="sub-text  font-weight-bold">
                    {item.tour_title}
                  </div>
                  <div className="small-heading  d-flex align-items-center">
                    <CgCalendarDates />
                    {moment(item.created_at).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <h5 className="fw-600 mt-3">Tour Photos</h5>
      <div className="w-100 sub-text p-2 d-flex justify-content-between">
        <div className="w-75 px-2 row align-items-center pointer">
          {memoriesButtons.map((memory, index) => (
            <div
              key={index}
              className={`col text-center py-1 px-3 rounded-pill mr-2 medium-font fw-600 text-wrap ${
                activeMemoryButton === index
                  ? "pink-clr clr-white"
                  : "border-grey grey-text bg-white"
              }`}
              onClick={() => handleMemoryButton(index)}
            >
              {memory}
            </div>
          ))}
        </div>
        {isAuthorized && (
          <div
            className="text-wrap medium-font fw-600 sub-text clr-pink d-flex align-items-center upload-button px-3 py-1 cursor-pointer"
            onClick={handleShowPhotos}
          >
            Upload Photo
          </div>
        )}
      </div>
      <div>
        {activeMemoryButton === 0 &&
          photos?.map((img, index) => (
            <div
              className="img-card-container"
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                alt="title"
                src={img?.path || Images.upload_img_02}
                className="img-card mb-1"
                onClick={() => navigate(`/photosvideos/${img?.memory_id}`)}
              />
              {img?.user_id === loginUserId && (
                <div
                  className="report-dots clr-black"
                  onClick={() =>
                    handlereport(img?.memory_id, img?.memory_privacy)
                  }
                  title={
                    img?.memory_privacy === "public"
                      ? "Switch to Private"
                      : "Switch to Public"
                  }
                >
                  <HiDotsVertical />
                </div>
              )}
              {report === true &&
                img?.user_id === loginUserId &&
                showMemoryId === img?.memory_id && (
                  <div className="report-mode white-space small-font pointer d-flex flex-column flex-center">
                    <div
                      className="px-2 py-2"
                      onClick={() =>
                        handleShow(img?.memory_id, img?.memory_privacy)
                      }
                    >
                      {img?.memory_privacy === "public"
                        ? "Make it Private"
                        : "Make it Public"}
                    </div>
                  </div>
                )}

              <div className="like-comment d-flex justify-content-between w-80">
                <div className="fw-600 large-font pointer pink-clr-txt">
                  <FaHeart
                    className={`font-20 pointer ${
                      likedStatus[img?.memory_id] ? "clr-red" : "clr-white"
                    } mr-1`}
                    onClick={() => handleLikes(img?.memory_id, img?.like_id)}
                  />

                  <span className="clr-white medium-font">
                    {img?.total_likes_count}
                  </span>
                </div>
                <div
                  className="large-font pointer pink-clr-txt"
                  onClick={() => navigate(`/photosvideos/${img?.memory_id}`)}
                >
                  <FaCommentDots className="mt-1 clr-white pointer font-20" />{" "}
                  <span className="clr-white medium-font">
                    {img?.comments_count}
                  </span>
                </div>
              </div>
            </div>
          ))}
        {activeMemoryButton === 1 &&
          photos?.map((img, index) => (
            <div
              className="img-card-container"
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                alt="title"
                src={img?.path || Images.upload_img_02}
                className="img-card mb-1"
                onClick={() => navigate(`/photosvideos/${img?.memory_id}`)}
              />

              {img?.user_id === loginUserId && (
                <div
                  className="report-dots clr-black"
                  onClick={() =>
                    handlereport(img?.memory_id, img?.memory_privacy)
                  }
                  title={
                    img?.memory_privacy === "public"
                      ? "Switch to Private"
                      : "Switch to Public"
                  }
                >
                  <HiDotsVertical />
                </div>
              )}
              {report === true &&
                img?.user_id === loginUserId &&
                showMemoryId === img?.memory_id && (
                  <div className="report-mode small-font pointer d-flex flex-column  white-space">
                    <div
                      className="px-2 py-2"
                      onClick={() => handleShow(img?.memory_id)}
                    >
                      {img?.memory_privacy === "public"
                        ? "Make it Private"
                        : "Make it Public"}
                    </div>
                  </div>
                )}
              <div className="like-comment d-flex justify-content-between w-80">
                <div className="fw-600 large-font pointer pink-clr-txt">
                  <FaHeart
                    className={`font-20 pointer ${
                      likedStatus[img?.memory_id] ? "clr-red" : "clr-white"
                    } mr-1`}
                    onClick={() => handleLikes(img?.memory_id, img?.like_id)}
                  />
                  <span className="clr-white medium-font">
                    {img?.total_likes_count}
                  </span>
                </div>

                <div
                  className="large-font pointer clr-white"
                  onClick={() => navigate(`/photosvideos/${img?.memory_id}`)}
                >
                  <FaCommentDots className="mt-1 clr-white pointer font-20" />{" "}
                  <span className="medium-font">{img?.comments_count}</span>
                </div>
              </div>
              {/* )} */}
            </div>
          ))}
        {activeMemoryButton === 2 &&
          photos?.map((img, index) => (
            <div
              className="img-card-container"
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                alt="title"
                src={img?.path || Images.upload_img_02}
                className="img-card mb-1"
                onClick={() => navigate(`/photosvideos/${img?.memory_id}`)}
              />
              {img?.user_id === loginUserId && (
                <div
                  className="report-dots clr-black"
                  onClick={() =>
                    handlereport(img?.memory_id, img?.memory_privacy)
                  }
                  title={
                    img?.memory_privacy === "public"
                      ? "Switch to Private"
                      : "Switch to Public"
                  }
                >
                  <HiDotsVertical />
                </div>
              )}
              {report === true &&
                img?.user_id === loginUserId &&
                showMemoryId === img?.memory_id && (
                  <div className="report-mode small-font pointer d-flex flex-column  white-space">
                    <div
                      className="px-2 py-2"
                      onClick={() => handleShow(img?.memory_id)}
                    >
                      {img?.memory_privacy === "public"
                        ? "Make it Private"
                        : "Make it Public"}
                    </div>
                  </div>
                )}
              <div className="like-comment d-flex justify-content-between w-80">
                <div className="fw-600 large-font pointer pink-clr-txt">
                  <FaHeart
                    className={`font-20 pointer ${
                      likedStatus[img?.memory_id] ? "clr-red" : "clr-white"
                    } mr-1`}
                    onClick={() => handleLikes(img?.memory_id, img?.like_id)}
                  />

                  <span className="clr-white medium-font">
                    {img?.total_likes_count}
                  </span>
                </div>

                <div
                  className="large-font pointer pink-clr-txt"
                  onClick={() => navigate(`/photosvideos/${img?.memory_id}`)}
                >
                  <FaCommentDots className="mt-1 clr-white pointer font-20" />{" "}
                  <span className="clr-white medium-font">
                    {img?.comments_count}
                  </span>
                </div>
              </div>
            </div>
          ))}

        {activeMemoryButton === 3 &&
          myMemories?.map((img, index) => (
            <div
              className="img-card-container"
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                alt="title"
                src={img?.path || Images.upload_img_02}
                className="img-card mb-1"
              />
              <div className="delete-icon clr-black">
                <CgTrash onClick={() => handleDeletePopup(img?.memory_id)} />
              </div>
              <div
                className="report-dots clr-black"
                onClick={() =>
                  handlereport(img?.memory_id, img?.memory_privacy)
                }
                title={
                  img?.memory_privacy === "private"
                    ? "Switch to Public"
                    : "Switch to Private"
                }
              >
                <HiDotsVertical />
              </div>
              {report === true && showMemoryId === img?.memory_id && (
                <div className="report-mode white-space small-font pointer d-flex flex-column flex-center">
                  <div
                    className="px-2 py-2"
                    onClick={() => handleShow(img?.memory_id)}
                  >
                    {img?.memory_privacy === "private"
                      ? "Make it Public"
                      : "Make it Private"}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>

      <UploadVideos
        openUploadVideos={openUploadVideos}
        setShowOpenVideos={setShowOpenVideos}
        isVideoUpload={isVideoUpload}
      />
      <DeletePopup
        showConfirmationPopup={showConfirmationPopup}
        setShowConfirmationPopup={setShowConfirmationPopup}
        handleConfirm={handleDeleteMemory}
        description="Are you sure to delete your memeory from your private folder"
      />
      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Body>
          <div className="white-bg px-2 pb-2">
            <div className="w-100 d-flex justify-content-end">
              <MdOutlineClose
                className="font-20 clr-text cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="password-clr w-100 text-center fw-600 mt-3 ">
              {`You're about to share your photo in ${
                photoStatus === "public" ? "Private" : "Public"
              } View?`}
            </div>
            <div className="flex-between medium-font mt-3 fw-600">
              <div
                className="pink-bgg  p-2 rounded-pill grey-text  w-50 me-2 text-center border"
                onClick={handleSubmitPrivacy}
              >
                YES
              </div>
              <div
                className="pink-bgg p-2 rounded-pill grey-text w-50 ms-2 text-center border"
                onClick={handleClose}
              >
                NO
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={videoPlay} onHide={() => setVideoPlay(false)} centered>
        <div className="d-flex flex-column ">
          <div className="text-end" onClick={() => setVideoPlay(false)}>
            <IoClose className="font-30 mx-2 my-1" />
          </div>
          {videoPlay && (
            <div>
              <iframe
                src={
                  "https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL" ||
                  videoSrc
                }
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  width: "100%",
                  height: "600px",
                  border: "none",
                  padding: "10px",
                }}
              ></iframe>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Memories;
