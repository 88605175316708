import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../Images";
import { MdBlock } from "react-icons/md";
import { FcAddImage, FcGallery, FcSms } from "react-icons/fc";
import { BiSearchAlt } from "react-icons/bi";
import BlockPopup from "../popups/BlockPopup";
import moment from "moment";
import io from "socket.io-client";
import { IoMdSend } from "react-icons/io";

import { LuFolderLock } from "react-icons/lu";
import PrivateUserImages from "./PrivateUserImages";
import { FaCheck } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa6";
import Notice from "../../components/Notice";
import EditMessage from "./EditMessage";
import PersonalPhotos from "./PersonalPhotos";
import personalPhotos from "../../api/chatApis/personalPhotos";
import ForwardMessage from "./ForwardMessage";
import chatting from "../../api/chatApis/chatting";
import { chattingBaseUrl } from "../../api/baseUrl";
import { uploadFilesToS3 } from "../../Images/images";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../../utils/socketIo";
import { useLocation } from "react-router-dom";
import ChatBubble from "./ChatBubble";
import { RxCross2 } from "react-icons/rx";
import { Modal } from "react-bootstrap";

function Chat() {
  const menuRef = useRef(null);
  const chatEndRef = useRef(null);
  const [message, setMessage] = useState([]);
  const [userMessages, setUserMessages] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [friendRequests, setFriendRequests] = useState([]);
  const [myTourMembers, setMyTourMembers] = useState([]);
  const [myAcceptedFriends, setMyAcceptedFriends] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [blockPopoup, setBlockPopup] = useState(false);
  const [personalPhotosPopoup, setPersonalPhotosPopoup] = useState(false);
  const [onlineUsers, setonlineUsers] = useState("");
  const [friendRequestStatus, setFriendRequestStatus] = useState({});
  const [switchPendingRequests, setSwitchPendingRequests] = useState(0);
  const [peronalPhotosPendingRequests, setPeronalPhotosPendingRequests] =
    useState([]);
  const [openPrivatePhotos, setOpenPrivatePhotos] = useState(false);
  const [forwardMessageId, setForwardMessageId] = useState("");
  const [trueOnlineUsers, setTrueOnlineUsers] = useState([]);
  const [chattingWithUser, setChattingWithUser] = useState({
    userId: "",
    name: "",
  });
  const UserId = localStorage.getItem("user_id");
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [openEditpopup, setOpenEditPopup] = useState(false);
  const [editContent, setEditContent] = useState({
    userId: "",
    MessageId: "",
    content: "",
  });
  const [personalStatus, setPersonalStatus] = useState();
  const [myCreator, setMyCreator] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [chatWindow, setChatWindow] = useState(false);
  const [messageSeenId, setMessageSeenId] = useState([]);
  const [chatFullImage, setChatFullImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const [socketConnected, setSocketConnected] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const [fullImage, setFullImage] = useState();


  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (chattingWithUser.userId) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", chattingWithUser.userId);

        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setMessage((prevMessages) => [...prevMessages, message]);
        };

        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user: ${chattingWithUser.userId}`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: ${chattingWithUser.userId}`);
      }
    };
  }, [chattingWithUser, socketConnected]);

  useEffect(() => {
    disconnectSocket();
    setSocketConnected(false);

    if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
    }
  }, [location]);

  useEffect(() => {
    onEvent("user:typing", ({ senderId }) => {
      if (!typingUsers.includes(senderId)) {
        setTypingUsers((prev) => [...prev, recipientId]);
      }
      setTimeout(() => {
        setTypingUsers((prev) => prev.filter((id) => id !== recipientId));
      }, 10000);
    });

    onEvent("user:stoppedTyping", ({ senderId }) => {
      setTypingUsers((prev) => prev.filter((id) => id !== senderId));
    });

    return () => {
      offEvent("user:typing");
      offEvent("user:stoppedTyping");
      disconnectSocket();
    };
  }, []);

  const handleTyping = () => {
    if (!isTyping) {
      setIsTyping(true);
      emitEvent("user:typing", { senderId, recipientId });
      console.log(senderId, "==>typing", recipientId);

      const timeout = setTimeout(() => {
        setIsTyping(false);
        emitEvent("user:stoppedTyping", { senderId, recipientId });
      }, 2000);

      return () => clearTimeout(timeout);
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const handleChatFullImage = (mediaUrl) => {
    const imageToSet = uploadPosterURL[0] || mediaUrl; // Use uploadPosterURL if it exists, otherwise fallback to mediaUrl
    setFullImage(imageToSet);
    setChatFullImage(!chatFullImage);
  };
  

  const startEditing = (msg) => {
    setOpenEditPopup(true);

    setEditContent({
      userId: msg.recipientId,
      MessageId: msg.id,
      content: msg.content,
    });
  };

  useEffect(() => {
    if (chattingWithUser?.userId) getMessages();
  }, [chattingWithUser?.userId]);

  const getMyTourMembers = async () => {
    try {
      const response = await chatting.getMyTourMemberDetails();
      setMyTourMembers(response?.data);
    } catch (error) {
      console.error("Error fetching tour members:", error);
    }
  };

  const blockUser = async (userId) => {
    setBlockPopup(!blockPopoup);
  };

  const getFriendRequests = async () => {
    try {
      const response = await chatting.getFriendRequest();
      setFriendRequests(response?.data);
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  const getMyFriendsAll = async () => {
    try {
      const response = await chatting.getMyFriendsList();

      setMyAcceptedFriends(response?.data);
    } catch (error) {
      console.error("Error fetching friends list:", error);
    }
  };

  const getMessages = async () => {
    try {
      const response = await chatting.getChatMessages(chattingWithUser.userId);

      const filteredMessages = response?.data?.filter(
        (message) => !message.deletedBy
      );

      setMessage(filteredMessages);

      const matchedContents = filteredMessages
        ?.filter((message) => Number(message.senderId) !== Number(UserId))
        ?.map((message) => message.id);

      setMessageSeenId(matchedContents);

      console.log(`Matching message ids:`, matchedContents);
      markMessageAsSeen();
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const senderId = Number(chattingWithUser?.userId);
  const recipientId = Number(UserId);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const sendChatMessages = async () => {
    try {
      const messageData = {
        senderId: Number(chattingWithUser.userId),
        recipientId: Number(UserId),
        content: userMessages,
        messageType: uploadPosterURL?.length > 0 ? "image" : "text",
        mediaUrl: uploadPosterURL[0],
      };

      emitEvent("chat:sendMessage", messageData);
      markMessageAsSeen();

      setMessage((prevMessages) => [
        ...prevMessages,
        {
          content:
            messageData.messageType === "image"
              ? messageData.mediaUrl
              : messageData.content,
          senderId: Number(chattingWithUser.userId),
          date: moment(new Date()).format("DD-MM-YYYY hh:mm A"),
          messageType: messageData.messageType,
        },
      ]);

      setSelectedFile(null);
      setUploadPosterURL("");
      setUploadProgress("");
      setUserMessages("");
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await chatting.deleteMessage(messageId);

      console.log("Message deleted successfully", response);
      getMessages();
    } catch (error) {
      console.error("Failed to delete message", error);
    }
  };

  const handleDeleteMessageForBothusers = async (recipientId, messageId) => {
    try {
      const response = await chatting.deleteMessageForBothUsers(
        recipientId,
        messageId
      );

      console.log("Message deleted for both users successfully", response);
      getMessages();
    } catch (error) {
      console.error("Failed to delete message for both users", error);
    }
  };

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChange = (e) => {
    setUserMessages(e.target.value);
    handleTyping();
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendChatMessages();
      setUserMessages("");
    }
  };
  const handleAcceptFriendRequest = async (requestId) => {
    try {
      const response = await chatting.acceptFriendRequest(requestId);
      console.log("Friend request accepted:", response?.data);

      getAllFriendRequestsList();
      getMyFriendsAll();
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  const handleDeleteFriendRequest = async (requestId) => {
    try {
      const response = await chatting.rejectFriendRequest(requestId);
      console.log("Friend request rejected:", response?.data);
      getAllFriendRequestsList();
    } catch (error) {
      console.error("Error rejecting friend request:", error);
    }
  };

  const handleChatClick = (id, name, block, isCreator) => {
    console.log(id, name, block, isCreator, "===>id,name,block,isCreator");
    setMyCreator(isCreator);

    markMessageAsSeen();
    if (block === false) {
      setChattingWithUser({ userId: id, name: name, block: block });
      setChatWindow(true);
    } else {
      setChattingWithUser({ userId: id, name: name, block: block });
      setChatWindow(true);
    }
  };

  const getAllFriendRequestsList = async () => {
    const response = await chatting.getAllFriendrequests();
    setFriendRequestStatus(response?.data);
    console.log("Friend requests list fetched successfully:", response?.data);
  };
  const sendFriendRequest = async (id) => {
    console.log(UserId, "friend request sent successfully in web ", id);

    const response = await chatting.sendFriendRequest(id);
    getAllFriendRequestsList();
  };

  const handleCancelFriendrequest = async (id) => {
    const response = await chatting.cancelFriendRequest(id);
    getAllFriendRequestsList();
  };

  const handleAcceptFriendRequestTest2 = async (requestId) => {
    const response = await chatting.acceptFriendRequest(requestId);
    if (response.success) {
    }
  };

  const getPersonalPhotosRequest = async () => {
    try {
      const response = await personalPhotos.getPersonalPhotosRequest();

      setPeronalPhotosPendingRequests(response.requests);
    } catch (error) {
      console.error("Error fetching personal photos requests:", error);
    }
  };

  const acceptPersonalPhotoRequest = async (requestId) => {
    try {
      const response = await personalPhotos.acceptPersonalPhotosRequest(
        requestId
      );
      console.log("Request accepted:", response);
    } catch (error) {
      console.error("Error accepting personal photo request:", error);
    }
  };

  const declinePersonalPhotoRequest = async (requestId) => {
    const response = await personalPhotos.rejectPersonalPhotosRequest(
      requestId
    );
  };

  const getOnlineusersDetails = async () => {
    try {
      const response = await chatting.getOnlineUsers();

      setonlineUsers(response?.data?.onlineFriends);
    } catch (error) {
      console.error("Error getting online users details:", error);
    }
  };

  useEffect(() => {
    const filteredOnlineFriends = myAcceptedFriends?.filter((friend) =>
      onlineUsers?.includes(friend?.userId)
    );

    setTrueOnlineUsers(filteredOnlineFriends);
  }, [myAcceptedFriends, onlineUsers]);

  useEffect(() => {
    getAllFriendRequestsList();
    getOnlineusersDetails();
    getPersonalPhotosRequest();
  }, []);

  const handleForwardMessageId = (id) => {
    setIsModalOpen(true);
    setForwardMessageId(id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuIndex(null);
      }
    };

    if (openMenuIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuIndex]);

  const getPrivatePhotos = async () => {
    try {
      const response = await personalPhotos.getThePersonalPhotos(
        chattingWithUser.userId
      );

      console.log("Private photos:", response?.data);
      if (response.status === true) {
        setPersonalStatus(response.status);
      } else {
        setPersonalStatus(false);
      }
    } catch (error) {
      console.error("Error fetching private photos:", error);
    }
  };

  useEffect(() => {
    getPrivatePhotos();
  }, [chattingWithUser.userId]);

  const filteredFriends = myAcceptedFriends?.filter(
    (chat) =>
      chat?.userName &&
      chat?.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const markMessageAsSeen = async () => {
    try {
      const response = await chatting.getSeenMessage(messageSeenId);
      if (response?.data?.status) {
        console.log("Message marked as seen:", response?.data?.data);

        const updatedMessages = userMessages.map((message) =>
          message.id === messageSeenId ? { ...message, seen: true } : message
        );
        setUserMessages(updatedMessages);
      }
    } catch (error) {
      console.error("Error marking message as seen:", error.message);
    }
  };

  const getMyCreator = async () => {
    const response = await chatting.getMyCreatorDetails();
    const newCreatorDetails = response?.data;

    setMyAcceptedFriends((prevFriends) => [
      ...(prevFriends || []),
      newCreatorDetails,
    ]);
  };

  useEffect(() => {
    getMyTourMembers();
    getFriendRequests();
    getMyFriendsAll();
    getMyCreator();
  }, []);

  return (
    <div className="row py-2 mt-2">
      <div className="col-3">
        <div className="w-100 white-bg box-shadow-1 br-10">
          <div className="medium-font fw-600 p-2">Online Now</div>
          <div className="d-flex scroll-x border-bottom-grey">
            {trueOnlineUsers?.map((online, index) => (
              <div className="w-25 p-2" key={index}>
                <img
                  src={Images.chatpic4}
                  className="online-chat-profile"
                  alt="Profile"
                />
                <div className="medium-font fw-600 py-2">
                  {online?.userName}
                </div>
              </div>
            ))}
          </div>
          <div className="chat-scroll px-2">
            <div className="medium-font fw-600 py-2">Users</div>
            <div className="white-bg w-100 border d-flex align-items-center p-1">
              <input
                type="text"
                placeholder="Search Users"
                className="small-font grey-text w-90 all-none fw-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <BiSearchAlt className="large-font grey-text w-10" />
            </div>
            <div className="d-flex flex-column my-2">
              <div className="d-flex flex-column my-2">
                {filteredFriends?.map((chat, index) => (
                  <div
                    key={index}
                    className="d-flex flex-row align-items-center py-1"
                    onClick={() => {
                      if (chat.hasOwnProperty("isCreator")) {
                        handleChatClick(
                          chat?.userId,
                          chat?.userName,
                          chat?.blocked,
                          chat?.isCreator
                        );
                      } else {
                        handleChatClick(
                          chat?.userId,
                          chat?.userName,
                          chat?.blocked
                        );
                      }
                    }}
                  >
                    <div className="w-25">
                      <img
                        src={chat.image || Images.chatpic2}
                        className="chat-profile"
                        alt="Profile"
                      />
                    </div>
                    <div className="d-flex flex-column w-75 x-small-font pointer">
                      <div className="d-flex flex-row justify-content-between">
                        <div className="password-clr fw-600 small-font">
                          {chat?.userName}
                        </div>
                        <div className="grey-text">{"10:25"}</div>
                      </div>
                      <div className="grey-text x-small-font mt-2px">
                        {chat.blocked ? (
                          <span className="small-font">Blocked</span>
                        ) : (
                          <span>Tap To Chat</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-7">
        {chatWindow === true ? (
          <div className="br-10 box-shadow-1 chat-bg d-flex flex-column">
            <div className="white-bg   d-flex flex-row  set-margin align-items-center br-10 w-98 border-bottom-grey ">
              <div className="d-flex flex-row  chat-interface-profile p-2  w-75 ">
                <img src={Images.chatpic2} className="online-chat-profile " />
                <div className="d-flex flex-column mx-4">
                  <div className="password-clr  medium-font fw-600">
                    {chattingWithUser?.name}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center w-30 px-2  justify-content-around pointer">
                {myCreator === true ? null : (
                  <div
                    className=" bg-pink br-10   clr-white fw-600"
                    onClick={() => {
                      personalStatus === true
                        ? setOpenPrivatePhotos(true)
                        : setPersonalPhotosPopoup(true);
                    }}
                  >
                    {personalStatus === true ? (
                      <span className="view-images small-font p-2">View photos</span>
                    ) : (
                      <span className="view-images small-font p-2">Request photos</span>
                    )}
                  </div>
                )}

                <MdBlock className="large-font grey-text" onClick={blockUser} />
              </div>
            </div>
            <div className="d-flex flex-column middle-chat px-3">
              {message?.length && (
                <>
                  {message?.map((msg, index) => {
                    const messageDate = moment(msg?.timestamp).format(
                      "YYYY-MM-DD"
                    );
                    const today = moment().format("YYYY-MM-DD");
                    const yesterday = moment()
                      .subtract(1, "days")
                      .format("YYYY-MM-DD");

                    const prevMessageDate =
                      index > 0
                        ? moment(message[index - 1].timestamp).format(
                            "YYYY-MM-DD"
                          )
                        : null;

                    const displayDate = messageDate !== prevMessageDate;
                    const displayDateText =
                      messageDate === today
                        ? "Today"
                        : messageDate === yesterday
                        ? "Yesterday"
                        : moment(msg?.timestamp).format("MMMM DD, YYYY");

                    return (
                      <React.Fragment key={index}>
                        {displayDate && (
                          <div className="text-center font-weight-bold mt-3 mb-2">
                            <span className="small-font">
                              {displayDateText}
                            </span>
                          </div>
                        )}

                        <div
                          className={
                            Number(msg?.senderId) === Number(UserId)
                              ? "w-100 d-flex justify-content-start"
                              : "w-100 d-flex justify-content-end"
                          }
                          onMouseEnter={() => setHoverIndex(index)}
                          onMouseLeave={() => setHoverIndex(null)}
                        >
                          <div className="d-flex flex-column max-50w position-relative">
                            <div
                              className={
                                Number(msg?.senderId) === Number(UserId)
                                  ? "flex-column position-relative grey-text fw-600 grey-bg2 msg-width small-font px-3 pb-2 br-10 position-relative"
                                  : "flex-column position-relative outgoing-chat-clr fw-600 msg-width lightgreen-bg small-font px-3 pb-2 br-10 position-relative"
                              }
                            >
                              {hoverIndex === index && (
                                <span
                                  className="three-dots flex-end"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => toggleMenu(index)}
                                >
                                  <FaChevronDown className="small-font down-icon-chat" />
                                </span>
                              )}

                              {editingMessageId === msg.id ? (
                                <input
                                  type="text"
                                  value={editedContent}
                                  onChange={(e) =>
                                    setEditedContent(e.target.value)
                                  }
                                  className="edit-message-input"
                                />
                              ) : (
                                <div className="message-content medium-font">
                                  {msg?.messageType === "image" ? (
                                    <img
                                      src={msg?.mediaUrl || msg?.content}
                                      alt="Message Image"
                                      className="chatImagesize pointer"
                                      onClick={() =>
                                        handleChatFullImage(msg.mediaUrl || msg.content)
                                      }
                                    />
                                  ) : typeof msg?.content === "string" &&
                                    msg?.content.includes("http") ? (
                                    <img
                                      src={msg?.content}
                                      alt="Chat Image"
                                      className="chatImagesize pointer"
                                      onClick={() =>
                                        handleChatFullImage(msg?.mediaUrl)
                                      }
                                    />
                                  ) : (
                                    <span>{msg?.content}</span>
                                  )}
                                  <span className="font-10">
                                    {msg.edited === true ? "edited" : ""}
                                  </span>
                                </div>
                              )}
                            </div>
                         

                            <div className="message-footer">
                              <span className="timestamp time-font">
                                {msg.timestamp
                                  ? new Date(msg.timestamp).toLocaleTimeString(
                                      [],
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )
                                  : new Date().toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                              </span>
                              {Number(msg?.senderId) !== Number(UserId) && (
                                <FaCheckDouble
                                  className={`${
                                    msg.seen === true
                                      ? "font-10 clr-blue mx-1"
                                      : "font-10 mx-1"
                                  }`}
                                />
                              )}
                            </div>

                            {openMenuIndex === index && (
                              <div
                                className="menu-options position-absolute"
                                ref={menuRef}
                                style={{
                                  top: "20px",
                                  fontSize: ".6rem",
                                  right: "10px",
                                  background: "#fff",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                  zIndex: 1000,
                                }}
                              >
                                <ul className="popup-menu fw-700 small-font">
                                  <li
                                    className="menu-item"
                                    onClick={() =>
                                      handleForwardMessageId(msg.id)
                                    }
                                  >
                                    Forward
                                  </li>

                                  {Number(msg?.recipientId) ===
                                    Number(UserId) &&
                                    msg?.messageType !== "image" && (
                                      <li
                                        className="menu-item"
                                        onClick={() => startEditing(msg)}
                                      >
                                        Edit
                                      </li>
                                    )}

                                  <li
                                    className="menu-item"
                                    onClick={() => handleDeleteMessage(msg.id)}
                                  >
                                    Delete
                                  </li>

                                  <li
                                    className="menu-item"
                                    onClick={() =>
                                      handleDeleteMessageForBothusers(
                                        msg.recipientId,
                                        msg.id
                                      )
                                    }
                                  >
                                    Delete All
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
              {typingUsers.length > 0 && (
                <>
                  <ChatBubble />
                </>
              )}

              <div ref={chatEndRef} />
            </div>

            <div className="px-3">
              <div className="white-bg border-grey br-20 small-font d-flex flex-row my-2">
                <input
                  type="text"
                  placeholder={`${
                    selectedFile &&
                    uploadProgress &&
                    uploadProgress[selectedFile.name] === 100
                      ? "Image uploaded"
                      : "Type your message"
                  }`}
                  className={`all-none p-1 my-2 grey-text w-80 ${
                    selectedFile &&
                    uploadProgress &&
                    uploadProgress[selectedFile.name] === 100
                      ? "image-uploaded"
                      : ""
                  }`}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  value={userMessages}
                />

                <div className="d-flex flex-row w-20 align-items-center justify-content-around">
                  <div className="flex-center">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file-upload"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="file-upload">
                      <FcAddImage className="icon-font pointer" />
                    </label>

                    <div className="hight-25 mx-3"></div>
                    <IoMdSend
                      className="icon-font pointer"
                      onClick={sendChatMessages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="br-10 w-98  white-bg">
            <Notice />
          </div>
        )}
      </div>
      <div className="col-2">
        <div className="br-10 box-shadow-1 chat-bg">
          <div className="p-2 medium-font fw-600 border-bottom-grey">
            Add Friends
          </div>
          <div className="flex-column add-friend-div">
            {myTourMembers?.map((chat, index) => {
              const existingRequest =
                friendRequestStatus?.length &&
                friendRequestStatus?.find(
                  (request) =>
                    Number(request?.recipientId) === Number(chat?.id) &&
                    request.status === "PENDING"
                );

              return (
                <div key={index} className="d-flex align-items-center p-1">
                  <img
                    src={Images?.onlinepic1}
                    className="chat-profile"
                    alt="Profile"
                  />
                  <div className="ms-2 small-font fw-600">
                    <div className="password-clr">{chat?.user_name}</div>
                    <div
                      className=" clr-white w-90 small-font  pointer br-5 px-2 py-1"
                      onClick={() =>
                        existingRequest
                          ? handleCancelFriendrequest(chat?.id)
                          : sendFriendRequest(chat?.id)
                      }
                    >
                      <span className="pink-bg fit-content p-1 br-10">
                        {existingRequest ? "Pending" : "+Add"}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="br-10 box-shadow-1 chat-bg mt-2">
          <div className="p-2 d-flex w-100 flex-between medium-font fw-600 border-bottom-grey">
            <div
              className="switch-requests clr-white pink-bg d-flex small-font flex-center br-5 py-2 px-2 pointer"
              onClick={() => setSwitchPendingRequests(0)}
            >
              friends
            </div>
            <div
              className="switch-requests clr-white pink-bg small-font d-flex flex-center p-2 br-5 pointer"
              onClick={() => setSwitchPendingRequests(1)}
            >
              Photos
            </div>
          </div>
          <div className="d-flex flex-column my-2">
            {switchPendingRequests === 0 && (
              <div className="flex-column px-2 send-request-div">
                {friendRequests?.filter((item) => item.recipientId !== UserId)
                  .length === 0 ? (
                  <div className="w-100 white-bg  py-2 d-flex flex-column">
                    <div className="w-100 text-center password-clr small-font fw-600 my-1">
                      You don't have any pending requests.
                    </div>
                  </div>
                ) : (
                  friendRequests
                    ?.filter((item) => item?.senderId !== UserId)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="w-100 white-bg border-bottom-greyy py-2 d-flex flex-column"
                      >
                        <img src={Images.sendreq1} className="w-100 " />
                        <div className="w-100 text-center password-clr small-font fw-600 my-1">
                          {item.senderName}
                        </div>
                        <div>
                          <div className="d-flex w-100 justify-content-between">
                            <div
                              className="accept-decline clr-white d-flex flex-center small-font pink-bg pointer br-5 p-2"
                              onClick={() => handleAcceptFriendRequest(item.id)}
                            >
                              Accept
                            </div>
                            <div
                              className="border-grey accept-decline d-flex flex-center small-font pointer br-5 grey-text p-2"
                              onClick={() => handleDeleteFriendRequest(item.id)}
                            >
                              Decline
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            )}
            {switchPendingRequests === 1 && (
              <div className="flex-column px-2 send-request-div">
                {peronalPhotosPendingRequests.length === 0 ? (
                  <div className="w-100 white-bg border-bottom-greyy py-2 d-flex flex-column">
                    <div className="w-100 text-center password-clr small-font fw-600 my-1">
                      You don't have any pending photo requests.
                    </div>
                  </div>
                ) : (
                  peronalPhotosPendingRequests.map((item, index) => (
                    <div
                      key={index}
                      className="w-100 white-bg border-bottom-greyy py-2 d-flex flex-column"
                    >
                      <img src={Images.sendreq1} className="w-100 " />
                      <div className="w-100 text-center password-clr small-font fw-600 my-1">
                        {item?.userName}
                      </div>
                      <div>
                        <div className="d-flex w-100 justify-content-between">
                          <div
                            className="accept-decline clr-white d-flex flex-center small-font pink-bg pointer br-5"
                            onClick={() => acceptPersonalPhotoRequest(item.id)}
                          >
                            Accept
                          </div>
                          <div
                            className="border-grey accept-decline d-flex flex-center small-font pointer br-5 grey-text"
                            onClick={() => declinePersonalPhotoRequest(item.id)}
                          >
                            Decline
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
      show={chatFullImage}
      onHide={() => setChatFullImage(false)}
      centered
      className="custom-modal"
      size="lg" 
    >
      <div className="modal-content">
        <div className="w-100 d-flex justify-content-end">
          <RxCross2
            className="large-font clr-white pointer"
            onClick={() => setChatFullImage(false)}
          />
        </div>
        <div className="modal-body">
          <img src={fullImage} alt="Error To Show Image" className="full-image" />
        </div>
      </div>


      
    </Modal>

      <ForwardMessage
        isOpen={isModalOpen}
        myAcceptedFriends={myAcceptedFriends}
        onClose={() => setIsModalOpen(false)}
        forwardMessageId={forwardMessageId}
      />
      <PersonalPhotos
        personalPhotosPopoup={personalPhotosPopoup}
        setPersonalPhotosPopoup={setPersonalPhotosPopoup}
        myAcceptedFriends={myAcceptedFriends}
        chattingWithUser={chattingWithUser}
      />
      <PrivateUserImages
        openPrivatePhotos={openPrivatePhotos}
        setOpenPrivatePhotos={setOpenPrivatePhotos}
      />
      <EditMessage
        openEditpopup={openEditpopup}
        setOpenEditPopup={setOpenEditPopup}
        editContent={editContent}
        getMessages={getMessages}
      />
      <BlockPopup
        blockPopoup={blockPopoup}
        setBlockPopup={setBlockPopup}
        chattingWithUser={chattingWithUser}
      />
    </div>
  );
}

export default Chat;
