import apiRequest from "./apiRequest";

const loginUser = (data) => {
  return apiRequest("loginUser", data);
};

const signUpUser = (data) => {
  return apiRequest("signUpUser", data);
};

const getTourCategories = (data) => {
  return apiRequest("getTourCategories", data);
};

const resetUserPassword = (data) => {
  return apiRequest("resetUserPassword", data);
};

const updateUserProfile = (data) => {
  return apiRequest("updateUserProfile", data);
};

const getAllTrips = (queryParams) => {
  return apiRequest("getAllTrips", null, null, queryParams);
};

const getUserDetails = (data) => {
  return apiRequest("getUserDetails", data);
};

const getBannerById = (id) => {
  return apiRequest("getBannerById", null, id);
};

const postForgotPassword = (data) => {
  return apiRequest("postForgotPassword", data);
};

const postVerifyOTP = (data) => {
  return apiRequest("postVerifyOTP", data);
};

const updatePassword = (data) => {
  return apiRequest("updatePassword", data);
};

const getPublishedTours = (data) => {
  return apiRequest("getPublishedTours", data);
};

const getPublishedTourById = (id) => {
  return apiRequest("getPublishedTourById", null, id);
};

const getBankDetailsByTourId = (tour_id) => {
  return apiRequest("getBankDetailsByTourId", null, tour_id);
};
const cancelUserTrip = (tripId, data) => {
  return apiRequest("cancelUserTrip", data, tripId);
};
const bookingUser = (data) => {
  return apiRequest("bookingUser", data);
};

const uploadImage = (formData) => {
  return apiRequest("uploadImage", formData);
};

const getBestDestination = (data) => {
  return apiRequest("getBestDestination", data);
};

const getAllMemories = (queryParams) => {
  return apiRequest("getAllMemories", null, null, queryParams);
};

const getToursBydates = (data) => {
  return apiRequest("getToursBydates", data);
};
const createMemories = (data) => {
  return apiRequest("createMemories", data);
};
const sendComments = (id, data) => {
  return apiRequest("sendComments", data, id);
};

const editComment = (id, data) => {
  return apiRequest("editComment", data, id);
};
const deleteComment = (id, data) => {
  apiRequest("deleteComment", data, id);
};
const postLike = (data) => {
  return apiRequest("postLike", data);
};
const getMemoryById = (id, data) => {
  return apiRequest("getMemoryById", data, id);
};
const getMyMemories = (data) => {
  return apiRequest("getMyMemories", data);
};
const memoryPrivacyRequest = (id, data) => {
  return apiRequest("memoryPrivacyRequest", data, id);
};

const commnetDelReq = (id, data) => {
  return apiRequest("commnetDelReq", data, id);
};

const replyComment = (id, data) => {
  return apiRequest("replyComment", data, id);
};
const dislike = (id, data) => {
  return apiRequest("dislike", data, id);
};
const deleteMemoryById = (id, data) => {
  return apiRequest("deleteMemoryById", data, id);
};

const getLoginVideos = (data) => {
  return apiRequest("getLoginVideos", data);
};

const getPublicPhotos = (data) => {
  return apiRequest("getPublicPhotos", data);
};

const getPromotionalBanner = (data) => {
  return apiRequest("getPromotionalBanner", data);
};

const getTicketByUserId = (id) => {
  return apiRequest("getTicketByUserId", null, id);
};

const updateImageById = (id, data) => apiRequest("updateImageById", data, id);

const getUpcomingTrips = () => {
  return apiRequest("getUpcomingTrips");
};

const getCompletedTrips = () => {
  return apiRequest("getCompletedTrips");
};

const getUserDeposit = () => {
  return apiRequest("getUserDeposit");
};
const getWalletStatus = () => {
  return apiRequest("getWalletStatus");
};
const getWalletTable = () => {
  return apiRequest("getWalletTable");
};
const getBeforeLoginSidbarIamges = (data) =>
  apiRequest("getBeforeLoginSidbarIamges", data);

const getBeforeLoginTours = (data) => apiRequest("getBeforeLoginTours", data);

const getAllDepositBankings = (data) =>
  apiRequest("getAllDepositBankings", data);

const createUserDeposit = (data) => apiRequest("createUserDeposit", data);
const createUserWithdraw = (data) => apiRequest("createUserWithdraw", data);

const getUserWithdrawDetailsByID = (id) => {
  return apiRequest("getUserWithdrawDetailsByID", null, id);
};

const getUserDepositDetailsByID = (id) => {
  return apiRequest("getUserDepositDetailsByID", null, id);
};
const updateUserWithdrawDetailsByID = (id, data) => {
  return apiRequest("updateUserWithdrawDetailsByID", data, id);
};
const deleteUserWithdrawDetailsByID = (id, data) => {
  return apiRequest("deleteUserWithdrawDetailsByID", data, id);
};
const updateUserDepositDetailsByID = (id, data) => {
  return apiRequest("updateUserDepositDetailsByID", data, id);
};
const deleteUserDepositDetailsByID = (id, data) => {
  return apiRequest("deleteUserDepositDetailsByID", data, id);
};
export {
  loginUser,
  signUpUser,
  getTourCategories,
  resetUserPassword,
  updateUserProfile,
  getAllTrips,
  getUpcomingTrips,
  getCompletedTrips,
  getUserDetails,
  getBannerById,
  postForgotPassword,
  postVerifyOTP,
  updatePassword,
  getPublishedTours,
  getPublishedTourById,
  getBankDetailsByTourId,
  cancelUserTrip,
  bookingUser,
  uploadImage,
  getBestDestination,
  getAllMemories,
  getToursBydates,
  createMemories,
  sendComments,
  editComment,
  deleteComment,
  postLike,
  dislike,
  getMemoryById,
  getMyMemories,
  memoryPrivacyRequest,
  commnetDelReq,
  replyComment,
  deleteMemoryById,
  getLoginVideos,
  getPublicPhotos,
  getPromotionalBanner,
  getTicketByUserId,
  updateImageById,
  getUserDeposit,
  getWalletStatus,
  getWalletTable,
  getBeforeLoginSidbarIamges,
  getBeforeLoginTours,
  getAllDepositBankings,
  createUserDeposit,
  createUserWithdraw,
  getUserWithdrawDetailsByID,
  updateUserWithdrawDetailsByID,
  getUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID,
  updateUserDepositDetailsByID,
  deleteUserDepositDetailsByID,
};
