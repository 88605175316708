import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateImageById,
  updateUserProfile,
  uploadImage,
} from "../../api/apiMethods";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { Images } from "../../Images";
import { uploadFilesToS3 } from "../../Images/images";
import { IoClose } from "react-icons/io5";
import UserDetails from "./../booknowpopup/UserDetails";
import { useSelector } from "react-redux";
import { setUserDetailsData } from "./../../redux/action";

const EditProfilePopup = ({
  showEditProfile,
  setShowEditProfile,
  userDetails,
  // setUserDetails,
}) => {
  const [UserDetails, setUserDetails] = useState({ userDetails });
  console.log(userDetails, "===>userDetails");
  const countryCodes = [
    { code: "+91", name: "India" },
    { code: "+971", name: "Dubai" },
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+61", name: "Australia" },
  ];

  const fileInputRef = useRef();
  const [editProfileData, setEditProfileData] = useState({
    name: "",
    phone: "",
    country_code: "",
    profile_image: Images.profile_img,
    upload_id: "",
  });

  useEffect(() => {
    if (userDetails) {
      setEditProfileData({
        name: userDetails?.name || "",
        phone: userDetails?.phone || "",
        country_code: userDetails?.country_code || "",
        profile_image: userDetails?.profile_image || Images.profile_img,
        upload_id: userDetails?.upload_id || "",
      });
    }
  }, [userDetails]);

  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    userDetails?.profile_image || Images.profile_img
  );

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setEditProfileData((prev) => ({
      ...prev,
      [name]:
        name === "phone"
          ? value.replace(/\D/g, "").slice(0, 10)
          : files
          ? files[0].name
          : value,
    }));
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log(file, "==>file");

    if (file) {
      try {
        const [url] = await uploadFilesToS3([file], setUploadProgress);

        if (!url) throw new Error("Image upload failed");

        const uploadData = editProfileData.upload_id
          ? updateImageById(editProfileData.upload_id, {
              path: url[0],
              type: "image",
            })
          : uploadImage({ path: [url[0]], type: "image" });

        console.log(uploadData, "===>uploadData");

        const response = await uploadData;
        if (response?.status) {
          setEditProfileData((prevData) => ({
            ...prevData,
            profile_image: url,
            upload_id: editProfileData.upload_id || response.data[0]?.id,
          }));
          setProfileImageUrl(url);
        } else {
          throw new Error("Failed to upload/update image");
        }
      } catch (err) {
        console.error("Image upload failed:", err.message);
      }
    }
  };

  console.log(editProfileData.upload_id, "===>editProfileData.upload_id");

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleCountryCodeChange = (e) => {
    setEditProfileData((prev) => ({
      ...prev,
      country_code: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const uploadProfileId =
        editProfileData?.upload_id || userDetails?.upload_id;

      const response = await updateUserProfile({
        name: editProfileData?.name || userDetails?.name,
        country_code:
          editProfileData?.country_code || userDetails?.country_code,
        phone: editProfileData?.phone || userDetails?.phone,
        upload_id: uploadProfileId,
      });

      if (response?.status === true) {
        setMessage({
          text: "Your Profile Updated Successfully",
          type: "success",
        });

        setUserDetails((prevDetails) => ({
          ...prevDetails,
          name: editProfileData?.name || prevDetails.name,
          country_code:
            editProfileData?.country_code || prevDetails.country_code,
          phone: editProfileData?.phone || prevDetails.phone,
          profile_image:
            editProfileData?.profile_image || prevDetails.profile_image,
        }));

        setEditProfileData({});
        setTimeout(() => {
          setMessage("");
          setShowEditProfile(false);
          window.location.reload();
        }, 500);
      } else {
        setError("Something went wrong while updating the profile");
      }
    } catch (error) {
      setError(error?.message || "An error occurred");
      setMessage({ text: error?.message, type: "error" });
    }
  };

  return (
    <div>
      <Modal
        size=""
        show={showEditProfile}
        centered
        onHide={() => setShowEditProfile(false)}
        className="edit-popup"
      >
        <div className="p-2">
          <div className="clr-black d-flex flex-between align-items-center mt-2 mx-2">
            <div>
              <h5 className="mr-4 mx-2 my-2">Edit Profile</h5>
            </div>
            <div>
              <span onClick={() => setShowEditProfile(false)}>
                <IoMdClose className="font-24 mx-2 my-2 pointer" />
              </span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="flex-center mt-2 position-relative">
              <img
                src={editProfileData?.profile_image}
                alt="profile"
                className="img-height rounded-circle"
              />
              <div className="clr-lightpink bg-addicon edit-icon pointer">
                <IoMdAdd
                  className="font-30 flex-center"
                  onClick={handleIconClick}
                />
              </div>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e)}
            />
            <div className="d-flex flex-column">
              <div className="password-clr fw-600 font-16 mx-2 ">Name</div>
              <input
                type="text"
                placeholder="Enter Name"
                className="input-box mx-2 br-5 py-2 w-96 fw-500 mt-1 px-2 clr-grey2"
                name="name"
                value={
                  editProfileData?.name !== undefined
                    ? editProfileData?.name
                    : userDetails?.name || ""
                }
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="password-clr fw-600 font-16 mx-2 mb-2">
                Phone number
              </div>
              <div className="d-flex">
                <select
                  className="input-box br-5 py-2 mx-2 px-2 w-34 fw-500 mt-1 clr-grey2 pointer"
                  value={
                    editProfileData?.country_code ||
                    userDetails?.country_code ||
                    ""
                  }
                  onChange={handleCountryCodeChange}
                >
                  {countryCodes.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </select>

                <input
                  type="tel"
                  placeholder="Enter Phone Number"
                  className="input-box br-5 py-2 mx-2  fw-500 mx-2 px-2 mt-1 w-59 clr-grey2"
                  name="phone"
                  value={
                    editProfileData?.phone !== undefined
                      ? editProfileData?.phone
                      : userDetails?.phone || ""
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 mb-4 d-flex flex-column flex-center">
            <div
              className="bg-pink clr-white border w-96 text-center py-2 br-20 medium-font pointer"
              onClick={handleSubmit}
            >
              Save
            </div>
            {message && (
              <span
                className={` mt-1 ${
                  message?.type === "success" ? "clr-green" : "clr-red"
                }`}
              >
                {message.text}
              </span>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditProfilePopup;
