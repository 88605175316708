import { useEffect, useState } from "react";
import { Images } from "../../Images";
import { Carousel } from "react-bootstrap";
import { useParams } from "react-router";
import { getPublishedTourById } from "../../api/apiMethods";
import BookNow from "./BookNow";
import BookNowPopup from "./BookNowPopup";

function TourMessage() {
  const { tourId } = useParams();
  console.log(tourId, "=====>tourId<========");
  const [selectedTourData, setSelectedTourData] = useState();
  const [showBookNow, setShowBookNow] = useState(false);
  const handleBookNow = () => {
    setShowBookNow(true);
  };
  console.log(selectedTourData, "selectedTourData");
  const fetchTourDataById = () => {
    getPublishedTourById(tourId)
      .then((response) => {
        if (response?.status === true) {
          console.log(response, "ranjith========>response");
          setSelectedTourData(response?.data[0]);
        } else {
          console.log("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    fetchTourDataById();
  }, [tourId]);

  return (
    <div className="mt-2">
      <h5 className="password-clr my-2 fw-600">
        {selectedTourData?.tour_title}
      </h5>
      <Carousel interval={3000} fade={true} className="w-100">
        {selectedTourData?.banners?.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              src={item?.path || Images.Casino}
              alt="Casino"
              className="w-100"
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="white-bg my-2 p-2 br-10">
        <h6 className="password-clr fw-bold">Discription</h6>
        <div className="p-2 grey-text small-font grey-border br-20 mt-2">
          {selectedTourData?.quotations}
        </div>
        <div className="d-flex justify-content-end">
          <div
            className="w-25 pink-bg py-2 br-20 clr-white fw-700 text-center my-2 pointer"
            onClick={() => handleBookNow()}
          >
            Book Now
          </div>
        </div>
      </div>
      <BookNow
        tourId={tourId}
        showBookNow={showBookNow}
        setShowBookNow={setShowBookNow}
        selectedTourData={selectedTourData}
      />
    </div>
  );
}

export default TourMessage;
