import React, { useEffect, useState } from "react";
import Login from "../pages/login/Login";
import SignUpPopup from "../pages/login/SignUpPopup";
import OtpPopup from "../pages/login/OtpPopup";
import SubmitPopup from "../pages/popups/SubmitPopup";
import { Images } from "../Images";
import {
  getBeforeLoginSidbarIamges,
  getBeforeLoginTours,
} from "../api/apiMethods";
import { MdOutlinePlayCircleOutline } from "react-icons/md";
import { CgCalendarDates } from "react-icons/cg";
import moment from "moment";

function BforeLoginSideBar({ handleLogin, setIsLoggedIn, isLoggedIn }) {
  const BUTTONS = ["Photos", "Videos"];
  const LOGIN_BUTTONS = ["Login", "Signup"];
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [OtpModal, setOtpModal] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [userId, setUserId] = useState(null);
  const [activeLoginButton, setActiveLoginButton] = useState("Login");
  const [sidebarImagesData, setSidebarImagesData] = useState();
  const [toursData, setToursData] = useState();
  const [activeButton, setActiveButton] = useState(0);

  const handleLoginBtn = () => {
    setShowLogin(true);
    setShowSignUp(false);
  };

  const handleSignUpBtn = () => {
    setShowSignUp(true);
    setShowLogin(false);
  };

  const handleOtpModal = () => {
    setOtpModal(true);
    setShowLogin(false);
    setShowSignUp(false);
  };

  const handleSuccessPopup = () => {
    setSuccessPopup(true);
    setOtpModal(false);
    setShowSignUp(false);
    setShowLogin(false);
  };

  const handleButtons = (index) => {
    setActiveButton(index);
  };

  const handleLoginButtonClick = (button) => {
    setActiveLoginButton(button);
    button === "Login" ? handleLoginBtn() : handleSignUpBtn();
  };

  const handleGetSidebarPhotos = () => {
    getBeforeLoginSidbarIamges()
      .then((response) => {
        if (response?.status) {
          setSidebarImagesData(response?.data);
        } else {
          console.error("Something went wrong");
        }
      })
      .catch((error) => console.error(error?.message));
  };

  const handleGetUpcomingTours = () => {
    getBeforeLoginTours()
      .then((response) => {
        if (response?.status) {
          setToursData(response?.data);
        } else {
          console.error("Something went wrong");
        }
      })
      .catch((error) => console.error(error?.message));
  };

  useEffect(() => {
    handleGetSidebarPhotos();
    handleGetUpcomingTours();
  }, []);

  return (
    <div className="sidebar-bg px-2 py-1 br-10">
      <div className="white-bg br-15 p-2">
        <div className="w-100 d-flex justify-content-center">
          <img alt="" src={Images.logo} className="w-75" />
        </div>
        <div className="w-100 d-flex mt-2">
          {LOGIN_BUTTONS.map((button) => (
            <div
              key={button}
              className={`w-50 cursor-pointer rounded-pill fw-bold text-center py-1 px-3 mx-1 medium-font ${
                activeLoginButton === button
                  ? "pink-bg clr-white"
                  : "input-bg clr-black"
              }`}
              onClick={() => handleLoginButtonClick(button)}
            >
              {button}
            </div>
          ))}
        </div>
      </div>

      <div className="text-start clr-white fw-600 large-font my-2">
        Upcoming Tours
      </div>

      <div className="w-100 d-flex">
        {BUTTONS?.map((btn, index) => (
          <div
            key={btn}
            className={`w-50 cursor-pointer rounded-pill fw-bold text-center py-1 px-3 mx-1 medium-font ${
              activeButton === index
                ? "pink-bg clr-white"
                : "input-bg clr-black"
            }`}
            onClick={() => handleButtons(index)}
          >
            {btn}
          </div>
        ))}
      </div>

      {activeButton === 0 && (
        <div className="vertical-scroll mt-2 h-65vh">
          <div className="row">
            {sidebarImagesData?.map((img, index) => (
              <div className="col-6" key={index}>
                <img
                  alt=""
                  src={img?.path}
                  className="w-100 p-2 br-15"
                  style={{ height: "30vh" }}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {activeButton === 1 && (
        <div className="vertical-scroll mt-2 h-65vh">
          {toursData?.map((item, i) => (
            <div
              key={i}
              className="d-flex align-items-center bg-white clr-black w-100 br-15 mb-2 p-2"
            >
              <div className="position-relative w-30 h-10vh">
                <img
                  className="w-100 h-100 rounded"
                  src={item.path || Images.trip_img_01}
                  alt={item?.tour_title}
                />
                <div className="position-absolute top-50 start-50 translate-middle">
                  <MdOutlinePlayCircleOutline className="font-3rem clr-white" />
                </div>
              </div>
              <div className="w-70 ps-2">
                <div className="grey-text medium-font fw-600">
                  {item?.tour_title || "Tour Title"}
                </div>
                <div className="grey-text small-font fw-500 mt-1">
                  <CgCalendarDates className="medium-font me-1" />
                  {moment(item?.schedule_from).format("DD-MM-YYYY")}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <Login
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        handleOtpModal={handleOtpModal}
        handleSignUpBtn={handleSignUpBtn}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        handleLogin={handleLogin}
      />
      <SignUpPopup
        showSignUp={showSignUp}
        setShowSignUp={setShowSignUp}
        handleLoginBtn={handleLoginBtn}
        handleOtpModal={handleOtpModal}
        setUserId={setUserId}
      />
      <OtpPopup
        OtpModal={OtpModal}
        setOtpModal={setOtpModal}
        handleSuccessPopup={handleSuccessPopup}
        handleLoginBtn={handleLoginBtn}
        userId={userId}
      />
      <SubmitPopup
        successPopup={successPopup}
        setSuccessPopup={setSuccessPopup}
      />
    </div>
  );
}

export default BforeLoginSideBar;
