import React from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import personalPhotos from "../../api/chatApis/personalPhotos";
import { FaImages } from "react-icons/fa6";

function PersonalPhotos({
  personalPhotosPopoup,
  setPersonalPhotosPopoup,
  myAcceptedFriends,
  chattingWithUser,
}) {
  const handleBlockClose = () => {
    setPersonalPhotosPopoup(false);
  };

  const sendPersonalPhotosRequest = async () => {
    try {
      const response = await personalPhotos.requestForPersonalPhotos(
        chattingWithUser.userId
      );

      console.log("Personal photos request sent successfully:", response);

      setPersonalPhotosPopoup(false);
    } catch (error) {
      console.error("Failed to send personal photos request:", error);
    }
  };

  return (
    <Modal
      show={personalPhotosPopoup}
      onHide={setPersonalPhotosPopoup}
      className="otp-popup"
      centered
    >
      <div className="p-1">
        <div className="w-100 d-flex justify-content-end">
          <RxCross2 className="large-font" onClick={handleBlockClose} />
        </div>
        <div className="p-2">
          <div className="px-4 my-2">
            <h5 className="password-clr w-100 text-center fw-600">
              Do you want to request Personal Photos?
            </h5>
            
            <span className="block-icon-size flex-center">
            <FaImages />
            </span>
            <div className="d-flex flex-row align-items-center justify-content-around  mt-2">
              <div
                className="grey-bg py-2 br-20 grey-text fw-700 text-center border-grey w-40 pointer"
                onClick={() => handleBlockClose()}
              >
                Cancel
              </div>
              <div
                className="pink-bg py-2 br-20 clr-white fw-700 text-center border-grey w-40 pointer"
                onClick={sendPersonalPhotosRequest}
              >
                Request
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PersonalPhotos;
