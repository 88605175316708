import React from "react";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router";

function Successful() {
  const navigate = useNavigate();
  return (
    <div className="mt-2 px-2">
      <div className="d-flex justify-content-center w-100 my-2">
        <IoCheckmarkCircleSharp
          className="font-40 clr-green"
          style={{ fontSize: "100px" }}
        />
      </div>
      <div className="px-2">
        <h3 className="w-100 password-clr text-center fw-700 my-1">
          Congratulation!
        </h3>
        <h5 className="w-100 password-clr text-center fw-700 my-1">
          Your Booking is Completed
        </h5>
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={() => navigate("/my-tours")}
        >
          Go Back to Message
        </div>
      </div>
    </div>
  );
}

export default Successful;
