import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import QRCodeModal from "../popups/QRCodeModal";
import { uploadFilesToS3 } from "../../Images/images";
import {
  createUserDeposit,
  getAllDepositBankings,
  uploadImage,
} from "../../api/apiMethods";
import { RxCross2 } from "react-icons/rx";
import SubmitPopup from "../popups/SubmitPopup";

function AddAmountPopup({
  addAmountPopup,
  setShowAddAmountPopup,
  userWalletWithdrawTable,
}) {
  console.log(userWalletWithdrawTable, "userWalletWithdrawTable");

  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);

  const [uploadScreenshotId, setUploadScreensId] = useState(null);

  const handleShowQrCode = () => {
    setShowQrCode(true);
  };

  const handleCloseQrCode = () => {
    setShowQrCode(false);
  };

  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();

  const handleQrCodeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      handlePosterSubmit(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const handlePosterSubmit = (url) => {
    uploadImage({ path: url, type: "image" })
      .then((response) => {
        if (response?.status === true) {
          const scrrenshotId = response.data.map((item) => item.id);
          setUploadScreensId(scrrenshotId);
          console.log(scrrenshotId, "scrrenshotId");
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  const [bankingDetailsData, setBankingDetailsData] = useState();

  const [selectedGatewayId, setSelectedGatewayId] = useState(null);
  const [upiDetails, setUpiDetails] = useState({
    upiId: "",
    phoneNumber: "",
    name: "",
  });
  const [utrNo, setUtrNo] = useState("");

  const handleGatewayChange = (e) => {
    const selectedId = e.target.value;
    setSelectedGatewayId(selectedId);

    const selectedGateway = bankingDetailsData.find(
      (item) => item.banking_gateway_id === parseInt(selectedId)
    );

    if (selectedGateway) {
      setUpiDetails({
        upiId: selectedGateway.upi_id || "",
        phoneNumber: selectedGateway.mobile_number || "",
        name: selectedGateway.upi_name || "",
      });
    } else {
      setUpiDetails({ upiId: "", phoneNumber: "", name: "" });
    }
  };

  console.log(bankingDetailsData, "bankingDetailsData");
  const getBankingDetails = () => {
    getAllDepositBankings()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setBankingDetailsData(response.data);
        } else {
          console.log(error);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getBankingDetails();
  }, []);
  const [showSuccessPopup, setShowSuccessPopup] = useState();
  const [amount, setAmount] = useState("");

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const userDeposit = () => {
    let payload = {
      utr_no: utrNo,
      banking_gateway_id: Number(selectedGatewayId),
      upload_id: Number(uploadScreenshotId),
      deposit_amount: amount,
    };
    if (payload === "") {
      setError("please fill requred fields");
      return;
    }
    createUserDeposit(payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setShowAddAmountPopup(false);
          setTimeout(() => {
            setShowSuccessPopup(true);
          }, [1000]);
          setTimeout(() => {
            setError("");
          }, 1500);
        } else {
          console.log("Error Is Happ");
        }
      })
      .catch((error) => setError(error?.message));
  };
  useEffect(() => {
    userDeposit();
  }, []);
  return (
    <>
      <Modal
        show={addAmountPopup}
        centered
        onHide={() => setShowAddAmountPopup(false)}
      >
        <Modal.Body>
          <div className="px-2">
            <div className="flex-between my-2">
              <h5 className="text-black fw-600">Add Amount</h5>
              <RxCross2
                className="pointer font-2rem"
                onClick={() => setShowAddAmountPopup(false)}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              {" "}
              <div className="w-50 me-1">
                <label className="medium-font fw-500 mb-2 text-black">
                  Payment Gateway
                </label>
                <select
                  className="small-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
                  onChange={handleGatewayChange}
                >
                  <option value="">Select Payment</option>
                  {bankingDetailsData?.map((item) => (
                    <option
                      key={item.banking_gateway_id}
                      value={item.banking_gateway_id}
                    >
                      {item.gateway_name}
                    </option>
                  ))}
                </select>
                {/* {selectedGatewayId && (
                  <p className="mt-2">
                    Selected Gateway ID: {selectedGatewayId}
                  </p>
                )} */}
              </div>
              <div className="w-50 ms-1">
                <label className="medium-font fw-500 mb-2 text-black">
                  Amount{" "}
                </label>
                <input
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={handleAmountChange}
                  className="input-box1 small-font br-10 p-2 w-fill fw-500"
                />
              </div>
            </div>

            <div>
              <div className="w-100 mt-2">
                <label className="medium-font fw-500 mb-2 text-black">
                  UPI/Bank Details
                </label>
                <textarea
                  className="small-font text-hash input-box1 br-10 p-2 w-fill h-17vh fw-500 pointer"
                  value={`UPI ID: ${upiDetails.upiId}\nPhone Number: ${upiDetails.phoneNumber}\nName: ${upiDetails.name}`}
                  readOnly
                />
              </div>
              <div className="w-100 mt-2">
                <label className="medium-font fw-500 mb-2 text-black">
                  Upload Payment Screenshot
                </label>

                <input
                  type="file"
                  id="fileUpload"
                  className="file-input"
                  onChange={handleQrCodeUpload}
                />
                <label
                  htmlFor="fileUpload"
                  className="custom-file-upload input-bg px-2 py-1 small-font"
                >
                  <span className="small-font">Upload File</span>
                  <span className="upload-icon">📤</span>
                </label>

                {selectedFile && (
                  <div className="mt-2">
                    <p>Selected File: {selectedFile.name}</p>
                  </div>
                )}
              </div>
            </div>

            {qrCodeImage && (
              <div className="flex-center mt-2">
                <img
                  onClick={handleShowQrCode}
                  src={qrCodeImage}
                  alt="QR Code"
                  className="qr-code-img"
                />
              </div>
            )}
            <div className="w-100 my-1">
              <label className="medium-font fw-500 mb-2 text-black">
                Enter UTR No
              </label>
              <input
                type="text"
                placeholder="Enter UTR"
                className="input-box1 small-font br-10 p-2 w-fill fw-500"
                value={utrNo}
                onChange={(e) => setUtrNo(e.target.value)}
              />
            </div>

            <div className="flex-between w-100 mt-3 medium-font fw-600">
              <div
                className="pink-bg p-2 rounded-pill w-48 clr-white clr-white text-center"
                onClick={userDeposit}
              >
                Proceed
              </div>
              <div
                className="border-grey password-clr p-2 rounded-pill w-48 clr-white text-center"
                onClick={() => setShowAddAmountPopup(false)}
              >
                Cancel
              </div>
            </div>
            {/* {error && (
              <div className="clr-red medium-font flex-center my-2">
                {error}
              </div>
            )} */}
          </div>
        </Modal.Body>
        <QRCodeModal
          showQrCode={showQrCode}
          qrCodeImage={qrCodeImage}
          handleCloseQrCode={handleCloseQrCode}
        />
      </Modal>
      <SubmitPopup
        successPopup={showSuccessPopup}
        setSuccessPopup={setShowSuccessPopup}
        title="Scuccess!!!"
        description="Submitted Deposit Request Successfully"
      />
    </>
  );
}

export default AddAmountPopup;
