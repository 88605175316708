export const setUserDetailsData = (data) => ({
  type: "SET_USER_DETAILS_DATA",
  payload: data,
});

export const setUploadPosterId = (id) => ({
  type: "SET_UPLOAD_POSTER_ID",
  payload: id,
});

export const setTourCategoryData = (data) => ({
  type: "SET_TOUR_CATEGORY_DATA",
  payload: data,
});

export const setSlectedPackageName = (packageName) => ({
  type: "SET_SELECTED_PACKAGE_NAME",
  payload: packageName,
});

export const setTotalGroupMembers = (count) => ({
  type: "SET_TOTAL_GROUP_MEMBERS",
  payload: count,
});

export const setTotalPackageCost = (cost) => ({
  type: "SET_TOTAL_PACKAGE_COST",
  payload: cost,
});

export const setTotalSelectedMembers = (count) => ({
  type: "SET_TOTAL_SELECTED_MEMBERS",
  payload: count,
});

export const setMembersData = (members) => ({
  type: "SET_MEMBERS_DATA",
  payload: members,
});

export const setPackagesData = (packages) => ({
  type: "SET_PACKAGES_DATA",
  payload: packages,
});

export const setTotalGroupCost = (cost) => ({
  type: "SET_TOTAL_GROUP_COST",
  payload: cost,
});

export const setSelectedOption = (option) => ({
  type: "SET_SELECTED_OPTION",
  payload: option,
});

export const setSelectedPackagesCount = (count) => ({
  type: "SET_SELECTED_PACKAGES_COUNT",
  payload: count,
});
export const setWithdrawData = (data) => ({
  type: "SET_WITHDRAW_DATA",
  payload: data,
});
export const setPrivatePhotos = (data) => ({
  type: "SET_PRIVATE_PHOTOS",
  payload: data,
});
export const setImagePaymentId = (id) => ({
  type: "SET_IMAGE_PAYMENT_ID",
  payload: id,
});
